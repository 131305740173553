import { del, get } from "@/requests/server";
import RestModule from "../RestModule";

export default new RestModule(
  "loanables",
  {
    empty: {
      type: null,
      comments: "",
      instructions: "",
      return_instructions: "",
      location_description: "",
      name: "",
      details: {},
      position: null,
      images: [],
      availability_json: "[]",
      availability_mode: "never",
      owner_user: {},
    },
    form: {
      general: {
        name: {
          type: "text",
          rules: {
            required: true,
          },
        },
        images: {
          type: "images",
          aspect_ratio: "16 / 10",
        },
        position: {
          type: "point",
        },
        location_description: {
          type: "textarea",
        },
        comments: {
          type: "textarea",
        },
        instructions: {
          type: "textarea",
        },
        return_instructions: {
          type: "textarea",
        },
        type: {
          type: "select",
          options: [
            {
              text: "Auto",
              value: "car",
            },
            {
              text: "Vélo",
              value: "bike",
            },
            {
              text: "Remorque",
              value: "trailer",
            },
          ],
          rules: {
            required: true,
            oneOf: ["bike", "car", "trailer"],
          },
        },
        sharing_mode: {
          type: "select",
          options: [
            {
              text: "Libre partage",
              value: "self_service",
            },
            {
              text: "Sur demande",
              value: "on_demand",
            },
            {
              text: "Libre partage limité",
              value: "hybride",
            },
          ],
          rules: {
            oneOf: ["on_demand", "self_service", "hybrid"],
          },
        },
      },
      bike: {
        model: {
          type: "text",
          rules: [],
        },
        bike_type: {
          type: "select",
          options: [
            {
              text: "Vélo régulier",
              value: "regular",
            },
            {
              text: "Vélo électrique",
              value: "electric",
            },
            {
              text: "Vélo-cargo",
              value: "cargo",
            },
            {
              text: "Vélo-cargo électrique",
              value: "cargo_electric",
            },
            {
              text: "Roue fixe",
              value: "fixed_wheel",
            },
          ],
          rules: {
            oneOf: ["regular", "cargo", "cargo_electric", "electric", "fixed_wheel"],
          },
        },
        size: {
          type: "select",
          options: [
            {
              text: "Grand",
              value: "big",
            },
            {
              text: "Moyen",
              value: "medium",
            },
            {
              text: "Petit",
              value: "small",
            },
            {
              text: "Enfant",
              value: "kid",
            },
          ],
          rules: {
            oneOf: ["big", "medium", "small", "kid"],
          },
        },
      },
      car: {
        brand: {
          type: "text",
          rules: [],
        },
        model: {
          type: "text",
          rules: [],
        },
        pricing_category: {
          type: "select",
          options: [
            {
              text: "Petite auto (compacte, sous-compacte, hybride non-branchable)",
              value: "small",
            },
            {
              text: "Grosse auto (van, VUS, pick-up)",
              value: "large",
            },
            {
              text: "Petite auto électrique (électrique, hybride branchable)",
              value: "small_electric",
            },
            {
              text: "Grosse auto électrique (électrique, hybride branchable)",
              value: "large_electric",
            },
          ],
          rules: {
            oneOf: ["small", "large", "small_electric", "large_electric"],
          },
        },
        year_of_circulation: {
          type: "number",
          max: 2026,
          min: 1900,
          rules: {
            numeric: true,
            nullable: true,
            digits: 4,
            max_value: 2026,
          },
        },
        transmission_mode: {
          type: "select",
          options: [
            {
              text: "Automatique",
              value: "automatic",
            },
            {
              text: "Manuelle",
              value: "manual",
            },
          ],
          rules: {
            oneOf: ["manual", "automatic"],
          },
        },
        engine: {
          type: "select",
          options: [
            {
              text: "Essence",
              value: "fuel",
            },
            {
              text: "Diesel",
              value: "diesel",
            },
            {
              text: "Électrique",
              value: "electric",
            },
            {
              text: "Hybride",
              value: "hybrid",
            },
          ],
          rules: {
            oneOf: ["fuel", "diesel", "electric", "hybrid"],
          },
        },
        plate_number: {
          type: "text",
          rules: [],
        },
        value_category: {
          type: "select",
          options: [
            {
              text: "50 000$ et moins",
              value: "lte50k",
            },
            {
              text: "50 001$ à 70 000$",
              value: "lte70k",
            },
            {
              text: "70 001$ à 100 000$",
              value: "lte100k",
            },
          ],
          rules: {
            oneOf: ["lte50k", "lte70k", "lte100k"],
          },
        },
        has_onboard_notebook: {
          type: "checkbox",
        },
        has_report_in_notebook: {
          type: "checkbox",
        },
        report: {
          type: "file",
        },
        papers_location: {
          type: "select",
          options: [
            {
              text: "Dans l'auto",
              value: "in_the_car",
            },
            {
              text: "À récupérer avec la clé",
              value: "to_request_with_car",
            },
          ],
          rules: {
            oneOf: ["in_the_car", "to_request_with_car"],
          },
        },
        insurer: {
          type: "text",
          rules: [],
        },
        has_informed_insurer: {
          type: "checkbox",
        },
      },
      trailer: {
        maximum_charge: {
          type: "text",
        },
        dimensions: {
          type: "text",
        },
      },
    },
  },
  {
    /**
     * Attempts to retrieve a loanable which may be accessible by the user
     */
    async tryRetrieveOne({ commit, state }, { params, id }) {
      commit("loaded", false);
      commit("loading", true);

      try {
        const { data } = await get(`/${state.slug}/${id}`, {
          axiosRequestConfig: { params },
          notifications: { action: "chargement" },
          cleanupCallback: () => commit("loading", false),
          requestOptions: { expects: [404] },
        });
        commit("item", data);
        commit("initialItem", data);

        commit("loaded", true);
        commit("loading", false);
      } catch (e) {
        if (e.request && e.request.status === 404) {
          commit(
            "addNotification",
            {
              content: "Ce véhicule n'est pas partagé dans votre communauté.",
              title: "Véhicule inaccessible",
              variant: "danger",
            },
            { root: true }
          );
        }
        throw e;
      }
    },
    async disable({ commit, state }, id) {
      const { data: deleted } = await del(`/${state.slug}/${id}`, {
        notifications: { action: "Archivage du véhicule", onSuccess: "Archivage réussi!" },
      });

      commit("deleted", deleted);
    },
  }
);
