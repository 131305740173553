<script>
import LoanableCalendar from "@/components/Loanable/Calendar.vue";
import { get } from "@/requests/server";
import dayjs from "dayjs";

export default {
  name: "LoanLoanableCalendar",
  components: { LoanableCalendar },
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      events: [],
      loading: false,
    };
  },
  computed: {
    searchDepartureAt() {
      return this.$store.state["loanable.search"].searchDepartureAt;
    },
    searchReturnAt() {
      return dayjs(this.searchDepartureAt)
        .add(this.$store.state["loanable.search"].searchDuration, "minute")
        .format("YYYY-MM-DD HH:mm");
    },
    loanEvent() {
      return {
        type: "loan",
        start: this.searchDepartureAt,
        end: this.searchReturnAt,
        title: "",
        data: {
          status: "potential",
        },
      };
    },
    allEvents() {
      return [this.loanEvent, ...this.events];
    },
  },
  methods: {
    async fetchEvents({ view, startDate, endDate }) {
      {
        // Load 1 extra month/week/day on both side for smoother navigation
        let start = dayjs(startDate).subtract(1, view);
        let end = dayjs(endDate).add(1, view);

        this.loading = true;
        const response = await get(`/loanables/${this.loan.loanable.id}/availability`, {
          axiosRequestConfig: {
            params: {
              start: start.format("YYYY-MM-DD HH:mm:ss"),
              end: end.format("YYYY-MM-DD HH:mm:ss"),
              responseMode: "unavailable",
            },
          },
          notifications: { action: "calcul des disponibilités de ce véhicule" },
          requestOptions: { cancelId: `loanables/${this.loan.loanable.id}/availability` },
          cleanupCallback: () => (this.loading = false),
        });
        this.events = response.data.map((e) => ({ ...e, type: "availability" }));
      }
    },
  },
};
</script>

<template>
  <div>
    <loanable-calendar
      class="loan-card loan-loanable-calendar p-0 styled-calendar-card"
      default-view="week"
      :events="allEvents"
      month-day-detail
      availability-mode="available"
      :initial-date="loanEvent.start"
      :loading="loading"
      @ready="fetchEvents"
      @view-change="fetchEvents"
    ></loanable-calendar>
  </div>
</template>

<style scoped lang="scss"></style>
