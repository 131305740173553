<template>
  <loan-status-alert-container :loan="loan" borrower-action-required>
    <template #borrower>
      <p>
        Pour avoir accès au véhicule, assurez-vous d'avoir assez d'argent dans votre solde en
        <span class="text-nowrap">
          <icon-button
            variant="ghost-secondary"
            size="inline"
            @click="$emit('jump', sections.contribution)"
            >prépayant</icon-button
          >.</span
        >
      </p>
    </template>

    <template #owner>
      <p>
        {{ loan.borrower_user.name }} doit ajouter de l'argent à son solde pour couvrir le coût de
        l'emprunt.
      </p>
      <p v-if="loan.loanable.type === 'car'">
        Assurez-vous que {{ loan.borrower_user.name }} a complété cette étape avant de lui donner
        accès à votre auto, c'est nécessaire pour être bien couvert par l'assurance.
      </p>
    </template>

    <template #admin>
      <p>
        {{ loan.borrower_user.name }} doit ajouter de l'argent à son solde pour couvrir le coût de
        l'emprunt.
      </p>
    </template>
  </loan-status-alert-container>
</template>

<script>
import LoanStatusAlertContainer from "@/components/Loan/Status/Alerts/LoanStatusAlertContainer.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { sections } from "@/components/Loan/FullLoanSections";

export default {
  name: "LoanAccepted",
  components: {
    LoanStatusAlertContainer,
    IconButton,
  },
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sections,
    };
  },
};
</script>

<style lang="scss"></style>
