<template>
  <div class="instructions">
    <p>Veuillez téléverser une des preuves d’identité avec photo suivantes*&nbsp;:</p>
    <ul>
      <li><icons-check class="accepted" /> Permis de conduire;</li>
      <li>
        <icons-check class="accepted" />
        Carte d’assurance maladie (RAMQ);
      </li>
      <li><icons-check class="accepted" /> Passeport.</li>
    </ul>
    <p>La preuve d'identité est partagée avec toutes les communautés la recquérant.</p>
    <forms-files-uploader
      :id="'identity_proof_' + communityUser.id"
      v-model="proofFiles"
      no-label
      class="mb-0"
      center
      :label="$t('communities.fields.identity_proof')"
      :disabled="loading"
      :field="'identity_proof'"
      required
    />
  </div>
</template>

<script>
import IconsCheck from "@/assets/icons/check.svg";

import FormsFilesUploader from "@/components/Forms/FilesUploader.vue";

export default {
  name: "IdentityProofInput",
  components: {
    "icons-check": IconsCheck,
    FormsFilesUploader,
  },
  props: {
    communityUser: {
      type: Object,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    proofFiles: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    submit(...params) {
      this.$emit("submit", ...params);
    },
  },
};
</script>
<style lang="scss" scoped>
.accepted {
  fill: $success;
}

.instructions {
  ul {
    list-style-type: none;
    padding-left: 1rem;
    li {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
