<template>
  <div
    class="loanable-details"
    :class="{
      'fixed-width': fixedWidth,
    }"
    :style="enableViewTransition ? { 'view-transition-name': 'loanable-image' } : {}"
  >
    <header class="loanable-details__header">
      <loanable-carousel :loanable="loanable" show-sharing-mode show-owner />
    </header>
    <main class="loanable-details__content">
      <borrower-profile-warning
        v-if="loanable.type === 'car' && !basic"
        size="sm"
        class="borrower-warning"
      />
      <pretty-description small :loanable="loanable" class="loanable-details__description" />

      <markdown-content
        v-if="loanable.comments && loanable.comments.length > 0"
        :content="loanable.comments"
        :max-height="100"
        class="mx-2 mb-2"
      >
        <template #moreLink
          ><b-link @click="() => createLoan(tabs.loanable)">(plus)</b-link></template
        >
      </markdown-content>
    </main>
    <footer v-if="!basic" class="loanable-details__footer">
      <!-- Only one button will be displayed at a time. -->
      <icon-button
        v-if="loanable.available"
        variant="success"
        :disabled="loanable.type === 'car' && !canLoanCar"
        size="sm"
        @click="() => createLoan()"
      >
        Sélectionner
      </icon-button>
      <icon-button
        v-else-if="!loanable.tested"
        v-b-tooltip.hover
        variant="outline-success"
        :disabled="invalidDuration"
        :loading="searchingLoanables"
        size="sm"
        @click.stop.prevent="$emit('test')"
      >
        Valider la disponibilité
      </icon-button>
      <div v-else class="text-center">
        <div
          v-if="
            loanable.max_loan_duration_in_minutes !== null &&
            searchDuration > loanable.max_loan_duration_in_minutes
          "
          class="text-muted small mb-2"
        >
          La durée maximale pour un emprunt de ce véhicule est de
          {{ loanable.max_loan_duration_in_minutes | duration }}.
        </div>
        <icon-button variant="outline-info" size="sm" @click="() => createLoan(tabs.calendar)"
          >Voir les disponibilités</icon-button
        >
      </div>
    </footer>
  </div>
</template>

<script>
import BorrowerProfileWarning from "@/components/Loan/BorrowerProfileWarning.vue";
import { tabs } from "@/components/Loan/FullLoanSections";
import PrettyDescription from "@/components/Loanable/PrettyDescription.vue";
import IconButton from "@/components/shared/IconButton.vue";
import MarkdownContent from "@/components/shared/MarkdownContent.vue";
import LoanableCarousel from "@/components/Loanable/LoanableCarousel.vue";
import { canLoanCar } from "@/helpers/permissions/users";

export default {
  name: "LoanableDetails",
  components: {
    MarkdownContent,
    PrettyDescription,
    IconButton,
    BorrowerProfileWarning,
    LoanableCarousel,
  },
  props: {
    loanable: {
      type: Object,
      required: false,
      default: undefined,
    },
    fixedWidth: {
      type: Boolean,
      default: false,
    },
    // weather to display only the images and description list
    basic: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tabs() {
      return tabs;
    },
    selected() {
      return this.$route.query.loanable_id == this.loanable.id;
    },
    searchingLoanables() {
      return this.$store.state["loanable.search"].availabilitiesLoading || this.loanable.testing;
    },
    invalidDuration() {
      return this.searchDuration <= 0;
    },
    searchDuration() {
      return this.$store.state["loanable.search"].searchDuration;
    },
    canLoanCar() {
      return canLoanCar(this.$store.state.user);
    },
    enableViewTransition() {
      return !this.$store.state["loanable.search"].newLoanModalOpened && this.selected;
    },
  },
  methods: {
    createLoan(tab) {
      this.$emit("select", { tab });
    },
  },
};
</script>

<style lang="scss">
.loanable-details {
  font-size: 0.875rem;
  position: relative;
  overflow-x: hidden;
  background: white;
  border-radius: 1rem;
  box-shadow: $small-shadow;
  display: flex;
  flex-direction: column;
  container-type: inline-size;

  &__content {
    flex-grow: 1;
  }

  &__description {
    padding: 0.5rem 0;
  }

  &.fixed-width {
    width: 16rem;
  }

  &__header {
    position: relative;
    width: 100%;
  }

  .borrower-warning {
    padding: 0.75rem;
    margin: 0.5rem;
  }

  &__footer {
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: 0.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: white;
    border-top: 1px solid $light-grey;
  }
}
</style>
