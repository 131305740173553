function isGlobalAdmin(user) {
  return user?.role === "admin";
}

function userIsSame(userA, userB) {
  return userA && userB && userA.id === userB.id;
}

function userIsApproved(user) {
  for (const communityUser of user.user_communities) {
    if (communityUser.status === "approved") {
      return true;
    }
  }
  return false;
}

const registeredRequiredFields = ["name", "date_of_birth", "address", "phone"];
function userIsRegistered(user) {
  if (!user) {
    return false;
  }

  for (let i = 0, len = registeredRequiredFields.length; i < len; i += 1) {
    if (!user[registeredRequiredFields[i]]) {
      return false;
    }
  }

  return true;
}

function canAssignFleet(accessingUser) {
  if (!accessingUser) {
    return false;
  }

  // Must be global admin to assign fleet users.
  return isGlobalAdmin(accessingUser);
}

function canChangeUserPassword(accessingUser, accessedUser) {
  if (!accessingUser) {
    return false;
  }

  // One can change their own password.
  if (userIsSame(accessingUser, accessedUser)) {
    return true;
  }

  // Global admins can too.
  return isGlobalAdmin(accessingUser);
}

function canEditUser(accessingUser, accessedUser) {
  if (!accessingUser) {
    return false;
  }

  // One can change their own dtails.
  if (userIsSame(accessingUser, accessedUser)) {
    return true;
  }

  return isGlobalAdmin(accessingUser);
}

function canEditDriversProfile(accessingUser, accessedUser) {
  if (!accessingUser) {
    return false;
  }

  if (userIsSame(accessingUser, accessedUser)) {
    return true;
  }

  return isGlobalAdmin(accessingUser);
}

function canManageGlobalAdmins(accessingUser) {
  return accessingUser && isGlobalAdmin(accessingUser);
}

function canSeeDeletedUser(accessingUser) {
  return isGlobalAdmin(accessingUser);
}

function canSeeAdmins(accessingUser) {
  return isGlobalAdmin(accessingUser);
}

function canLoanCar(accessingUser) {
  return (
    canLoanVehicle(accessingUser) &&
    accessingUser?.borrower?.approved_at &&
    !accessingUser?.borrower?.suspended_at
  );
}

function canSeeUserBills(accessingUser) {
  return isGlobalAdmin(accessingUser);
}

// Communities for which proofs have been submitted or approved
function onboardedCommunities(user) {
  return (user?.user_communities || []).filter(
    (cu) => !["absent", "partial"].includes(cu.proof_state) || cu.status === "approved"
  );
}

function canFillDriverProfile(user) {
  for (const community of onboardedCommunities(user)) {
    if (community.community.shared_loanable_types?.includes("car")) {
      return true;
    }
  }
  return false;
}

function canLoanVehicle(user) {
  return (
    user && user.user_communities && user.user_communities.some((cu) => cu.status === "approved")
  );
}

function canAddLoanables(user) {
  return onboardedCommunities(user).some((cu) => cu.community.members_can_add_loanables);
}

export {
  canAssignFleet,
  canChangeUserPassword,
  canEditDriversProfile,
  canEditUser,
  canLoanCar,
  canManageGlobalAdmins,
  canSeeAdmins,
  canSeeDeletedUser,
  canSeeUserBills,
  isGlobalAdmin,
  userIsApproved,
  userIsRegistered,
  userIsSame,
  onboardedCommunities,
  canFillDriverProfile,
  canLoanVehicle,
  canAddLoanables,
};
