const tabs = {
  loan: "loan",
  messages: "messages",
  contribution: "contribution",
  loanable: "loanable",
  calendar: "calendar",
};

const sections = {
  contacts: {
    target: "loan-section-contacts",
    tab: tabs.messages,
  },
  messages: {
    target: "loan-section-messages",
    tab: tabs.messages,
  },
  loan: {
    target: "loan-section-loan",
    tab: tabs.loan,
  },
  dates: {
    target: "loan-section-dates",
    tab: tabs.loan,
  },
  info: {
    target: "loan-section-info",
    tab: tabs.loan,
  },
  incidents: {
    target: "loan-section-incidents",
    tab: tabs.loan,
  },
  autoValidation: {
    target: "loan-section-auto-validation",
    tab: tabs.loan,
  },
  contribution: {
    target: "loan-section-contribution",
    tab: tabs.contribution,
  },
  instructions: {
    target: "loan-section-instructions",
    tab: tabs.loanable,
  },
  returnInstructions: {
    target: "loan-section-return-instructions",
    tab: tabs.loanable,
  },
  calendar: {
    target: "loan-section-calendar",
    tab: tabs.calendar,
  },
};

export { tabs, sections };
