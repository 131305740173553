<template>
  <loan-status-alert-container :loan="loan">
    <template #borrower>
      <p>
        Voir les
        <icon-button
          variant="ghost-secondary"
          size="inline"
          @click="$emit('jump', sections.instructions)"
          >détails du véhicule</icon-button
        >
        pour son emplacement et les instructions de {{ loanableOwnerUser.name }}.

        <template v-if="loan.borrower_may_contribute && !loan.platform_tip">
          Considérez
          <icon-button
            variant="ghost-secondary"
            size="inline"
            @click="$emit('jump', sections.contribution)"
            >contribuer</icon-button
          >
          pour que LocoMotion continue à rouler!
        </template>
      </p>

      <div v-if="loan.loanable.type === 'car'" class="mb-3">
        <div class="font-weight-bold">Au début et à la fin de l'emprunt</div>
        <ol class="mb-0">
          <li>
            <span v-if="loan.loanable.details.report">
              Prenez connaissance de l'état de l'auto et comparez avec la
              <safe-file :file="loan.loanable.details.report">fiche d'état de l'auto.</safe-file>
            </span>
            <span v-else> Prenez connaissance de l'état de l'auto. </span>
          </li>
          <template v-if="loan.requires_detailed_mileage">
            <li>
              <icon-button
                variant="ghost-secondary"
                size="inline"
                @click="$emit('jump', sections.info)"
                >Entrez le KM</icon-button
              >
              au compteur du tableau de bord.
            </li>
            <li>
              Ajoutez une photo du tableau de bord ou inscrivez le KM dans le carnet de bord qui se
              trouve dans l'auto.
            </li>
          </template>
        </ol>
      </div>

      <p>
        <icon-button
          v-if="loan.status === 'ongoing'"
          role="edit"
          icon="skip-end-circle"
          size="sm"
          :onclick="earlyReturn"
        >
          Terminé plus tôt?
        </icon-button>
      </p>
    </template>

    <template #owner>
      <template v-if="loan.loanable.type === 'car'">
        <div class="font-weight-bold">Au début et à la fin de l'emprunt</div>
        <ol class="mb-0">
          <li>
            <span v-if="loan.loanable.details.report">
              Assurez-vous que la
              <safe-file :file="loan.loanable.details.report">fiche d'état de l'auto</safe-file>
              est à jour. Vous pouvez la mettre à jour
              <router-link :to="`/profile/loanables/${loan.loanable.id}#report-section`">
                ici</router-link
              >.
            </span>
            <template v-else>
              <span>
                Prenez connaissance de l'état de votre véhicule et
                <router-link :to="`/profile/loanables/${loan.loanable.id}#report-section`">
                  ajoutez la fiche d'état ici.
                </router-link>
              </span>
            </template>
          </li>
          <template v-if="loan.requires_detailed_mileage">
            <li>
              <icon-button
                variant="ghost-secondary"
                size="inline"
                @click="$emit('jump', sections.info)"
                >Entrez le KM</icon-button
              >
              au compteur du tableau de bord.
            </li>
            <li>
              Ajoutez une photo du tableau de bord ou inscrivez le KM dans le carnet de bord qui se
              trouve dans l'auto.
            </li>
          </template>
        </ol>
      </template>
      <template v-else>
        <p>En attente de la fin de l'emprunt.</p>
      </template>
    </template>

    <template #admin>
      <p>En attente de la fin de l'emprunt.</p>
    </template>
  </loan-status-alert-container>
</template>

<script>
import LoanStatusAlertContainer from "@/components/Loan/Status/Alerts/LoanStatusAlertContainer.vue";
import IconButton from "@/components/shared/IconButton.vue";
import SafeFile from "@/components/shared/SafeFile.vue";
import { getOwner, isCoownerOrOwner } from "@/helpers/permissions/loanables";
import { isBorrower } from "@/helpers/permissions/loans";
import { put } from "@/requests/server";
import { sections } from "@/components/Loan/FullLoanSections";

export default {
  name: "LoanOngoing",
  components: {
    IconButton,
    LoanStatusAlertContainer,
    SafeFile,
  },
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sections,
    };
  },
  computed: {
    isBorrower() {
      return isBorrower(this.$store.state.user, this.loan);
    },
    isCoownerOrOwner() {
      return isCoownerOrOwner(this.$store.state.user, this.loan.loanable);
    },
    loanableOwnerUser() {
      return getOwner(this.loan.loanable);
    },
  },
  methods: {
    async earlyReturn() {
      const confirm = await this.$bvModal.msgBoxConfirm(
        "Êtes-vous sûr-e de vouloir terminer votre réservation?",
        {
          okTitle: "Terminer ma réservation",
          cancelTitle: "Annuler",
          okVariant: "outline-success",
          cancelVariant: "ghost-secondary",
          centered: true,
        }
      );

      if (!confirm) {
        return;
      }
      const { data } = await put(
        `/loans/${this.loan.id}/return`,
        {},
        {
          notifications: {
            action: "retour de votre emprunt",
          },
        }
      );
      this.$emit("input", data);
    },
  },
};
</script>

<style lang="scss"></style>
