import { getEarliestDeparture } from "@/helpers/loanDepartures";
import { test } from "@/requests/loanRequests";
import { get } from "@/requests/server";
import dayjs from "dayjs";

// Cache for 5 minutes
const loanablesCacheTTL = 5;

export function getInitialState() {
  return {
    center: null,
    selectedLoanableTypes: [],
    searchDepartureAt: null,
    searchDuration: null,
    estimatedDistance: null,
    selectedLoanable: null,
    newLoanModalOpened: false,
    loanablesByType: {},
    loading: false,
    lastTypeFetchDate: null,
    availableLoanableIds: [],
    availabilitiesLoading: false,
    loanablesLoading: false,
    testingLoanableIds: [],
    testedLoanableIds: [],
    allTested: false,
  };
}

export function persistState(state) {
  // Do not persist loading state, availability and
  // invalidate the cache (lastTypeFetchDate not set).
  return {
    selectedLoanableTypes: state.selectedLoanableTypes,
    searchDepartureAt: state.searchDepartureAt,
    searchDuration: state.searchDuration,
    loanablesByType: state.loanablesByType,
  };
}

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset(state) {
      Object.assign(state, getInitialState());
    },
    center(state, center) {
      state.center = center;
    },
    availbilitiesLoading(state, value) {
      state.availabilitiesLoading = value;
    },
    loanablesLoading(state, value) {
      state.loanablesLoading = value;
    },
    estimatedDistance(state, value) {
      state.estimatedDistance = value;
    },
    selectedLoanable(state, loanable) {
      state.selectedLoanable = loanable;
    },
    newLoanModalOpened(state, value) {
      state.newLoanModalOpened = value;
    },
    selectedLoanableTypes(state, selectedLoanableTypes) {
      state.selectedLoanableTypes = selectedLoanableTypes;
    },
    searchDepartureAt(state, date) {
      let dayjsDate = dayjs(date);

      let oldDate = state.searchDepartureAt;
      if (dayjsDate.isValid()) {
        state.searchDepartureAt = dayjsDate.format("YYYY-MM-DD HH:mm");
      } else {
        state.searchDepartureAt = getEarliestDeparture().format("YYYY-MM-DD HH:mm");
      }

      if (oldDate === state.searchDepartureAt) {
        return;
      }

      state.availableLoanableIds = [];
      state.testedLoanableIds = [];
      state.testingLoanableIds = [];
      state.allTested = false;
    },
    searchDuration(state, duration) {
      let oldDuration = state.searchDuration;
      if (duration && duration > 15) {
        state.searchDuration = duration;
      } else {
        state.searchDuration = 15;
      }

      if (oldDuration === state.searchDuration) {
        return;
      }

      state.availableLoanableIds = [];
      state.testingLoanableIds = [];
      state.testedLoanableIds = [];
      state.allTested = false;
    },
    updateAvailabilities(state, availableLoanableIds) {
      state.testingLoanableIds = [];
      state.availableLoanableIds = availableLoanableIds;
      state.allTested = true;
    },
    setTestingLoanable(state, loanableId) {
      state.testingLoanableIds.push(loanableId);
    },
    setLoanableTested(state, loanableId) {
      state.testingLoanableIds = state.testingLoanableIds.filter((id) => id !== loanableId);
      if (!state.testedLoanableIds.includes(loanableId)) {
        state.testedLoanableIds.push(loanableId);
      }
    },
    setLoanableAvailable(state, { id, available }) {
      const previouslyAvailable = state.availableLoanableIds.includes(id);
      if (available && !previouslyAvailable) {
        state.availableLoanableIds.push(id);
        return;
      }

      if (!available && previouslyAvailable) {
        state.availableLoanableIds = state.availableLoanableIds.filter((i) => i !== id);
      }
    },
    mergeLoanablesByType(state, loanableByTypes) {
      state.lastTypeFetchDate = new dayjs().toISOString();
      state.loanablesByType = { ...state.loanablesByType, ...loanableByTypes };
    },
    invalidateCache(state) {
      state.lastTypeFetchDate = null;
    },
  },
  actions: {
    async list({ commit, state }, { types }) {
      commit("selectedLoanableTypes", types);

      if (!types || types.length === 0) {
        return;
      }

      // Check if cached loanables are enough
      if (
        state.lastTypeFetchDate &&
        new dayjs().diff(state.lastTypeFetchDate, "minutes") < loanablesCacheTTL
      ) {
        return;
      }

      commit("loanablesLoading", true);
      const { data } = await get("/loanables/listByType", {
        notifications: { action: "mise à jour de la liste des véhicules" },
        cleanupCallback: () => commit("loanablesLoading", false),
      });
      commit("mergeLoanablesByType", data);
    },
    async testAll({ commit, state, dispatch }) {
      // We ensure the shown loanables are at most loanablesCacheTTL minutes old
      // when searching for available loanables
      // This can be async since both available loanable ids and the loanable list are independent
      dispatch("list", { types: state.selectedLoanableTypes });

      // if last search date below the minimum, no loanables will be available.
      // Move it to the minimum time.
      let earliestDeparture = getEarliestDeparture();
      if (earliestDeparture.isAfter(state.searchDepartureAt)) {
        commit("searchDepartureAt", earliestDeparture);
      }

      const params = {
        departure_at: state.searchDepartureAt,
        duration_in_minutes: state.searchDuration,
      };

      commit("availbilitiesLoading", true);
      const { data } = await get(`/loanables/search`, {
        axiosRequestConfig: { params },
        notifications: { action: "recherche des véhicule disponibles" },
        cleanupCallback: () => commit("availbilitiesLoading", false),
      });

      commit("updateAvailabilities", data);
    },
    async testOne({ commit, state }, { loanable_id, borrower_user_id }) {
      commit("setTestingLoanable", loanable_id);
      const data = await test({
        departure_at: state.searchDepartureAt,
        duration_in_minutes: state.searchDuration,
        estimated_distance: 10,
        loanable_id,
        borrower_user_id,
      });
      if (!data) {
        return;
      }
      commit("setLoanableTested", loanable_id);
      commit("setLoanableAvailable", { id: loanable_id, available: data.available });
    },
  },
  getters: {
    loanables(state) {
      const loanables = [];
      const availableLoanableIds = new Set(state.availableLoanableIds);
      const testedLoanableIds = new Set(state.testedLoanableIds);
      const testingLoanableIds = new Set(state.testingLoanableIds);

      for (const loanableType of state.selectedLoanableTypes) {
        loanables.push(
          ...(state.loanablesByType[loanableType] || []).map((loanable) => ({
            ...loanable,
            available: availableLoanableIds.has(loanable.id),
            tested: state.allTested || testedLoanableIds.has(loanable.id),
            testing: testingLoanableIds.has(loanable.id),
          }))
        );
      }

      loanables.sort((a, b) => a.name.localeCompare(b.name));

      return loanables;
    },
    loading(state) {
      return state.loanablesLoading || state.availabilitiesLoading;
    },
  },
};
