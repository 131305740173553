<template>
  <b-card class="profile-locomotion">
    <profile-form v-if="item" :user="item" :loading="loading" @submit="saveProfile">
      <b-row v-if="hasAddressChanged">
        <b-col>
          <b-alert variant="danger" show class="address-change-warning">
            Si votre changement d'adresse entraine un changement de quartier, vous devrez soumettre
            une nouvelle preuve de résidence.
          </b-alert>
        </b-col>
      </b-row>
    </profile-form>
  </b-card>
</template>

<script>
import ProfileForm from "@/components/Profile/ProfileForm.vue";
import { put } from "@/requests/server";

export default {
  name: "ProfileLocomotion",
  components: { ProfileForm },
  data() {
    return {
      item: { ...this.$store.state.user },
      initialItem: { ...this.$store.state.user },
      loading: false,
    };
  },
  computed: {
    hasAddressChanged() {
      return this.item.address !== this.initialItem.address;
    },
  },
  methods: {
    async saveProfile() {
      this.loading = true;
      await put(`/users/${this.item.id}`, this.item, {
        notifications: {
          action: "Mise à jour du profil",
          onSuccess: true,
        },
        cleanupCallback: () => (this.loading = false),
      });
      this.$store.commit("user", this.item);
    },
  },
};
</script>

<style lang="scss">
.profile-locomotion {
  margin-bottom: 3em;
  .address-change-warning {
    margin-top: 20px;
  }
}
</style>
