<template>
  <paginated-table
    endpoint="user_reports"
    :label="$t('profile.titles.reports')"
    :columns="columns"
    :fetch-params="{ user_id: $store.state.user.id }"
    :load-relations="['report']"
    initial-sort-by="report.created_at"
    :initial-sort-desc="true"
    :show-generate-csv="false"
    :show-action-column="false"
    sync-filters-with-url
  >
    <template #cell(path)="{ item }">
      <file-download-link
        v-if="item.path"
        :api-url="`user_reports/${item.id}/download`"
        :filename="formatReportFilename(item.report.report_key)"
        download
      />
      <div v-else>Non généré</div>
    </template>
  </paginated-table>
</template>

<script>
import FileDownloadLink from "@/components/shared/FileDownloadLink.vue";
import { slugify } from "@/helpers/filters";
import Authenticated from "@/mixins/Authenticated";

import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column } from "@/components/shared/PaginatedTableColumns";

export default {
  name: "ProfileReports",
  components: { FileDownloadLink, PaginatedTable },
  mixins: [Authenticated],
  data() {
    return {
      columns: [
        new Column("id", "ID", "id", { showByDefault: false }),
        Column.withSelect("report.type", "Type", [
          {
            value: null,
            label: "Tous",
          },
          {
            value: "yearly-owner-income",
            label: "Revenus de propriétaire",
            variant: "success",
          },
        ]),
        new Column("report.report_key", "Pour"),
        Column.withoutFilter("path", "Fichier", "text", { sortable: false }),
      ],
    };
  },
  methods: {
    formatReportFilename(year) {
      return `Revenus_${year}_${slugify(this.$store.state.user.full_name)}.pdf`;
    },
  },
};
</script>

<style lang="scss"></style>
