<template>
  <div>
    <paginated-table
      id="admin-invoices-table"
      endpoint="invoices"
      :columns="columns"
      :label="$tc('invoices.invoice', 2)"
      row-link
      :show-action-column="false"
      @filters-changed="setInvoiceFilters"
      @updating="$refs.export.reset()"
    >
      <template #cell(paid_at)="{ item }">
        {{ item.paid_at ? "✓" : "✗" }}
      </template>
    </paginated-table>
  </div>
</template>

<script>
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column } from "@/components/shared/PaginatedTableColumns";

export default {
  name: "AdminInvoices",
  components: {
    PaginatedTable,
  },
  data() {
    return {
      filters: {},
      columns: [
        Column.withoutFilter("id", "ID", "id", { showByDefault: false }),
        Column.withRelation(
          "user.full_name",
          "Membre",
          {
            relation: "users",
            label: "full_name",
            field: "id",
          },
          "user_id"
        ),
        new Column("period", "Nom", "text"),
        new Column("created_at", "Date", "date"),
        new Column("total", "Total", "currency"),
        new Column("total_with_taxes", "Total avec taxes", "currency"),
      ],
    };
  },
  methods: {
    setInvoiceFilters(invoiceFilters) {
      const newFilters = {};
      for (const invoiceFilter in invoiceFilters) {
        newFilters["invoice." + invoiceFilter] = invoiceFilters[invoiceFilter];
      }

      this.filters = newFilters;
    },
  },
};
</script>

<style lang="scss"></style>
