import dayjs from "dayjs";
import { isAdminOfCommunity } from "./communities";
import { isCoownerOrOwner, getOwner } from "./loanables";
import { isGlobalAdmin } from "./users";

export function isBorrower(user, loan) {
  return loan.borrower_user.id === user.id;
}

export function isLoanCommunityAdmin(user, loan) {
  if (!loan) {
    return false;
  }

  return isAdminOfCommunity(user, loan.community);
}

/**
 * Returns true if user is either admin of loan community or global admin.
 */
export function isLoanAdmin(user, loan) {
  return isGlobalAdmin(user) || isLoanCommunityAdmin(user, loan);
}

export function canViewLoan(user, loan) {
  return isBorrower(user, loan) || isCoownerOrOwner(user, loan.loanable) || isLoanAdmin(user, loan);
}

export function canViewLoanAdminDetails(user, loan) {
  return isLoanAdmin(user, loan);
}

export function canViewLoanInstructions(user, loan) {
  return (
    isCoownerOrOwner(user, loan.loanable) ||
    isLoanAdmin(user, loan) ||
    (isBorrower(user, loan) && ["confirmed", "ongoing", "ended", "validated"].includes(loan.status))
  );
}

export function canAcceptLoan(user, loan) {
  if (loan.status !== "requested") {
    return false;
  }

  return isCoownerOrOwner(user, loan.loanable) || isLoanAdmin(user, loan);
}

export function canRejectLoan(user, loan) {
  if (loan.status !== "requested") {
    return false;
  }

  return isCoownerOrOwner(user, loan.loanable) || isLoanAdmin(user, loan);
}

export function loanBlockedByIncident(loan, incident) {
  return (
    incident.status === "in_process" &&
    incident.blocking_until &&
    dayjs(incident.blocking_until).isAfter(loan.departure_at) &&
    dayjs(incident.start_at).isSameOrBefore(loan.departure_at)
  );
}

export function loanableHasIncidentBlockingLoan(loanable, loan) {
  return (
    loanable.active_incidents?.filter((incident) => loanBlockedByIncident(loan, incident)).length >
    0
  );
}

export function loanIsInProcess(loan) {
  return (
    loan.status &&
    loan.status !== "completed" &&
    loan.status !== "rejected" &&
    loan.status !== "canceled"
  );
}

export function canCancelLoan(user, loan) {
  if (!loanIsInProcess(loan)) {
    return false;
  }

  if (isLoanAdmin(user, loan)) {
    return true;
  }

  // Owners can cancel ongoing loans, but not new requests (they have to reject instead).
  if (isCoownerOrOwner(user, loan.loanable) && loan.status !== "requested") {
    return true;
  }

  if (
    !loan.is_free &&
    ["ongoing", "validated", "ended"].includes(loan.status) &&
    // Can cancel loan if it is blocked by an incident. This handles cases where user has prepaid a
    // loan and time has slipped by before they realised that an incident was preventing this loan.
    !loanableHasIncidentBlockingLoan(loan.loanable, loan)
  ) {
    return false;
  }
  return isBorrower(user, loan);
}

export function canResumeLoan(user, loan) {
  if (
    loan.loanable.deleted_at ||
    getOwner(loan.loanable).deleted_at ||
    loan.borrower_user.deleted_at
  ) {
    return false;
  }

  if (loan.status !== "canceled") {
    return false;
  }

  return isLoanAdmin(user, loan);
}

export function canChangeLoanTime(user, loan) {
  return (
    isBorrower(user, loan) || isCoownerOrOwner(user, loan.loanable) || isGlobalAdmin(user, loan)
  );
}

export function canAcceptExtension(user, loan) {
  return isCoownerOrOwner(user, loan.loanable) || isGlobalAdmin(user, loan);
}

export function canRejectExtension(user, loan) {
  if (loan.status !== "in_process") {
    return false;
  }

  return isCoownerOrOwner(user, loan.loanable) || isGlobalAdmin(user, loan);
}

export function canCancelExtension(user, loan) {
  return isBorrower(user, loan) || isGlobalAdmin(user, loan);
}

export function canDeclareIncident(user, loan) {
  return isBorrower(user, loan) || isCoownerOrOwner(user, loan.loanable) || isLoanAdmin(user, loan);
}

export function canResolveIncident(user, loan) {
  return isLoanAdmin(user, loan);
}

export function canChangeLoanInfo(user, loan) {
  if (loan.status === "refused" || loan.status === "canceled" || loan.status === "completed") {
    return false;
  }

  return (
    isBorrower(user, loan) || isCoownerOrOwner(user, loan.loanable) || isGlobalAdmin(user, loan)
  );
}

export function canPrepay(user, loan) {
  if (loan.status !== "in_process") {
    return false;
  }

  if (loan.pre_payment?.status !== "in_process") {
    return false;
  }

  return isBorrower(user, loan) || isLoanAdmin(user, loan);
}

export function canPay(user, loan) {
  if (loan.status !== "in_process") {
    return false;
  }

  if (loan.payment?.status !== "in_process") {
    return false;
  }

  return isBorrower(user, loan) || isCoownerOrOwner(user, loan.loanable) || isLoanAdmin(user, loan);
}
