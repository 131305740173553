<script>
import GbfsStream from "@/components/Admin/GbfsStream.vue";
import AdminListActions from "@/components/Admin/ListActions.vue";
import RelationInput from "@/components/Forms/RelationInput.vue";
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import FormButtons from "@/components/shared/FormButtons.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column } from "@/components/shared/PaginatedTableColumns";
import { del, get, put } from "@/requests/server";
import Vue from "vue";

export default Vue.extend({
  name: "GbfsDataset",
  components: {
    FormButtons,
    GbfsStream,
    RelationInput,
    AdminListActions,
    PaginatedTable,
    FormsValidatedInput,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dataset: {},
      loaded: false,
      saving: false,
      loading: false,
      columns: [
        new Column("id", "ID", "id", { showByDefault: false }),
        new Column("community.name", "Nom", "text", {
          urlFct: (item) => `/admin/communities/${item.community.id}`,
        }),
        Column.withoutFilter("loanables_count", "# véhicules sur le flux", "number", {
          sortable: false,
        }),
      ],
    };
  },
  computed: {
    isNew() {
      return this.name === "new";
    },
    communityFetchParams() {
      return {
        gbfs_dataset_name: this.name,
      };
    },
  },
  created() {
    if (this.name !== "new") {
      this.fetchDataset();
    } else {
      this.loaded = true;
    }
  },
  methods: {
    async fetchDataset() {
      this.loading = true;
      const { data } = await get(`gbfs_datasets/${this.name}`, {
        notifications: { action: "chargement du flux", resourceName: "flux gbfs" },
        cleanupCallback: () => (this.loading = false),
      });

      this.dataset = data;
      this.loaded = true;
    },
    save() {
      if (this.isNew) {
        this.createDataset();
      } else {
        this.updateDataset();
      }

      this.$refs.validation.reset();
    },
    async reset() {
      await this.fetchDataset();
      this.$refs.validation.reset();
    },
    async updateDataset() {
      this.saving = true;
      const { data } = await put(`gbfs_datasets/${this.name}`, this.dataset, {
        notifications: {
          action: "enregistrement du flux",
          resourceName: "flux gbfs",
          onSuccess: true,
        },
        cleanupCallback: () => (this.saving = false),
      });
      this.dataset = data;
    },
    async createDataset() {
      this.saving = true;
      const { data } = await put(`gbfs_datasets`, this.dataset, {
        notifications: { action: "création du flux", resourceName: "flux gbfs", onSuccess: true },
        cleanupCallback: () => (this.saving = false),
      });
      this.dataset = data;

      await this.$router.push(`/admin/gbfs_datasets/${data.name}`);
    },
    async removeCommunity(item) {
      await del(`/gbfs_dataset_communities/${item.id}`, {
        notifications: {
          action: "retrait de la communauté du flux",
          resourceName: "communauté du flux gbfs",
          onSuccess: true,
        },
      });
    },

    async addCommunity(community) {
      await put(
        "gbfs_dataset_communities",
        {
          community_id: community.id,
          gbfs_dataset_name: this.name,
        },
        {
          notifications: {
            action: "ajout de la communauté du flux",
            resourceName: "communauté du flux gbfs",
            onSuccess: true,
          },
        }
      );
      this.$refs.table.refresh();
    },
  },
});
</script>

<template>
  <layout-loading v-if="!loaded" />
  <validation-observer v-else v-slot="{ passes, pristine }" ref="validation">
    <b-form class="form" @submit.stop.prevent="passes(save)">
      <h1 v-if="isNew" class="mb-4">Nouveau flux</h1>
      <h1 v-else class="mb-4">{{ dataset.name }}</h1>

      <forms-validated-input
        v-if="isNew"
        v-model="dataset.name"
        label="Nom du flux"
        type="text"
        :rules="{
          required: true,
          alpha_dash: true,
          excluded: 'new',
        }"
        name="name"
      />

      <forms-validated-input v-model="dataset.notes" label="Notes" type="textarea" name="notes" />

      <paginated-table
        v-if="!isNew"
        id="admin-gbfs-dataset-communities-table"
        ref="table"
        endpoint="gbfs_dataset_communities"
        :columns="columns"
        label="Communautés"
        :load-relations="['community']"
        :fetch-params="communityFetchParams"
        :show-generate-csv="false"
        show-action-column
        key-column="id"
      >
        <template #head-buttons>
          <relation-input
            placeholder="Ajouter une communauté"
            class="add-community-input"
            :value="null"
            reset-after-select
            :query="{
              slug: 'communities',
              value: 'id',
              text: 'name',
              params: {
                '!gbfs_datasets.name': name,
              },
            }"
            name="add-dataset"
            @input="addCommunity"
          />
        </template>
        <template #cell(actions)="{ item }">
          <admin-list-actions
            slug="communities"
            :item-name="item.community.name"
            :item="item"
            :destroy-action="removeCommunity"
            :show-restore="false"
            archive-label="retirer"
            @change="$refs.table.refresh"
          />
        </template>
      </paginated-table>

      <template v-if="!isNew">
        <gbfs-stream :root="dataset.link" stream="system_information" />

        <gbfs-stream :root="dataset.link" stream="vehicle_types" />

        <gbfs-stream :root="dataset.link" stream="vehicle_status" />
      </template>

      <form-buttons :changed="!pristine" :saving="saving" :loading="loading" @reset="reset" />
    </b-form>
  </validation-observer>
</template>

<style lang="scss">
.add-community-input {
  width: 15rem;
}
</style>
