<template>
  <div class="dashboard-balance">
    <h3 class="dashboard-h3">Solde</h3>

    <div
      class="dashboard-balance__balance"
      :class="{ 'dashboard-balance__balance__smaller': user.balance > 999 }"
    >
      {{ user.balance | currency }}
    </div>

    <div class="dashboard-balance__buttons">
      <div
        v-b-tooltip="
          !hasApprovedCommunity
            ? 'Votre compte doit être validé avant de pouvoir ajouter de l\'argent à votre solde.'
            : null
        "
        class="d-inline-block"
      >
        <icon-button
          v-b-modal.add-credit-modal
          class="mr-2"
          size="sm"
          variant="outline-success"
          :disabled="!hasApprovedCommunity"
        >
          {{ $t("components.dashboard.balance.fund") | capitalize }}
        </icon-button>
      </div>

      <div
        v-b-tooltip="user.balance < 1 ? 'Minimum de 1 $ pour réclamer la balance' : null"
        class="d-inline-block"
      >
        <icon-button
          v-b-modal.claim-modal
          :disabled="user.balance < 1"
          size="sm"
          variant="ghost-secondary"
          :loading="claiming"
        >
          {{ $t("components.dashboard.balance.claim") | capitalize }}
        </icon-button>
      </div>
    </div>

    <b-modal
      id="add-credit-modal"
      title="Approvisionner mon compte"
      size="lg"
      footer-class="d-none"
    >
      <user-add-credit-box
        :payment-methods="user.payment_methods"
        :add-standard-options="true"
        @bought="closeModal"
        @cancel="closeModal"
      />
    </b-modal>

    <b-modal
      id="claim-modal"
      title="Demande de transfert du solde du compte LocoMotion vers un compte bancaire"
      size="lg"
      hide-footer
    >
      <user-claim-credits-box :user="user" @claimed="closeModal" @cancel="closeModal" />
    </b-modal>
  </div>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";

import UserAddCreditBox from "@/components/User/AddCreditBox.vue";
import UserClaimCreditsBox from "@/components/User/ClaimCreditsBox.vue";
import { userIsApproved } from "@/helpers/permissions/users";

export default {
  name: "DashboardBalance",
  components: {
    IconButton,
    UserAddCreditBox,
    UserClaimCreditsBox,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasApprovedCommunity() {
      return userIsApproved(this.user);
    },
    claiming() {
      return this.$store.state.account.claiming;
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("add-credit-modal");
      this.$bvModal.hide("claim-modal");
    },
  },
};
</script>

<style lang="scss">
.dashboard-balance {
  .btn {
    margin-left: 0;
    margin-bottom: 10px;
  }

  h3.dashboard-h3 {
    margin: 0;
  }
  &__balance {
    font-size: 3rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: $primary;

    &__smaller {
      font-size: 2rem;
      margin: 0.5rem 0 1rem;
    }
  }

  &__buttons {
    margin-bottom: 10px;
  }
}
</style>
