<template>
  <div class="register-step">
    <b-pagination-nav
      v-if="currentPage < 4"
      :value="currentPage"
      :number-of-pages="3"
      pills
      align="center"
      use-router
      :hide-goto-end-buttons="true"
      disabled
    >
      <template #page="{ page }">
        <span v-if="page < currentPage" class="checked">
          <b-icon icon="check" font-scale="2" />
        </span>
        <span v-else>{{ page }}</span>
      </template>
    </b-pagination-nav>

    <div v-if="item && currentPage === 2">
      <h3>Profil</h3>

      <p class="text-center">Mettez votre communauté en confiance.</p>
      <profile-form v-if="item" :user="item" :loading="savingProfile" @submit="saveProfile">
        <forms-validated-input
          v-model="item.accept_conditions"
          name="accept_conditions"
          :rules="form.general.accept_conditions.rules"
          type="checkbox"
        >
          <template #label>
            J'accepte les
            <a href="/conditions" target="_blank">conditions générales d'utilisation</a> incluant
            des communications par courriel.
          </template>
        </forms-validated-input>
      </profile-form>
      <layout-loading v-else />
    </div>

    <layout-loading v-if="!item && currentPage == 2" />

    <div v-if="currentPage == 3" class="register-step__community">
      <div v-if="proofLoaded">
        <div v-for="communityUser in communityUsers" :key="communityUser.id">
          <h2>{{ communityUser.community.name }}</h2>
          <community-proof-form
            :can-skip="true"
            :community-user="communityUser"
            :user="user"
            @submitted="proofsSubmitted"
            @skip="skipUserProofs"
          />
        </div>
      </div>

      <layout-loading v-else />
    </div>

    <div v-if="currentPage === 4" class="register-step__reasons-why">
      <img src="/donation-bike_288.jpg" alt="Enfants dans une remorque LocoMotion" />
      <p class="title">Bravo! Vous faites maintenant partie de l'aventure.</p>
      <p class="subtitle">Merci d'avancer avec nous vers plus de partage dans votre communauté!</p>

      <b-btn variant="primary" to="/app">On avance!</b-btn>
    </div>
  </div>
</template>

<script>
import CommunityProofForm from "@/components/Community/ProofForm.vue";
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import LayoutLoading from "@/components/Layout/Loading.vue";
import ProfileForm from "@/components/Profile/ProfileForm.vue";

import Authenticated from "@/mixins/Authenticated";
import FormLabelsMixin from "@/mixins/FormLabelsMixin";
import FormMixin from "@/mixins/FormMixin";
import Notification from "@/mixins/Notification";
import { get } from "@/requests/server";

export default {
  name: "RegisterStep",
  components: {
    LayoutLoading,
    CommunityProofForm,
    FormsValidatedInput,
    ProfileForm,
  },

  mixins: [Authenticated, FormLabelsMixin, FormMixin, Notification],
  beforeRouteUpdate(to, from, next) {
    if (to.params.step === "3") {
      this.loadProofs();
    } else if (to.params.step === "4") {
      // Ensure we reload the fully registered user at the last step before
      // showing on the dashboard.
      this.$store.dispatch("loadUser");
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.isLoggedIn) {
        // Has not finalized his account creation
        if (!vm.isRegistered) {
          if (vm.$route.path !== "/register/2") {
            vm.$router.replace("/register/2");
          }
        }
      }
    });
    next((vm) => {
      if (vm.$route.path === "/register/3") {
        vm.loadProofs();
      }
    });
  },
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: "me",
    },
    step: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      proofLoaded: false,
      // We do not want to submit an empty community array here, since it would
      // overwrite the one we automatically assign on user relocation
      ignoreFields: ["communities"],
      savingProfile: false,
      communityUsers: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    currentPage() {
      const stepIndex = parseInt(this.step, 10);

      if (Number.isNaN(stepIndex)) {
        return 1;
      }

      return stepIndex;
    },
    invitation() {
      return this.$store.state.invitation;
    },
    hasInvitation() {
      return this.invitation.code && this.invitation.id;
    },
    hasUnapprovedCommunities() {
      return this.item.user_communities.filter((c) => !c.approved_at).length > 0;
    },
  },
  methods: {
    async loadProofs() {
      this.proofLoaded = false;

      const { data } = await get(
        `/communityUsers?user.id=${this.user.id}&relations=community,user.residency_proof,user.identity_proof,custom_proof`,
        {
          notifications: { action: "chargement des communautés" },
          cleanupCallback: () => (this.proofLoaded = true),
        }
      );

      let invitedPrivateCommunities = data.data.filter(
        (cu) => cu.community.type === "private" && cu.join_method === "invitation"
      );

      if (invitedPrivateCommunities.length) {
        this.communityUsers = invitedPrivateCommunities;
        return;
      }
      this.communityUsers = data.data;
    },
    async saveProfile() {
      this.savingProfile = true;

      try {
        await this.submit();

        if (this.hasInvitation) {
          await this.$store.dispatch("invitation/accept");
        } else if (this.item.email_verified_at) {
          // TODO: create a separate endpoint for completing user registration with invitation acceptation
          await this.$store.dispatch("invitation/acceptOnRegistration");
        }

        // invitations may have added communities, so we merge them back from the current user
        this.$store.commit("users/mergeItem", {
          user_communities: this.user.user_communities,
        });
      } finally {
        this.savingProfile = false;
      }

      if (this.hasUnapprovedCommunities) {
        // Go to "Submit proof for community."
        await this.$router.push("/register/3");
      } else {
        await this.$router.push("/register/4");
      }
    },
    proofsSubmitted() {
      this.$router.push("/register/4");
    },
    async skipUserProofs() {
      await this.$router.push("/register/4");
    },
  },
};
</script>

<style lang="scss">
.register-step {
  .profile-form label {
    color: #1a1a1a;
    font-weight: 400;
    font-size: 14px;
  }

  h3,
  h4 {
    text-align: center;
  }

  h3 {
    margin-top: 30px;
    font-size: 26px;
    font-weight: 600;
  }

  h4 {
    color: grey;
    font-size: 20px;
    margin: 20px 0;
    font-weight: 400;
  }

  &__completed__button {
    text-align: center;
  }

  &__reasons-why {
    text-align: center;

    // Match dashboard jumbotron styles
    .title {
      font-size: 2rem;
      font-weight: 700;
    }
    .subtitle {
      font-size: 1.25rem;
      font-weight: 300;
      margin-bottom: 2rem;
    }

    img {
      border-radius: 100%;
      padding: 2rem;
      max-width: 100%;
    }
  }
}
</style>
