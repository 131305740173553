<template>
  <div class="email-form">
    <validation-observer ref="observer" v-slot="{ passes }">
      <b-form
        :novalidate="true"
        class="email-form__form"
        @submit.stop.prevent="passes(updateEmail)"
      >
        <forms-validated-input
          v-model="currentPassword"
          mode="lazy"
          name="current_password_for_email_change"
          :label="$t('components.user.email_form.current_password')"
          :rules="{ required: true }"
          type="password"
          :placeholder="$t('components.user.email_form.current_password')"
        />

        <forms-validated-input
          v-model="newEmail"
          mode="lazy"
          name="new_email"
          :label="$t('components.user.email_form.new_email')"
          :rules="{ required: true, email: true }"
          type="email"
          :placeholder="$t('components.user.email_form.new_email')"
        />

        <forms-validated-input
          v-model="newEmailRepeat"
          mode="lazy"
          name="new_email_repeat"
          :label="$t('components.user.email_form.new_email_repeat')"
          :rules="{ required: true, is: newEmail }"
          type="email"
          :placeholder="$t('components.user.email_form.new_email_repeat')"
        />

        <icon-button v-if="!!user.id" type="submit" :loading="loading" role="save">
          {{ $t("components.user.email_form.submit") }}
        </icon-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";

import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import { post } from "@/requests/server";
export default {
  name: "UserEmailForm",
  components: {
    IconButton,
    FormsValidatedInput,
  },
  props: {
    isAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentPassword: "",
      newEmail: "",
      newEmailRepeat: "",
      loading: false,
    };
  },
  methods: {
    async updateEmail() {
      this.loading = true;

      try {
        const { data } = await post(
          `/users/${this.user.id}/email`,
          {
            password: this.currentPassword,
            email: this.newEmail,
          },
          {
            requestOptions: { cancelId: "update-email", expects: [401] },
            notifications: { action: "changement du courriel", onSuccess: true },
            cleanupCallback: () => (this.loading = false),
          }
        );

        // if data is received, the email has been succesfully updated, and we show the success message.
        if (data) {
          this.$emit("updated", { email: this.newEmail });
          this.reset();
        }
      } catch (e) {
        if (e.response?.status === 401) {
          this.$store.commit("addNotification", {
            content: "Le mot de passe entré ne correspond pas à votre mot de passe actuel.",
            title: "Mot de passe invalide",
            variant: "danger",
          });

          return;
        }
        throw e;
      }
    },
    reset() {
      this.currentPassword = "";
      this.newEmail = "";
      this.newEmailRepeat = "";
      this.$refs.observer.reset();
    },
  },
};
</script>

<style lang="scss"></style>
