<template>
  <layout-page name="community-overview" wide>
    <b-container class="h-100 position-relative" fluid>
      <b-row class="h-100">
        <b-col v-if="!fullscreen" xl="3" class="community-overview__sidebar">
          <b-form-group class="mb-3">
            <div class="d-flex">
              <place-autocomplete-input
                ref="placeAutocomplete"
                v-model="placeSearchText"
                class="form-control form-control-sm"
                :options="{
                  language: 'fr',
                  fields: ['geometry', 'formatted_address'],
                  componentRestrictions: { country: 'ca' },
                }"
                placeholder="Chercher un endroit"
                @place-selected="setSearchLocation"
              />
              <icon-button
                v-if="placeSearchText"
                variant="link"
                size="sm"
                icon="x"
                @click="clearSearchInput"
              />
            </div>
          </b-form-group>

          <icon-button
            v-if="isAdmin"
            class="mb-3"
            :disabled="!userPositions"
            :pressed="showMembers"
            variant="ghost-secondary"
            icon="ui-radios-grid"
            @click="showMembers = !showMembers"
          >
            Nuage de points des membres
          </icon-button>

          <h4
            v-if="isLoggedIn"
            v-b-toggle:toggleable="'collapse_filters'"
            class="section-toggle mb-0"
          >
            <b-icon font-scale="0.75" icon="chevron-right" /> Filtres
            <b-badge v-if="filterCount > 0" variant="success">{{ filterCount }}</b-badge>
          </h4>
          <div v-if="isLoggedIn" class="community-overview__filters">
            <b-collapse id="collapse_filters" accordion="collapse_filters">
              <b-row>
                <b-col
                  v-for="(filter, filterKey) of filterDefinitions"
                  :key="filterKey"
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    v-if="
                      (canFilterCommunities || !filter.filtersCommunities) &&
                      (isAdmin || !filter.adminOnly)
                    "
                    label-size="sm"
                    :label="filter.label"
                  >
                    <filter-input
                      :name="filterKey"
                      :type="filter.type"
                      :options="filter.options"
                      :value="filters[filterKey]"
                      @input="
                        filters[filterKey] = $event;
                        clearSelectedIfNotShown();
                      "
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-collapse>
          </div>

          <div class="community-overview__communities mt-3 d-xl-block d-none">
            <h4>Communautés</h4>
            <transition-group name="community-list">
              <community-overview-card
                v-for="community of communities"
                :key="community.id"
                :community="community"
                :selected="selectedCommunityIds.includes(community.id)"
                :muted="
                  selectedCommunityIds.length > 0 && !selectedCommunityIds.includes(community.id)
                "
                @click="selectedCommunityIds = [community.id]"
              />
            </transition-group>
          </div>
        </b-col>
        <b-col :xl="fullscreen ? 12 : 9" class="map-container">
          <loanable-map
            ref="map"
            :loanables="loanables"
            :communities="communities"
            polygons-selectable
            :polygon-options="polygonOptions"
            :selected-polygon-options="selectedPolygonOptions"
            :selected-community-ids="selectedCommunityIds"
            :no-recenter="!!center"
            v-bind="mapOptionalProps"
            :padding-client-width-max="fullscreen ? Number.MAX_VALUE : 1200"
            :padding="{
              y: 150,
            }"
            :heatmap-data="showMembers ? userPositions : null"
            @select-communities="selectedCommunityIds = $event"
            @select-loanable="selectLoanable"
          >
            <template #loanable-details="{ loanable }">
              <loanable-details fixed-width :loanable="loanable" basic />
            </template>
            <template #top-right-button>
              <layout-loading v-if="loading" with-button />
            </template>
          </loanable-map>
        </b-col>
      </b-row>
      <community-overview-card
        v-if="firstSelectedCommunity"
        class="floating-community-overview-card"
        :class="{ 'd-xl-none': !fullscreen }"
        :community="firstSelectedCommunity"
      />
    </b-container>
  </layout-page>
</template>

<script>
import LayoutLoading from "@/components/Layout/Loading.vue";
import LoanableDetails from "@/components/Loanable/Details.vue";
import LoanableMap from "@/components/Loanable/Map.vue";
import FilterInput from "@/components/shared/FilterInput.vue";
import IconButton from "@/components/shared/IconButton.vue";
import PlaceAutocompleteInput from "@/components/shared/PlaceAutocompleteInput.vue";
import { capitalize } from "@/helpers/filters";
import { isAdminOfSomeCommunity } from "@/helpers/permissions/communities";
import { getOwner } from "@/helpers/permissions/loanables";
import { isGlobalAdmin } from "@/helpers/permissions/users";
import { get } from "@/requests/server";
import CommunityOverviewCard from "@/views/community/CommunityOverviewCard.vue";
import axios from "axios";

export default {
  name: "CommunityOverview",
  components: {
    IconButton,
    PlaceAutocompleteInput,
    LoanableMap,
    CommunityOverviewCard,
    LayoutLoading,
    FilterInput,
    LoanableDetails,
  },
  data() {
    return {
      loading: false,
      allLoanables: [],
      allCommunities: [],
      userPositions: [],
      placeSearchText: "",
      showMembers: false,
      polygonOptions: {
        fillColor: "#16a59e",
        fillOpacity: 0.2,
        strokeColor: "#16a59e",
        strokeOpacity: 0.6,
        strokeWeight: 2,
        zIndex: 2,
      },
      selectedPolygonOptions: {
        fillColor: "#16a59e",
        fillOpacity: 0.4,
        strokeColor: "#16a59e",
        strokeOpacity: 0.8,
        strokeWeight: 5,
        zIndex: 2,
      },
      filters: {
        communityId: null,
        communityType: null,
        loanableId: null,
        loanableType: null,
        loanableSharingMode: null,
        ownerUserId: null,
      },
      filterDefinitions: {
        communityId: {
          type: "relation",
          label: "Communauté",
          filtersCommunities: true,
          options: {
            relation: "communities",
            label: "name",
            field: "id",
            params: { for: "loan" },
          },
        },
        communityType: {
          type: "select",
          filtersCommunities: true,
          label: "Type de communauté",
          options: [
            { value: null, label: this.$t("communities.fields.types.null") },
            { value: "borough", label: this.$t("communities.fields.types.borough") },
            { value: "private", label: this.$t("communities.fields.types.private") },
          ],
        },
        loanableId: {
          type: "relation",
          label: "Véhicule",
          adminOnly: true,
          options: {
            relation: "loanables",
            label: "name",
            field: "id",
            params: { for: "admin" },
          },
        },
        loanableType: {
          type: "select",
          label: "Type de véhicule",
          options: [
            { value: null, label: this.$t("loanables.fields.types.null") },
            { value: "car", label: "Auto" },
            { value: "trailer", label: "Remorque" },
            { value: "bike", label: "Vélo" },
          ],
        },
        loanableSharingMode: {
          type: "select",
          label: "Mode de partage du véhicule",
          options: [
            { value: null, label: "Tous" },
            { value: "on_demand", label: capitalize(this.$t("loanables.sharing_modes.on_demand")) },
            {
              value: "self_service",
              label: capitalize(this.$t("loanables.sharing_modes.self_service")),
            },
            { value: "hybrid", label: capitalize(this.$t("loanables.sharing_modes.hybrid")) },
          ],
        },
        ownerUserId: {
          type: "relation",
          adminOnly: true,
          label: "Propriétaire",
          options: {
            relation: "users",
            label: "full_name",
            field: "id",
            params: { for: "admin", owns_loanables: true },
          },
        },
      },
      selectedCommunityIds: [],
    };
  },
  computed: {
    fullscreen() {
      return !!this.$route.query.full_screen;
    },
    zoom() {
      return parseInt(this.$route.query.zoom);
    },
    center() {
      const center = this.$route.query.center;
      if (!center) {
        return null;
      }

      let [lat, lng] = center.split("+");

      lat = parseFloat(lat);
      lng = parseFloat(lng);

      if (!lat || !lng) {
        return;
      }

      return {
        lat,
        lng,
      };
    },
    mapOptionalProps() {
      let props = {};
      if (this.center) {
        props["default-center"] = this.center;
      }
      if (this.zoom) {
        props["zoom"] = this.zoom;
      }
      return props;
    },
    firstSelectedCommunity() {
      if (this.selectedCommunityIds.length < 1) {
        return null;
      }
      return this.allCommunities.find((c) => c.id === this.selectedCommunityIds[0]);
    },
    canFilterCommunities() {
      return this.allCommunities.length > 1;
    },
    communities() {
      let allCommunities = this.allCommunities;

      if (this.filters.communityType) {
        allCommunities = allCommunities.filter((c) => c.type === this.filters.communityType);
      }

      if (this.filters.communityId) {
        allCommunities = allCommunities.filter((c) => c.id === this.filters.communityId);
      }

      allCommunities.sort((a, b) =>
        this.selectedCommunityIds.includes(b.id)
          ? 1
          : this.selectedCommunityIds.includes(a.id)
          ? -1
          : a.name.localeCompare(b.name)
      );
      return allCommunities;
    },
    isLoggedIn() {
      return !!this.$store.state.user;
    },
    isGlobalAdmin() {
      return this.isLoggedIn && isGlobalAdmin(this.$store.state.user);
    },
    isAdmin() {
      return (
        this.isLoggedIn && (this.isGlobalAdmin || isAdminOfSomeCommunity(this.$store.state.user))
      );
    },
    hasCommunityFilters() {
      for (const filter in this.filterDefinitions) {
        if (this.filterDefinitions[filter].filtersCommunities && this.filters[filter] !== null) {
          return true;
        }
      }
      return false;
    },
    loanables() {
      let loanables = this.allLoanables;

      if (this.hasCommunityFilters) {
        const filteredCommunityIds = this.communities.map((c) => c.id);
        loanables = loanables.filter(
          (l) =>
            (l.community_ids || []).find((cid) => filteredCommunityIds.includes(cid)) !== undefined
        );
      }

      if (this.filters.loanableId) {
        loanables = loanables.filter((l) => l.id === this.filters.loanableId);
      }

      if (this.filters.loanableType) {
        loanables = loanables.filter((l) => l.type === this.filters.loanableType);
      }

      if (this.filters.loanableSharingMode !== null) {
        loanables = loanables.filter((l) => l.sharing_mode === this.filters.loanableSharingMode);
      }

      if (this.filters.ownerUserId) {
        loanables = loanables.filter((l) => getOwner(l).id === this.filters.ownerUserId);
      }

      return loanables.map((l) => ({
        ...l,
        tested: this.selectedCommunityIds.length > 0,
        available:
          (l.community_ids || []).find((cid) => this.selectedCommunityIds.includes(cid)) !==
          undefined,
      }));
    },
    filterCount() {
      return Object.values(this.filters).filter((v) => v !== null).length;
    },
  },
  beforeMount() {
    this.loadOverviewData();
  },
  methods: {
    async loadOverviewData() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      const { data } = await get("/communities/overview", {
        notifications: { action: "chargement des données" },
        cleanupCallback: () => (this.loading = false),
      });

      this.allLoanables = data.loanables;
      this.allCommunities = data.communities;
      this.userPositions = data.user_positions;

      const urlCommunityId = parseInt(this.$route.query.community);

      if (urlCommunityId) {
        this.selectedCommunityIds = [urlCommunityId];
      }
    },
    selectLoanable(loanable) {
      if (!loanable) {
        return;
      }
      if (loanable.community_ids) {
        this.selectedCommunityIds = loanable.community_ids;
      }
      this.loadImages(loanable);
    },
    async loadImages(loanable) {
      if (!loanable.images) {
        const { data: updatedLoanable } = await axios.get(`/loanables/${loanable.id}`);

        this.allLoanables = this.allLoanables.map((l) => {
          if (l.id === loanable.id) {
            return Object.assign(l, updatedLoanable);
          }
          return l;
        });
      }
    },
    setSearchLocation(place) {
      if (place) {
        this.$refs.map.setSearchLocation(place.geometry?.location ?? null);
      }
    },
    clearSelectedIfNotShown() {
      // Deselect communities if they are not displayed anymore
      const selectedCommunities = [];
      for (const selectedCommunityId of this.selectedCommunityIds) {
        if (this.communities.find((c) => c.id === selectedCommunityId)) {
          selectedCommunities.push(selectedCommunityId);
        }
      }
      this.selectedCommunityIds = selectedCommunities;

      // Deselect loanable if not displayed
      if (this.$refs.map.selectedLoanable) {
        let inActiveCommunity = false;
        for (const communityId of this.$refs.map.selectedLoanable.community_ids) {
          if (this.communities.find((c) => c.id === communityId)) {
            inActiveCommunity = true;
            break;
          }
        }

        if (!inActiveCommunity) {
          this.$refs.map.clearSelected();
        }
      }

      if (this.communities.length === 1) {
        this.selectedCommunityIds = [this.communities[0].id];
      }

      if (this.loanables.length === 1) {
        this.$refs.map.activateLoanable(this.loanables[0]);
      }
    },
    clearSearchInput() {
      this.placeSearchText = "";
      this.$refs.map.setSearchLocation(null);
      this.$refs.placeAutocomplete.$el.focus();
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.community-overview {
  &__sidebar {
    padding-top: 1rem;

    @include media-breakpoint-up(xl) {
      height: calc(100vh - #{$layout-navbar-height} - 1px);
      overflow: auto;
    }

    .section-toggle {
      transition-duration: 0.5s;

      .b-icon {
        transition: 0.3s;
      }

      &.not-collapsed .b-icon {
        transform: rotate(90deg);
      }
    }
  }

  &__filters {
    .form-group {
      margin: 0.75rem 0 0;
    }
  }

  .map-container {
    padding: 0;
    @include media-breakpoint-down(lg) {
      margin-top: 1rem;
      height: 100vh;
    }
  }
  .community-map {
    height: 100%;
    z-index: 10;
  }

  .community-list-leave-to,
  .community-list-enter {
    opacity: 0;
  }

  .community-list-move,
  .community-list-enter-active {
    transition: all 0.5s ease;
  }

  .community-list-leave-active {
    position: absolute !important;
    transition-duration: 0s;
  }

  .floating-community-overview-card {
    position: absolute;
    cursor: unset;
    max-width: 30rem;
    bottom: 0.5rem;
    left: 1rem;
    right: 1rem;
    z-index: 15;
    margin-inline: auto;
  }
}
</style>
