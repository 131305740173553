<template>
  <paginated-table
    id="admin-gbfs-datasets-table"
    ref="table"
    endpoint="gbfs_datasets"
    :columns="columns"
    label="Flux GBFS"
    :show-generate-csv="false"
    show-action-column
    key-column="name"
    initial-sort-by="name"
    row-link
  >
    <template #head-buttons>
      <icon-button role="add" to="/admin/gbfs_datasets/new"> Nouveau flux </icon-button>
    </template>
    <template #cell(actions)="{ item }">
      <admin-list-actions
        slug="communities"
        :item-name="item.name"
        :item="item"
        :destroy-action="destroyStream"
        :show-restore="false"
        archive-label="supprimer"
        @change="$refs.table.refresh"
      />
    </template>
  </paginated-table>
</template>

<script>
import AdminListActions from "@/components/Admin/ListActions.vue";
import IconButton from "@/components/shared/IconButton.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column } from "@/components/shared/PaginatedTableColumns";
import { del } from "@/requests/server";

export default {
  name: "AdminGbfsDatasets",
  components: {
    IconButton,
    AdminListActions,
    PaginatedTable,
  },
  data() {
    return {
      columns: [
        new Column("name", "Nom", "text", {
          urlFct: (item) => `/admin/gbfs_datasets/${item.name}`,
        }),
        new Column("notes", "Notes", "text", { formatter: this.truncateNotes }),
        Column.withoutFilter("link", "Lien", "text", {
          urlFct: (item) => item.link + "/gbfs",
          sortable: false,
          formatter: () => "lien du flux",
        }),
        Column.withRelationCount("communities_count", "# communautés"),
      ],
    };
  },
  methods: {
    async destroyStream(item) {
      await del(`/gbfs_datasets/${item.name}`, {
        notifications: { action: "Supression du flux", resourceName: "flux gbfs", onSuccess: true },
      });
    },
    truncateNotes(notes) {
      if (!notes) {
        return "";
      }

      const firstLineBreak = notes.indexOf("\n");
      const maxLength = Math.min(firstLineBreak > 0 ? firstLineBreak : notes.length, 77);

      return notes.substring(0, maxLength) + (maxLength < notes.length ? "..." : "");
    },
  },
};
</script>

<style lang="scss"></style>
