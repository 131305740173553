import { del, post } from "@/requests/server";
import RestModule from "../RestModule";

export default new RestModule(
  "communities",
  {
    form: {
      name: {
        type: "text",
        rules: {
          required: true,
        },
      },
      starting_guide_url: {
        type: "text",
        rules: {
          url: true,
        },
      },
      chat_group_url: {
        type: "text",
        rules: {
          url: true,
        },
      },
      contact_email: {
        type: "text",
        rules: {
          email: true,
        },
      },
      description: {
        type: "textarea",
      },
      type: {
        type: "select",
        label: "Type",
        options: [
          {
            text: "Privée",
            value: "private",
          },
          {
            text: "Quartier",
            value: "borough",
          },
        ],
        rules: {
          nullable: true,
          oneOf: ["private", "borough"],
        },
      },
      exempt_from_contributions: {
        type: "checkbox",
        rules: {
          boolean: true,
        },
      },
      requires_residency_proof: {
        type: "checkbox",
        rules: {
          boolean: true,
        },
      },
      requires_identity_proof: {
        type: "checkbox",
        rules: {
          boolean: true,
        },
      },
      requires_custom_proof: {
        type: "checkbox",
        rules: {
          boolean: true,
        },
      },
      custom_proof_name: {
        type: "text",
        rules: {
          nullable: true,
        },
      },
      custom_proof_desc: {
        type: "textarea",
        rules: {
          max: 10000,
        },
      },
      members_can_add_loanables: {
        type: "checkbox",
        rules: {
          boolean: true,
        },
      },
      long_description: {},
      area: {},
      custom_registration_approved_email: {
        rules: {
          max: 10000,
        },
      },
    },
    empty: {
      name: "",
      chat_group_url: "",
      starting_guide_url: "",
      description: null,
      contact_email: null,
      area: [],
      type: "private",
      pricings: [],
      exempt_from_contributions: false,
      requires_residency_proof: false,
      requires_identity_proof: false,
      requires_custom_proof: false,
    },
  },
  {
    async addUser({ commit }, { communityId, userId }) {
      const response = await post(
        `/communities/${communityId}/users`,
        { id: userId },
        {
          requestOptions: { cancelId: "add-user" },
          notifications: { action: "ajout de membre" },
        }
      );
      commit("users/addData", [response.data], { root: true });
    },
    async removeUser(store, { id, userId }) {
      await del(`/communities/${id}/users/${userId}`, {
        requestOptions: { cancelId: "remove-user" },
        notifications: { action: "retrait de membre" },
      });
    },
  }
);
