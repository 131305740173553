<template>
  <div v-if="show" class="conditional-context" :class="{ large, inset }">
    <span v-if="label" class="conditional-context-label" :class="background"
      >{{ label }}
      <b-icon
        v-if="additionalInfo"
        v-b-tooltip="additionalInfo"
        :scale="0.75"
        icon="info-circle"
      ></b-icon>
    </span>
    <slot />
  </div>
  <div v-else>
    <slot />
  </div>
</template>

<script>
export default {
  name: "ConditionalContext",
  props: {
    show: {
      type: Boolean,
    },
    label: {
      type: String,
      default: undefined,
    },
    additionalInfo: {
      type: String,
      default: undefined,
    },
    background: {
      type: String,
      default: "main",
    },
    large: {
      type: Boolean,
      default: false,
    },
    inset: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.conditional-context {
  padding: 1rem 1rem 0;
  border-radius: 0.5rem;
  border: 1px solid $light-grey;
  margin-bottom: 1rem;
  position: relative;
  &.inset {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .conditional-context-label {
    position: absolute;
    top: -0.5rem;
    font-size: 0.8rem;
    color: $text-muted;
    font-weight: 600;
    padding: 0rem 0.5rem;
    margin-left: -0.25rem;
    &.white {
      background-color: white;
    }
    &.main {
      background-color: $main-bg;
    }
  }
  &.large {
    padding-top: 1.15rem;
    .conditional-context-label {
      top: -0.9125rem;
      font-size: 1.25rem;
      font-weight: normal;
    }
  }
}
</style>
