<script>
import FormsFilesUploader from "@/components/Forms/FilesUploader.vue";

import locales from "@/locales";
import MarkdownContent from "@/components/shared/MarkdownContent.vue";

export default {
  name: "CustomProofInput",
  components: {
    MarkdownContent,
    FormsFilesUploader,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    communityUser: {
      type: Object,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    proofFiles: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  i18n: {
    messages: {
      fr: {
        ...locales.fr.communities,
        ...locales.fr.forms,
      },
    },
  },
};
</script>

<template>
  <div class="instructions">
    <markdown-content :content="communityUser.community.custom_proof_desc" />

    <forms-files-uploader
      :id="'custom_proof_' + communityUser.id"
      v-model="proofFiles"
      no-label
      class="mb-0"
      center
      :label="$t('communities.fields.custom_proof')"
      :disabled="loading"
      :field="'custom_proof'"
      required
    />
  </div>
</template>

<style lang="scss" scoped>
.instructions {
  ul {
    list-style-type: none;
    padding-left: 1rem;
    li {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
