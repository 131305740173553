<template>
  <div class="admin-list-actions text-right">
    <b-dropdown size="sm" variant="white-primary" no-caret toggle-class="mr-0">
      <template #button-content>
        <b-icon icon="three-dots-vertical" /><span class="sr-only">actions</span>
      </template>
      <b-dropdown-item v-if="showView && !item[deletedField]" :to="`/admin/${slug}/${item.id}`">
        {{ $t("forms.view") | capitalize }}
      </b-dropdown-item>
      <validated-button
        v-if="showDestroy && !item[deletedField]"
        type="b-dropdown-item"
        variant="danger"
        :action="destroy"
        :label="archiveText"
        :icon="archiveIcon ? archiveIcon : 'trash'"
        @hidden="$emit('destroy-hidden')"
      >
        <template #question="{ hide }">
          <slot name="destroy-question" :hide="hide">
            {{ `Êtes-vous sûr-e de vouloir ${archiveText} ${itemName}?` }}
          </slot>
        </template>
      </validated-button>
      <validated-button
        v-if="showRestore && !!item[deletedField]"
        type="b-dropdown-item"
        variant="warning"
        :action="restore"
        :label="restoreText"
        icon="recycle"
        @hidden="$emit('restore-hidden')"
      >
        <template #question="{ hide }">
          <slot name="restore-question" :hide="hide">
            {{ `Êtes-vous sûr-e de vouloir ${restoreText} ${itemName}?` }}
          </slot>
        </template>
      </validated-button>
      <slot />
    </b-dropdown>
  </div>
</template>

<script>
import ValidatedButton from "@/components/Admin/ValidatedButton.vue";

export default {
  name: "AdminListActions",
  components: { ValidatedButton },
  props: {
    showView: {
      type: Boolean,
      default: false,
    },
    showDestroy: {
      type: Boolean,
      default: true,
    },
    showRestore: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      required: true,
    },
    itemName: {
      type: String,
      required: true,
    },
    slug: {
      required: true,
      type: String,
    },
    destroyAction: {
      type: Function,
      default: undefined,
    },
    restoreAction: {
      type: Function,
      default: undefined,
    },
    deletedField: {
      type: String,
      default: "deleted_at",
    },
    restoreParams: {
      type: Object,
      default: () => ({}),
    },
    destroyParams: {
      type: Object,
      default: () => ({}),
    },
    archiveLabel: {
      type: String,
      default: undefined,
    },
    restoreLabel: {
      type: String,
      default: undefined,
    },
    archiveIcon: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    archiveText() {
      return this.archiveLabel ? this.archiveLabel : this.$t("forms.archive");
    },
    restoreText() {
      return this.restoreLabel ? this.restoreLabel : this.$t("forms.restore");
    },
  },
  methods: {
    async destroy() {
      if (this.destroyAction) {
        await this.destroyAction(this.item);
      } else {
        await this.$store.dispatch(`${this.slug}/destroy`, {
          id: this.item.id,
          data: this.destroyParams,
        });
      }
      this.$emit("change");
    },
    async restore() {
      if (this.restoreAction) {
        await this.restoreAction(this.item);
      } else {
        await this.$store.dispatch(`${this.slug}/restore`, {
          id: this.item.id,
          params: this.restoreParams,
        });
      }
      this.$emit("change");
    },
  },
};
</script>

<style lang="scss"></style>
