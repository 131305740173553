import DashboardBalance from "./components/Dashboard/Balance";
import LoginBox from "./components/Login/Box";
import RegisterForm from "./components/Register/RegisterForm";
import UserEmailForm from "./components/User/EmailForm";
import UserPasswordForm from "./components/User/PasswordForm";
import faq from "./faq";
import bikes from "./models/bikes";
import bill_items from "./models/bill_items";
import borrowers from "./models/borrowers";
import cars from "./models/cars";
import communities from "./models/communities";
import fr from "./fr";
import merge from "deepmerge";
import invoices from "./models/invoices";
import libraries from "./models/libraries";
import loanables from "./models/loanables";
import loans from "./models/loans";
import paymentMethods from "./models/paymentMethods";
import pricings from "./models/pricings";
import trailers from "./models/trailers";
import users from "./models/users";
import Conditions from "./views/Conditions";
import Dashboard from "./views/Dashboard";
import Loan from "./views/Loan";
import PasswordRequest from "./views/Password/Request";
import PasswordReset from "./views/Password/Reset";
import AccountProfile from "./views/Profile/Account";

const messages = merge.all([
  fr,
  faq,

  // Models
  bikes,
  bill_items,
  borrowers,
  cars,
  communities,
  invoices,
  libraries,
  loans,
  loanables,
  paymentMethods,
  pricings,
  trailers,
  users,

  // Components
  DashboardBalance,
  LoginBox,
  RegisterForm,
  UserEmailForm,
  UserPasswordForm,

  // Views
  Conditions,
  Dashboard,
  AccountProfile,
  PasswordRequest,
  PasswordReset,
  Loan,
]);

export default messages;
