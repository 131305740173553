<template>
  <paginated-table
    id="profile-loans-table"
    ref="table"
    label="Emprunts"
    :columns="columns"
    :extra-filters="extraFilters"
    :load-relations="['loanable.active_incidents', 'loanable.merged_user_roles.user']"
    endpoint="loans"
    :fetch-params="{ for: 'profile' }"
    sync-filters-with-url
    :show-action-column="false"
    @filters-changed="filtersUpdated"
    @row-clicked="(item) => $router.push(`/loans/${item.id}`)"
  >
    <template #cell(status)="{ item }">
      <loan-status :item="item"></loan-status>
    </template>
    <template #head-buttons>
      <b-dropdown
        right
        :text="modeText"
        variant="ghost-secondary"
        :toggle-class="{ active: modeText !== 'Tous les emprunts' }"
      >
        <b-dropdown-item v-if="modeText !== 'Tous les emprunts'" @click="showAllLoans">
          Tous les emprunts
        </b-dropdown-item>
        <b-dropdown-item v-if="modeText !== 'Mes emprunts'" @click="showMyLoans">
          Mes emprunts
        </b-dropdown-item>
        <b-dropdown-item
          v-if="modeText !== 'Emprunts de mes véhicules'"
          @click="showLoansOfMyLoanables"
        >
          Emprunts de mes véhicules
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </paginated-table>
</template>

<script>
import LoanStatus from "@/components/Loan/Status/LoanStatusBadge.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column, Filter } from "@/components/shared/PaginatedTableColumns";
import { capitalize } from "@/helpers/filters";
import Authenticated from "@/mixins/Authenticated";
import dayjs from "dayjs";

export default {
  name: "ProfileLoans",
  components: { LoanStatus, PaginatedTable },
  mixins: [Authenticated],
  data() {
    return {
      filters: {},
      extraFilters: [
        new Filter("loanable.ownedOrCoownedBy", "Géré par", "relation", {
          relation: "users",
          label: "full_name",
          field: "id",
          params: { for: "profile" },
        }),
      ],
      columns: [
        new Column("id", "ID", "id", { showByDefault: false }),
        new Column("departure_at", "Départ", "date", {
          formatter: (value, key, item) =>
            dayjs.atTz(value, item.loanable.timezone).format("D MMMM YYYY HH:mm"),
        }),
        new Column("actual_return_at", "Retour", "date", {
          showByDefault: false,
          formatter: (value, key, item) =>
            dayjs.atTz(value, item.loanable.timezone).format("D MMMM YYYY HH:mm"),
        }),
        Column.withRelation(
          "borrower_user.full_name",
          "Emprunteur",
          {
            relation: "users",
            label: "full_name",
            field: "id",
            params: { for: "profile" },
          },
          "borrower_user.id"
        ),
        Column.withRelation(
          "loanable.owner_user.full_name",
          "Propriétaire",
          {
            relation: "users",
            label: "full_name",
            field: "id",
            params: { for: "profile" },
          },
          "loanable.owner_user_id",
          { showByDefault: false, sortable: false }
        ),
        Column.withRelation(
          "community.name",
          "Communauté",
          { relation: "communities", label: "name", field: "id", params: { for: "admin" } },
          "community_id",
          { showByDefault: false }
        ),
        Column.withRelation(
          "loanable.name",
          "Véhicule",
          {
            relation: "loanables",
            label: "name",
            field: "id",
            params: { with_deleted: true },
          },
          "loanable.id",
          {
            urlFct: (item) => {
              if (item.loanable.deleted_at) {
                return null;
              }

              if (item.loanable.can_edit) {
                return `/profile/loanables/${item.loanable.id}`;
              }

              return null;
            },
          }
        ),

        Column.withSelect("status", "Statut", [
          { value: null, label: "Tous" },
          { value: "requested", label: this.$t("loans.statuses.requested.label") },
          { value: "accepted", label: this.$t("loans.statuses.accepted.label") },
          { value: "confirmed", label: this.$t("loans.statuses.confirmed.label") },
          { value: "ongoing", label: this.$t("loans.statuses.ongoing.label") },
          { value: "ended", label: this.$t("loans.statuses.ended.label") },
          { value: "validated", label: this.$t("loans.statuses.validated.label") },
          { value: "completed", label: this.$t("loans.statuses.completed.label") },
          { value: "rejected", label: this.$t("loans.statuses.rejected.label") },
          { value: "canceled", label: this.$t("loans.statuses.canceled.label") },
        ]),
        new Column("duration_in_minutes", "Durée (minutes)", "number", {
          showByDefault: false,
        }),
        Column.withSelect(
          "loanable.type",
          "Type de véhicule",
          [
            { value: null, label: "Tous" },
            { value: "car", label: "auto" },
            { value: "trailer", label: "Remorque" },
            { value: "bike", label: "vélo" },
          ],
          { showByDefault: false }
        ),

        Column.withSelect(
          "is_self_service",
          capitalize(this.$t("loans.self_service.is_self_service")),
          [
            { value: null, label: capitalize(this.$t("loans.self_service.all")) },
            { value: true, label: capitalize(this.$t("loans.self_service.is_self_service")) },
            { value: false, label: capitalize(this.$t("loans.self_service.on_demand")) },
          ],
          { showByDefault: false }
        ),
      ],
    };
  },
  computed: {
    modeText() {
      if (this.filters["borrower_user.id"] === this.$store.state.user.id) {
        return "Mes emprunts";
      }

      if (this.filters["loanable.ownedOrCoownedBy"] === this.$store.state.user.id) {
        return "Emprunts de mes véhicules";
      }

      return "Tous les emprunts";
    },
  },
  methods: {
    filtersUpdated(filters) {
      this.filters = filters;
    },
    showMyLoans() {
      const filters = {
        ...this.filters,
        "borrower_user.id": this.$store.state.user.id,
      };

      delete filters["loanable.ownedOrCoownedBy"];

      this.$refs.table.setFilters(filters);
    },
    showAllLoans() {
      const filters = {
        ...this.filters,
      };

      delete filters["loanable.ownedOrCoownedBy"];
      delete filters["borrower_user.id"];

      this.$refs.table.setFilters(filters);
    },
    showLoansOfMyLoanables() {
      const filters = {
        ...this.filters,
        "loanable.ownedOrCoownedBy": this.$store.state.user.id,
      };

      delete filters["borrower_user.id"];

      this.$refs.table.setFilters(filters);
    },
  },
};
</script>

<style lang="scss"></style>
