<template>
  <div v-if="itemLoaded && routeDataLoaded" class="profile-invoice" fluid>
    <single-invoice :invoice="item" />
  </div>
  <layout-loading v-else />
</template>

<script>
import SingleInvoice from "@/components/Invoice/Single.vue";

import Authenticated from "@/mixins/Authenticated";
import DataRouteGuards from "@/mixins/DataRouteGuards";
import FormMixin from "@/mixins/FormMixin";

export default {
  name: "ProfileInvoice",
  components: { SingleInvoice },
  mixins: [Authenticated, DataRouteGuards, FormMixin],
};
</script>

<style lang="scss"></style>
