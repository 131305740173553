<script>
import Vue from "vue";

export default Vue.extend({
  name: "DropdownSelect",
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number],
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    onselect: {
      type: Function,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    selectedOption() {
      const selectedOptions = this.options.filter((item) => item.value === this.value);
      return selectedOptions.length > 0 ? selectedOptions[0] : null;
    },
    selectedLabel() {
      return this.selectedOption?.label ?? null;
    },
    selectedIcon() {
      if (this.selectedOption?.icon) {
        return this.selectedOption.icon;
      }
      return this.icon;
    },
  },
  methods: {
    async handleSelect(value) {
      if (this.onselect) {
        this.loading = true;
        try {
          await this.onselect(value);
        } finally {
          this.loading = false;
        }
      } else {
        this.$emit("select", value);
        this.$emit("input", value);
      }
    },
  },
});
</script>

<template>
  <b-dropdown
    toggle-class="icon-button"
    :size="size"
    variant="ghost-secondary"
    :disabled="loading || disabled"
    v-bind="$attrs"
  >
    <template #button-content>
      <b-spinner v-if="loading" class="spinner" small />
      <b-icon v-else-if="selectedIcon" :icon="selectedIcon" />
      <div>
        <span v-if="label">{{ label }} : </span
        ><span :class="{ 'font-weight-bold': label }">{{ selectedLabel }}</span>
      </div>
    </template>
    <b-dropdown-item
      v-for="option in options"
      :key="option.value"
      :active="value === option.value"
      @click="() => handleSelect(option.value)"
      >{{ option.label }}</b-dropdown-item
    >
  </b-dropdown>
</template>

<style scoped lang="scss"></style>
