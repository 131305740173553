<script lang="js">
import { getOwner } from "@/helpers/permissions/loanables";
import ImageCarousel from "@/components/shared/ImageCarousel.vue";
import LoanableIcon from "@/components/Loanable/LoanableIcon.vue";
import UserAvatar from "@/components/User/Avatar.vue";

export default {
  name: "LoanableCarousel",
  components: {
    ImageCarousel,
    LoanableIcon,
    UserAvatar,
  },
  props: {
    loanable: {
      type: Object,
      required: true,
    },
    showOwner: {
      type: Boolean,
      default: false,
    },
    showSharingMode: {
      type: Boolean,
      default: false,
    },
    showLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ownerUser() {
      return getOwner(this.loanable);
    },
    userTrustedForLoanable() {
      return this.$store.state.user?.trusted_for_loanables?.includes(this.loanable.id);
    },
  },
};
</script>

<template>
  <div>
    <image-carousel
      v-if="loanable && loanable.images && loanable.images.length > 0"
      class="loanable-details__image"
      :images="loanable.images"
      aspect-ratio="16 / 10"
      :preferred-sizes="['loan', 'thumbnail', 'original']"
    />
    <loanable-icon
      v-else
      :loanable="loanable"
      class="loanable-details__image loanable-details__image--default"
    />

    <div class="loanable-context-overlay">
      <div class="loanable-context-label">
        <div>
          <div
            class="loanable-context-name"
            :class="{ 'loanable-context-name-large': !$slots['loanable-context-label'] }"
          >
            <b-link v-if="showLink && !loanable.deleted_at" :to="`/loanables/${loanable.id}`">
              {{ loanable.name }}
            </b-link>
            <span v-else>{{ loanable.name }}</span>
          </div>
          <slot name="loanable-context-label"></slot>
          <div v-if="showSharingMode" class="loanable-details__tags">
            <b-badge
              v-if="
                loanable.sharing_mode === 'self_service' ||
                (loanable.sharing_mode === 'hybrid' && userTrustedForLoanable)
              "
              variant="primary"
            >
              <b-icon icon="unlock-fill" />
              {{ $t("loanables.sharing_modes.self_service") | capitalize }}</b-badge
            >
            <b-badge v-else variant="warning">
              <b-icon icon="chat-dots-fill" />
              {{ $t("loanables.sharing_modes.on_demand") | capitalize }}</b-badge
            >
          </div>
        </div>
        <user-avatar
          v-if="showOwner"
          :user="ownerUser"
          variant="cut-out"
          class="loanable-details__avatar"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.loanable-context-overlay {
  position: absolute;
  pointer-events: none;
  max-width: 100%;
  padding: 0.5rem;

  @container (min-width: 20rem) {
    padding: 1rem;
  }

  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  margin: auto;
  background: transparentize($main-bg, 0.3);
  display: flex;
  background: linear-gradient(
    5deg,
    hsla(170, 8.11%, 29.02%, 0.73) 0%,
    hsla(170.77, 8.84%, 28.82%, 0.722) 8.1%,
    hsla(171.43, 9.72%, 28.24%, 0.701) 15.5%,
    hsla(170, 12.86%, 27.45%, 0.669) 22.5%,
    hsla(168.57, 15.56%, 26.47%, 0.627) 29%,
    hsla(170, 18.75%, 25.1%, 0.577) 35.3%,
    hsla(168.89, 22.31%, 23.73%, 0.522) 41.2%,
    hsla(168, 26.32%, 22.35%, 0.464) 47.1%,
    hsla(168.75, 30.19%, 20.78%, 0.406) 52.9%,
    hsla(169.41, 34.69%, 19.22%, 0.348) 58.8%,
    hsla(168, 38.46%, 17.84%, 0.293) 64.7%,
    hsla(168.33, 42.86%, 16.47%, 0.243) 71%,
    hsla(168, 44.3%, 15.49%, 0.201) 77.5%,
    hsla(168, 46.67%, 14.71%, 0.169) 84.5%,
    hsla(168.33, 50%, 14.12%, 0.148) 91.9%,
    hsla(168, 49.3%, 13.92%, 0.14) 100%
  );

  .loanable-context-label {
    color: white;
    align-self: flex-end;
    pointer-events: all;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;

    @container (min-width: 20rem) {
      gap: 1rem;
    }
    align-items: flex-end;
    width: 100%;
    .loanable-context-name-large {
      margin-bottom: 0;
      font-size: 1rem;
      line-height: 1.2;
      font-weight: 400;
    }
    .loanable-details__avatar {
      flex-shrink: 0;
    }
    a {
      color: white;
      text-decoration: underline;
    }
  }
}
.loanable-details__image {
  position: relative;
  aspect-ratio: 16 / 10;
  width: 100%;
  .carousel-indicators {
    bottom: auto;
    top: 0;
  }
}

.loanable-details__image--default {
  background: $locomotion-green;
  fill: $locomotion-dark-green;
  position: relative;
  padding: 2rem;
}

.loanable-details__tags {
  margin-top: 0.25rem;
}
</style>
