<template>
  <layout-loading v-if="loading" />
  <div v-else-if="!loaded" class="text-center">
    <p>Erreur de chargement de la communauté.</p>
    <icon-button variant="primary" icon="arrow-clockwise" :onclick="load">Réessayer</icon-button>
  </div>
  <div v-else class="profile-community">
    <h2 class="mb-3 font-weight-bold text-primary">{{ communityUser.community.name }}</h2>

    <b-card v-if="showContributionBox" class="profile-community__card">
      <form-section toggleable inititally-visible tag="h3" section-title="Ma contribution">
        <profile-community-subscriptions
          :community="communityUser.community"
          :pricings-per-loanable-type="communityUser.new_subscription_pricings"
          :current-granted-subscription="communityUser.current_granted_subscription"
          :current-paid-subscription="communityUser.current_paid_subscription"
          @paid="load"
        />
      </form-section>
    </b-card>
    <hr v-if="showContributionBox" class="mt-0" />

    <b-card class="profile-community__card">
      <form-section
        section-title="Mes preuves"
        :toggleable="!!showContributionBox"
        :inititally-visible="!showContributionBox || communityUser.status !== 'approved'"
        tag="h3"
      >
        <proof-form :community-user="communityUser" :user="user" @submitted="onSubmitted" />
      </form-section>
    </b-card>
  </div>
</template>

<script>
import LayoutLoading from "@/components/Layout/Loading.vue";
import IconButton from "@/components/shared/IconButton.vue";
import ProofForm from "@/components/Community/ProofForm.vue";
import { get } from "@/requests/server";
import ProfileCommunitySubscriptions from "@/views/profile/CommunitySubscriptions.vue";
import FormSection from "@/components/Loanable/FormSection.vue";
export default {
  name: "ProfileCommunities",
  components: { IconButton, LayoutLoading, ProofForm, ProfileCommunitySubscriptions, FormSection },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      loading: true,
      communityUser: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    showContributionBox() {
      return (
        this.communityUser.status === "approved" &&
        (this.communityUser.new_subscription_pricings ||
          this.communityUser.current_granted_subscription ||
          this.communityUser.current_paid_subscription)
      );
    },
  },
  beforeMount() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;

      const { data } = await get(
        `/users/${this.$store.state.user.id}/communities/${this.id}?relations=community,custom_proof,current_granted_subscription.granted_by_user,current_paid_subscription`,
        {
          notifications: { action: "chargement de la communauté" },
          cleanupCallback: () => (this.loading = false),
        }
      );
      this.loaded = true;
      this.communityUser = data;
    },
    onSubmitted() {
      // Reload user with updated proofs
      this.$store.dispatch("loadUser");

      if (this.communityUser.status !== "approved") {
        this.$store.commit("addNotification", {
          content: "Un membre de notre équipe va vérifier votre preuve et valider votre profil.",
          title: "Preuves soumises!",
          variant: "success",
        });
        this.$router.push("/profile/communities");
      } else {
        this.$store.commit("addNotification", {
          title: "Profil modifiées avec succès!",
          variant: "success",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.profile-community {
  &__card {
    box-shadow: $small-shadow;
  }

  .form__section {
    padding-bottom: 0;
    h3 {
      margin-bottom: 1rem;
    }
  }
}
</style>
