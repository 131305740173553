<template>
  <div>
    <paginated-table
      id="profile-loanables-table"
      ref="loanablesTable"
      label="Véhicules"
      endpoint="loanables"
      :columns="columns"
      :fetch-params="{
        for: 'profile',
      }"
      :load-relations="['active_incidents', 'merged_user_roles.user']"
      :show-action-column="true"
      :extra-filters="extraFilters"
      @row-clicked="(item) => $router.push(`/loanables/${item.id}`)"
    >
      <template #head-buttons>
        <icon-button role="add" to="/profile/loanables/new"> Ajouter un véhicule </icon-button>
      </template>

      <template #cell(availability_status)="{ item }">
        <availability-status :loanable="item"
      /></template>

      <template #cell(actions)="{ item }">
        <icon-button
          v-if="canEditLoanable(item)"
          role="edit"
          size="sm"
          :to="`/profile/loanables/${item.id}`"
          @click.stop.prevent=""
        />
        <icon-button
          v-if="canDeleteLoanable(item)"
          role="delete"
          size="sm"
          :onclick="async () => disableLoanableModal(item)"
          @click.stop.prevent=""
        />
      </template>
    </paginated-table>

    <template v-if="user.has_fleet">
      <hr />
      <paginated-table
        id="profile-libraries-table"
        ref="libraries-table"
        endpoint="libraries"
        :fetch-params="{
          for: 'profile',
        }"
        :label="$t('titles.libraries') | capitalize"
        :columns="libraries.columns"
        :extra-filters="libraries.extraFilters"
        :show-generate-csv="false"
        sync-filters-with-url
        @row-clicked="(item) => $router.push(`/profile/libraries/${item.id}`)"
      >
        <template #cell(actions)="{ item }">
          <validated-button
            v-if="canDeleteLibrary(item)"
            :action="async () => deleteLibrary(item)"
            :question="`Êtes-vous sûr de vouloir retirer ${item.name}?`"
            label="retirer"
            icon="trash"
            size="sm"
            variant="ghost-danger"
            :show-button-label="false"
          />
        </template>
      </paginated-table>
    </template>
  </div>
</template>

<script>
import ValidatedButton from "@/components/Admin/ValidatedButton.vue";
import AvailabilityStatus from "@/components/Loanable/AvailabilityStatus.vue";
import IconButton from "@/components/shared/IconButton.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column, Filter } from "@/components/shared/PaginatedTableColumns";
import {
  canDeleteLoanable,
  canEditLoanable,
  hasLibraryRole,
} from "@/helpers/permissions/loanables";
import Authenticated from "@/mixins/Authenticated";
import { del } from "@/requests/server";

export default {
  name: "ProfileLoanables",
  components: { ValidatedButton, AvailabilityStatus, IconButton, PaginatedTable },
  mixins: [Authenticated],
  data() {
    return {
      libraries: {
        extraFilters: [
          new Filter("sharedInCommunity", "Communauté", "relation", {
            relation: "communities",
            label: "name",
            field: "id",
            params: { for: "admin" },
          }),
        ],

        columns: [
          new Column("id", "ID", "id"),
          new Column("name", "Nom", "text"),
          Column.withRelation(
            "owner_user.full_name",
            "Propriétaire",
            {
              relation: "users",
              label: "full_name",
              field: "id",
              params: { for: "admin" },
            },
            "owner_user_id",
            {
              sortable: false,
            }
          ),
          Column.withoutFilter("communities_count", "Nombre de communautés", "number", {
            sortable: false,
          }),
          Column.withoutFilter("loanables_count", "Nombre de véhicules", "number", {
            sortable: false,
          }),
        ],
      },
      extraFilters: [
        new Filter("sharedInCommunity", "Communauté", "relation", {
          relation: "communities",
          label: "name",
          field: "id",
          params: { for: "profile" },
        }),
      ],

      columns: [
        new Column("id", "ID", "id", { showByDefault: false }),
        new Column("name", "Nom", "text"),
        Column.withSelect("type", "Type", [
          { value: null, label: "Tous" },
          { value: "car", label: "Auto" },
          { value: "trailer", label: "Remorque" },
          { value: "bike", label: "Vélo" },
        ]),
        Column.withRelation(
          "owner_user.full_name",
          "Propriétaire",
          {
            relation: "users",
            label: "full_name",
            field: "id",
          },
          "owner_user_id",
          {
            sortable: false,
          }
        ),

        Column.withRelation(
          "library.name",
          "Flotte",
          {
            relation: "libraries",
            label: "name",
            field: "id",
          },
          "library_id",
          {
            showByDefault: false,
          }
        ),
        new Column("current_loan.id", "Emprunt en cours", "text", {
          urlFct: (loanable) => `/loans/${loanable.current_loan?.id}`,
          sortable: false,
        }),
        new Column("current_loan.borrower_user.full_name", "Emprunteur\u2011se", "text", {
          sortable: false,
        }),
        Column.withSelect("availability_status", "Statut d'affichage", [
          { value: null, label: "Tous" },
          {
            value: "no_approved_communities",
            label: "Propriétaire sans communauté",
            variant: "warning",
          },
          { value: "wrong_type", label: "Type non partagé", variant: "danger" },
          { value: "no_availabilities", label: "Aucune disponibilité", variant: "warning" },
          { value: "unpublished", label: "Non publié", variant: "secondary" },
          { value: "has_availabilities", label: "Affiché", variant: "success" },
        ]),
      ],
    };
  },
  computed: {},
  methods: {
    canDeleteLoanable(loanable) {
      return canDeleteLoanable(this.$store.state.user, loanable);
    },
    canEditLoanable(loanable) {
      return canEditLoanable(this.$store.state.user, loanable);
    },
    async disableLoanableModal(loanable) {
      await this.$bvModal
        .msgBoxConfirm("Êtes-vous sûr-e de vouloir retirer ce véhicule de la plateforme?", {
          size: "sm",
          buttonSize: "sm",
          okTitle: "Oui, retirer",
          cancelTitle: "Annuler",
          okVariant: "danger",
          cancelVariant: "ghost-secondary",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.disableLoanable(loanable);
          }
        });
    },
    async disableLoanable(loanable) {
      await this.$store.dispatch("loanables/disable", loanable.id);
      this.$refs.loanablesTable.refresh();
      this.$store.dispatch("dashboard/loadLoanables");
    },
    canDeleteLibrary(library) {
      return hasLibraryRole(this.$store.state.user, library, "owner");
    },
    async deleteLibrary(library) {
      await del("libraries/" + library.id, {
        notifications: {
          action: "suppression de la flotte",
          resourceName: "flotte",
          onSuccess: true,
        },
      });
      this.$refs["libraries-table"].refresh();
    },
  },
};
</script>

<style lang="scss">
.profile-loanables__loanables__loanable {
  margin-bottom: 20px;
}
</style>
