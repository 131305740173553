import dayjs from "dayjs";

/**
 *
 * @return {dayjs.Dayjs}
 */
export function getEarliestDeparture() {
  const now = new dayjs();
  const minutesToAdd = 15 - (now.minute() % 15); // Clamp to next 15 minute interval
  return now.add(minutesToAdd, "minutes");
}
