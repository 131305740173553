<template>
  <vue-cal
    ref="vueCal"
    :class="classList"
    :disable-views="disableViews"
    :active-view="defaultView"
    :snap-to-time="15"
    :time-step="60"
    :time-cell-height="32"
    :events="vueCalEvents"
    click-to-navigate
    locale="fr"
    events-on-month-view
    :selected-date="initialDate"
    :on-event-click="onEventClick"
    @ready="$emit('ready', $event)"
    @view-change="onViewChanged"
    @event-mouse-enter="eventEnter"
    @event-mouse-leave="eventLeave"
  >
    <template #title="{ view }">
      <div class="d-flex align-items-center position-relative">
        <span v-if="view.id === 'years'">Years</span>
        <span v-else-if="view.id === 'year'">{{ view.startDate.format("YYYY") }}</span>
        <span v-else-if="view.id === 'month'">{{ view.startDate.format("MMMM YYYY") }}</span>
        <span v-else-if="view.id === 'week'">{{ view.startDate.format("MMMM YYYY") }}</span>
        <span v-else-if="view.id === 'day'">{{ view.startDate.format("dddd D MMMM (YYYY)") }}</span>
        <b-spinner v-if="loading" class="loanable-calendar__loading-spinner" small />
      </div>
    </template>

    <template #time-cell="{ hours, minutes }">
      <div
        :class="{
          'loanable-calendar__time-step--hours': !minutes,
          'loanable-calendar__time-step--minutes': minutes,
          'vuecal__time-cell-line': true,
        }"
      >
        <span class="line"></span>
        <template v-if="!minutes">
          <span
            >{{ hours < 10 ? "0" + hours : hours }}:{{
              minutes < 10 ? "0" + minutes : minutes
            }}</span
          >
        </template>
      </div>
    </template>

    <template v-if="availabilityMode" #cell-content="cellData">
      <calendar-month-cell-content
        v-if="cellData.view.id === 'month'"
        :availability="getMonthDayAvailability(cellData.events, cellData.cell)"
        :current="cellData.cell.today"
      >
        {{ cellData.cell.content }}
      </calendar-month-cell-content>

      <span v-else class="vuecal__cell-date">
        {{ cellData.cell.content }}
      </span>
    </template>

    <template #event="{ event }">
      <b-icon v-if="event.data.available === false" icon="calendar2-x" />
      <b-icon v-if="event.data.available === true" icon="calendar2-check" />

      <div class="hover-target"></div>
      <span
        v-if="
          event.data.available !== undefined && (event.title || event.startDate || event.endDate)
        "
        >&nbsp;
      </span>
      <div v-if="event.title" class="vuecal__event-title">
        {{ event.title }}
      </div>

      <small class="vuecal__event-time">
        <span class="vuecal__event-time__start">
          <loan-status-icon
            v-if="event.data.status"
            :status="event.data.status"
            :action-required="event.data.action_required"
          />
          {{ formatEventTimeStart(event) }}</span
        >
        <span class="vuecal__event-time__end">{{ formatEventTimeEnd(event) }} </span>
        <span class="vuecal__event-time__span">{{ formatEventTimeSpan(event) }}</span>
      </small>
    </template>
  </vue-cal>
</template>

<script>
import LoanStatusIcon from "@/components/Loan/Status/LoanStatusIcon.vue";
import CalendarMonthCellContent from "@/components/Loanable/CalendarMonthCellContent.vue";
import dayjs from "dayjs";
import VueCal from "vue-cal";

export default {
  name: "LoanableCalendar",
  components: {
    LoanStatusIcon,
    VueCal,
    "calendar-month-cell-content": CalendarMonthCellContent,
  },
  props: {
    defaultView: {
      type: String,
      default: "week",
    },
    events: {
      type: Array,
      default: () => [],
    },
    initialDate: {
      type: [String, Date],
      default: "",
    },
    monthDayDetail: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disableViews: {
      type: Array,
      default: () => ["years", "year"],
    },
    // Default availability mode if no availabilty event is found.
    availabilityMode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      lastView: null,
    };
  },
  computed: {
    classList: function () {
      let classList = {
        "loanable-calendar": true,
      };
      if (this.variant) {
        classList["loanable-calendar--" + this.variant] = true;
      }
      return classList;
    },
    vueCalEvents: function () {
      let immtableEvent = {
        resizable: false,
        draggable: false,
        titleEditable: false,
        deletable: false,
      };

      let vueCalEvents = this.events.map((e) => ({ ...immtableEvent, ...e }));

      let baseEvent = {
        deletable: false,
        class: ["loanable-calendar__event"],
      };
      return vueCalEvents.map((e) => {
        e = { ...baseEvent, ...e, class: [...(e.class || [])] };

        e.class.push("loanable-calendar__event");

        if (e.type === "availability" || e.type === "availability_rule") {
          // Availability events go in the background.
          e.background = true;
          if (e.data.available) {
            e.class.push("loanable-calendar__event--availability");
          } else {
            e.class.push("loanable-calendar__event--unavailability");
          }
        } else if (e.type === "loan") {
          // Loans don't go in the background.
          e.background = false;

          e.class.push("loanable-loan-event");

          if (e.data.action_required) {
            e.class.push("loan-status-action-required");
          } else {
            e.class.push("loan-status-" + e.data.status);
          }

          if (!e.data.accepted) {
            e.class.push("loanable-calendar__event--loan_needs_approval");
          }
        }

        if (this.eventClickable(e)) {
          e.class.push("loanable-calendar__event--clickable");
        }

        // Pass class as a string to vue-cal.
        e.class = e.class.join(" ");

        return e;
      });
    },
  },
  mounted() {
    this.scrollPotentialEventIntoView(this.defaultView);
  },
  methods: {
    eventClickable(event) {
      return event.uri && this.$route.path !== event.uri;
    },
    onViewChanged(event) {
      this.$emit("view-change", event);
      this.scrollPotentialEventIntoView(event.view);
    },
    scrollPotentialEventIntoView(view) {
      if (view && view === this.lastView) {
        // no scroll necessary when transitioning within the same view
        return;
      }
      this.lastView = view;
      if (view !== "week" && view !== "day") {
        return;
      }
      setTimeout(() => {
        let viewElement = this.$el.getElementsByClassName(`${view}-view`);
        if (!viewElement || viewElement.length === 0) return;
        let potentialLoan = viewElement[0].getElementsByClassName("loan-status-potential");
        if (!potentialLoan || potentialLoan.length === 0) return;
        let scrollElement = viewElement[0].closest(".vuecal__bg");

        scrollElement.scrollTo({
          top: potentialLoan[0].offsetTop - 16,
          behavior: "smooth",
        });
      }, 200);
    },
    eventEnter(e) {
      const loanId = e.data.loan_id;
      for (const event of this.$el.getElementsByClassName(`vuecal__event loan_id_${loanId}`)) {
        let parentWidth = event.style.width;
        let parentLeft = event.style.left;
        let hoverTarget = event.getElementsByClassName("hover-target")[0];
        hoverTarget.setAttribute("style", `width: ${parentWidth};left:${parentLeft};`);
        event.classList.add("hovered");
        setTimeout(() => event.classList.add("no-pointer"), 100);
      }
    },
    eventLeave() {
      for (const event of this.$el.getElementsByClassName("vuecal__event")) {
        event.classList.remove(["hovered"]);
        setTimeout(() => {
          event.classList.remove("no-pointer");
        }, 100);
      }
    },
    formatEventTimeEnd(event) {
      const endDate = dayjs(event.end);

      if (endDate.endOf("day").isSame(endDate, "second")) {
        return "24:00";
      }
      return endDate.format("HH:mm");
    },
    formatEventTimeStart(event) {
      const startDate = dayjs(event.start);
      return startDate.format("HH:mm");
    },
    formatEventTimeSpan(event) {
      const startDate = dayjs(event.start);
      const endDate = dayjs(event.end);

      if (!startDate.isSame(endDate, "day")) {
        return " +" + endDate.diff(startDate.startOf("day"), "day") + "j";
      }

      return "";
    },
    onEventClick(event, e) {
      // Stop propagation to avoid changing calendar view.
      e.stopPropagation();
      e.preventDefault();
      if (this.eventClickable(event)) {
        this.$router.push(event.uri);
      }
    },
    getMonthDayAvailability(events, cell) {
      if (this.loading) {
        return null;
      }
      const cellStartTime = this.$dayjs(cell.startDate).startOfDay();

      if (cellStartTime.isBefore(this.$dayjs(), "day")) {
        return null;
      }
      const cellEndTime = cellStartTime.add(1, "day").subtract(1, "second");

      if (events.filter((e) => e.type === "availability").length === 0) {
        return this.availabilityMode;
      }

      let eventAvailable = this.availabilityMode === "unavailable";

      if (
        events.find(
          (e) =>
            e.type === "availability" &&
            e.data.available === eventAvailable &&
            cellStartTime.isSameOrAfter(e.start, "seconds") &&
            cellEndTime.isSameOrBefore(e.end, "seconds")
        )
      ) {
        return eventAvailable ? "available" : "unavailable";
      }

      return "partially-available";
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";
.loanable-calendar.vuecal {
  background-color: $white;
  font-variant-numeric: tabular-nums;
  margin-top: 1rem;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: $small-shadow;
  font-size: 0.8rem;
  font-weight: 600;

  // Avoid too tall calendars, they're unweildy
  max-height: calc(100vh - 15rem);
  @include media-breakpoint-down(md) {
    max-height: calc(100vh - 5rem);
  }

  .loanable-calendar__loading-spinner.spinner-border {
    position: absolute;
    right: -1.5rem;
    border-width: 2px;
    border-color: $grey;
    border-top-color: transparent;
  }

  // Hide now, since it doesn't take into account loanable timezone
  .vuecal__now-line {
    display: none;
  }

  .vuecal__title-bar {
    background-color: transparent;
  }

  .vuecal__cell--selected {
    background-color: transparent;
  }
  .vuecal__cell--current,
  .vuecal__cell--today {
    background-color: rgba(240, 240, 255, 0.4);
  }
  .vuecal__event--focus,
  .vuecal__event:focus {
    box-shadow: none;
  }

  .vuecal__no-event {
    display: none;
  }

  .vuecal__cell--out-of-scope {
    // Disabled buttons have opacity: 0.65, but it does not feel clear enough here, hence 0.4.
    opacity: 0.4;
    .vuecal__event-time {
      // Do not display time on out of scope events since the multi-day events don't get the
      // start, middle and end classes we expect.
      display: none;
    }
  }

  // Week and day views
  // Styling the time axis.
  .loanable-calendar__time-step--minutes {
    font-size: 0.7em;
  }

  .loanable-calendar__event.vuecal__event--background {
    opacity: 0.8;
    // Leave background events in the background.
    z-index: 0;
    &:hover,
    &:focus,
    &.vuecal__event--focus {
      z-index: 0;
    }
  }
  .loanable-calendar__event:not(.vuecal__event--background) {
    z-index: 3;
  }

  .vuecal__cell--selected {
    z-index: 4 !important;
  }

  .loanable-calendar__event--availability {
    background-color: $background-alert-positive;
  }
  .loanable-calendar__event--unavailability {
    background-color: $background-alert-negative;
  }
  .loan-status-potential {
    color: $primary;
    background-color: rgba($primary, 0.2);
    border: 1px solid $primary;
  }

  .vuecal__event {
    border-radius: 0.25rem;
    padding: 0.125rem;
    transition:
      all 0.25s cubic-bezier(0.18, 0.87, 0.67, 1.2),
      color 0.1s,
      background-color 0.1s;
    &.vuecal__event--resizing {
      transition: none;
    }
    &.no-pointer,
    &.vuecal__event--background {
      pointer-events: none;
    }
    .hover-target {
      position: absolute;
      pointer-events: auto;
      width: 100%;
      height: 100%;
    }
    &.vuecal__event--background .hover-target {
      pointer-events: none;
    }
    &.loanable-calendar__event--clickable {
      &.hovered,
      &:hover {
        box-shadow: $medium-shadow;
        position: relative;

        .hover-target {
          z-index: 4;
        }
      }
    }
  }

  .vuecal__event-title {
    font-weight: 600;
    font-size: 0.85rem;
  }
  .vuecal__event-time {
    font-size: 0.75rem;

    .circular-progress,
    .loan-status-icon {
      min-width: 0.7rem;
      font-size: 0.7rem;
      --stroke-width: 2px;
    }
  }

  // Event time separators
  .vuecal__event:not(.vuecal__event--multiple-days) .vuecal__event-time__end::before {
    content: "-";
  }
  .vuecal__event--multiple-days.event-start {
    .vuecal__event-time__end {
      display: none;
    }
    .vuecal__event-time__span::before {
      content: "..";
    }
  }

  .vuecal__event--multiple-days.event-middle {
    .vuecal__event-time {
      display: none;
    }
  }
  .vuecal__event--multiple-days.event-end {
    .vuecal__event-time__start,
    .vuecal__event-time__span {
      display: none;
    }

    .vuecal__event-time__end::before {
      content: "..";
    }
  }

  // Month view.
  .vuecal__cells {
    &.month-view {
      .vuecal__flex.vuecal__weekdays-headings {
        gap: 0.25rem;
        padding: 0 0.5rem;
      }

      .vuecal__cells {
        padding: 0.5rem;

        > .vuecal__flex {
          gap: 0.25rem;
        }
      }

      .vuecal__flex.vuecal__cell-content {
        gap: 0.25rem;
        justify-content: start;
        padding: 0.5rem 0 0.25rem;
      }

      .vuecal__event {
        margin: 0 0.125rem;
        width: unset;
      }

      .vuecal__event + .vuecal__event {
        margin-top: 0.25rem;
      }

      .vuecal__cell {
        transition-duration: 0s;
        &:hover {
          background: transparentize($primary, 0.92);
        }
        border-radius: 0.5rem;
        min-height: 3rem;
        width: unset;
        flex-basis: 13%;
        min-width: 13%;
        flex-grow: 1;

        &::before {
          border: none;
        }

        // Do not display availability event on month view, since they're summarized
        .loanable-calendar__event--availability,
        .loanable-calendar__event--unavailability {
          display: none;
        }
      }

      .vuecal__cell--today {
        background-color: transparent;

        .loanable-calendar__cell-date-background svg {
          stroke: currentColor;
        }

        .loanable-calendar__cell-date--unavailable svg rect {
          fill: $beige;
        }
      }
    }

    &.week-view {
      .vuecal__event.hovered {
        z-index: 4 !important;
        width: 100% !important;
        left: 0 !important;
        min-height: fit-content;
        opacity: 0.9;
      }

      .vuecal__event:not(.hovered) {
        min-height: 0;

        .vuecal__event-title {
          white-space: nowrap;
        }
      }
    }

    &.day-view {
      .hover-target {
        width: 100% !important;
        left: 0 !important;
      }

      .vuecal__event.hovered {
        z-index: 4 !important;
        min-width: 5rem;
        min-height: 5rem;
      }

      .vuecal__event:not(.hovered) {
        min-height: 0;
      }
    }
  }
}
</style>
