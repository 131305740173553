<template>
  <dl>
    <template v-if="!detailsOnly">
      <dt>Nom du véhicule</dt>
      <dd>
        <router-link v-if="isAdminOfLoanable" :to="`/admin/loanables/${loanable.id}`">
          {{ loanable.name }}
        </router-link>
        <template v-else>
          {{ loanable.name }}
        </template>
      </dd>

      <dt>Propriétaire</dt>
      <dd>
        <router-link v-if="isAdminOfLoanable" :to="`/admin/users/${loanableOwnerUser.id}`">
          {{ loanableOwnerUser.full_name }}
        </router-link>
        <template v-else>
          {{ loanableOwnerUser.full_name }}
        </template>
      </dd>

      <template v-if="loanable.comments">
        <dt>Commentaires</dt>
        <dd>&laquo;&nbsp;{{ loanable.comments }}&nbsp;&raquo;</dd>
      </template>
    </template>
    <template v-if="loanable.type === 'bike'">
      <dt>{{ $t("loanables.fields.model") | capitalize }}</dt>
      <dd>{{ loanable.details.model }}</dd>
      <dt>{{ $t("loanables.fields.bike_type") | capitalize }}</dt>
      <dd>{{ $t(`loanables.bike_types.${loanable.details.bike_type}`) | capitalize }}</dd>
      <dt>{{ $t("loanables.fields.size") | capitalize }}</dt>
      <dd>
        {{ $t(`loanables.sizes.${loanable.details.size}`) | capitalize }}
      </dd>
    </template>
    <template v-else-if="loanable.type === 'trailer'">
      <dt>{{ $t("loanables.fields.maximum_charge") | capitalize }}</dt>
      <dd>{{ loanable.details.maximum_charge }}</dd>
      <dt>{{ $t("loanables.fields.dimensions") | capitalize }}</dt>
      <dd>{{ loanable.details.dimensions }}</dd>
    </template>
    <template v-else-if="loanable.type === 'car'">
      <dt>{{ $t("loanables.fields.brand") | capitalize }}</dt>
      <dd>{{ loanable.details.brand }}</dd>
      <dt>{{ $t("loanables.fields.model") | capitalize }}</dt>
      <dd>{{ loanable.details.model }}</dd>
      <dt>{{ $t("loanables.fields.year_of_circulation") | capitalize }}</dt>
      <dd>{{ loanable.details.year_of_circulation }}</dd>
      <dt>{{ $t("loanables.fields.transmission_mode") | capitalize }}</dt>
      <dd>
        {{ $t(`loanables.transmission_modes.${loanable.details.transmission_mode}`) | capitalize }}
      </dd>
      <dt>{{ $t("loanables.fields.engine") | capitalize }}</dt>
      <dd>{{ $t(`loanables.engines.${loanable.details.engine}`) | capitalize }}</dd>
      <dt>{{ $t("loanables.fields.papers_location") | capitalize }}</dt>
      <dd>
        {{ $t(`loanables.papers_locations.${loanable.details.papers_location}`) | capitalize }}
      </dd>
      <template v-if="loanable.details.report">
        <dt>{{ $t("loanables.fields.report") | capitalize }}</dt>
        <dd><safe-file :file="loanable.details.report">Fiche</safe-file></dd>
      </template>
    </template>
  </dl>
</template>

<script>
import SafeFile from "@/components/shared/SafeFile.vue";
import { getOwner, isAdminOfLoanable } from "@/helpers/permissions/loanables";

export default {
  name: "DescriptionList",
  components: { SafeFile },
  props: {
    loanable: {
      type: Object,
      required: false,
      default: undefined,
    },
    detailsOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isAdminOfLoanable() {
      return isAdminOfLoanable(this.$store.state.user, this.loanable);
    },
    loanableOwnerUser() {
      return getOwner(this.loanable);
    },
  },
};
</script>

<style lang="scss"></style>
