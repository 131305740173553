var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.loaded)?_c('layout-loading'):_c('validation-observer',{ref:"validation",scopedSlots:_vm._u([{key:"default",fn:function({ passes, pristine }){return [_c('b-form',{staticClass:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return passes(_vm.save)}}},[(_vm.isNew)?_c('h1',{staticClass:"mb-4"},[_vm._v("Nouveau flux")]):_c('h1',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.dataset.name))]),(_vm.isNew)?_c('forms-validated-input',{attrs:{"label":"Nom du flux","type":"text","rules":{
        required: true,
        alpha_dash: true,
        excluded: 'new',
      },"name":"name"},model:{value:(_vm.dataset.name),callback:function ($$v) {_vm.$set(_vm.dataset, "name", $$v)},expression:"dataset.name"}}):_vm._e(),_c('forms-validated-input',{attrs:{"label":"Notes","type":"textarea","name":"notes"},model:{value:(_vm.dataset.notes),callback:function ($$v) {_vm.$set(_vm.dataset, "notes", $$v)},expression:"dataset.notes"}}),(!_vm.isNew)?_c('paginated-table',{ref:"table",attrs:{"id":"admin-gbfs-dataset-communities-table","endpoint":"gbfs_dataset_communities","columns":_vm.columns,"label":"Communautés","load-relations":['community'],"fetch-params":_vm.communityFetchParams,"show-generate-csv":false,"show-action-column":"","key-column":"id"},scopedSlots:_vm._u([{key:"head-buttons",fn:function(){return [_c('relation-input',{staticClass:"add-community-input",attrs:{"placeholder":"Ajouter une communauté","value":null,"reset-after-select":"","query":{
            slug: 'communities',
            value: 'id',
            text: 'name',
            params: {
              '!gbfs_datasets.name': _vm.name,
            },
          },"name":"add-dataset"},on:{"input":_vm.addCommunity}})]},proxy:true},{key:"cell(actions)",fn:function({ item }){return [_c('admin-list-actions',{attrs:{"slug":"communities","item-name":item.community.name,"item":item,"destroy-action":_vm.removeCommunity,"show-restore":false,"archive-label":"retirer"},on:{"change":_vm.$refs.table.refresh}})]}}],null,true)}):_vm._e(),(!_vm.isNew)?[_c('gbfs-stream',{attrs:{"root":_vm.dataset.link,"stream":"system_information"}}),_c('gbfs-stream',{attrs:{"root":_vm.dataset.link,"stream":"vehicle_types"}}),_c('gbfs-stream',{attrs:{"root":_vm.dataset.link,"stream":"vehicle_status"}})]:_vm._e(),_c('form-buttons',{attrs:{"changed":!pristine,"saving":_vm.saving,"loading":_vm.loading},on:{"reset":_vm.reset}})],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }