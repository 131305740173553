<script>
import UserBlurb from "@/components/User/UserBlurb.vue";
import { capitalize } from "@/helpers/filters";

export default {
  name: "UserRoleList",
  components: { UserBlurb },
  props: {
    showContactsOnly: {
      type: Boolean,
      default: true,
    },
    userRoles: {
      type: Array,
      required: true,
    },
    showAdminLinks: {
      type: Boolean,
      default: false,
    },
    rolesToShow: {
      type: Array,
      default: () => ["owner", "manager", "coowner"],
    },
  },
  computed: {
    roles() {
      let rolesByUser = {};

      for (const userRole of this.userRoles) {
        if (this.showContactsOnly && !userRole.show_as_contact) {
          continue;
        }
        if (this.rolesToShow.includes(userRole.role)) {
          const currentUserRole = rolesByUser[userRole.user.id];
          if (!currentUserRole || this.isRoleGreater(userRole.role, currentUserRole.role)) {
            rolesByUser[userRole.user.id] = userRole;
            if (currentUserRole) {
              // Preserve title if some is declared for less important (but more specific) role.
              // E.g. Coowner with some title who is also a fleet manager without a title should have
              // the role manager with the coowner's title.
              rolesByUser[userRole.user.id].title = userRole.title ?? currentUserRole.title;
            }
          }
        }
      }
      return Object.values(rolesByUser);
    },
  },
  methods: {
    capitalize,

    isRoleGreater(roleA, roleB) {
      if (roleA === "owner") {
        return roleB !== "owner";
      }
      if (roleB === "manager") {
        return roleB !== "manager";
      }
      if (roleA === "coowner") {
        return roleB !== "coowner";
      }
      return false;
    },
  },
};
</script>

<template>
  <div>
    <slot v-if="$slots.title && roles.length > 0" name="title"></slot>
    <user-blurb
      v-for="role of roles"
      :key="role.id"
      class="mt-2"
      :show-admin-link="showAdminLinks"
      :user="role.user"
      :description="role.title ? role.title : capitalize($t(`loanables.roles.${role.role}`))"
      show-phone
    >
      <template #nameicon>
        <b-icon
          v-if="role.show_as_contact && !showContactsOnly"
          v-b-tooltip.hover
          title="Visible aux emprunteurs"
          variant="primary"
          icon="eye-fill"
        />
      </template>
    </user-blurb>
  </div>
</template>

<style scoped lang="scss"></style>
