<script>
import LoanStatusIcon from "@/components/Loan/Status/LoanStatusIcon.vue";
import AvailabilityStatus from "@/components/Loanable/AvailabilityStatus.vue";
import { get } from "@/requests/server";

export default {
  name: "LibraryLoanableOverview",
  components: { LoanStatusIcon, AvailabilityStatus },
  props: {
    libraryId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      page: 1,
      perPage: 6,
      loading: true,
      loanables: [],
      totalCount: null,
      loaded: false,
    };
  },
  mounted() {
    this.loadLoanables();
  },
  methods: {
    async loadLoanables() {
      this.loading = true;
      const { data } = await get(
        `/libraries/${this.libraryId}/loanables?with_loan_summary=1&page=${this.page}&per_page=${this.perPage}&order=id`,
        {
          notifications: { action: "chargement des véhicules de la flotte" },
          cleanupCallback: () => (this.loading = false),
        }
      );
      this.loanables = data.data;
      this.totalCount = data.meta.total;
      this.loaded = true;
    },
  },
};
</script>

<template>
  <div class="position-relative">
    <transition name="fade">
      <div v-if="!loaded" key="overview-skeleton" class="loanable-overview-skeleton">
        <div class="loanable-grid">
          <div v-for="i in perPage" :key="i" class="loanable-card-skeleton">
            <b-skeleton type="card"> </b-skeleton>
            <b-skeleton class="loanable-name-skeleton" :animation="null" />
            <b-skeleton width="50%" :animation="null" />
          </div>
        </div>
      </div>
      <div
        v-else-if="totalCount > 0"
        key="overview"
        class="loanable-overview"
        :class="{ loading: loading }"
      >
        <div class="loanable-grid">
          <div v-for="loanable in loanables" :key="loanable.id">
            <router-link :to="`/loanables/${loanable.id}`" class="text-decoration-none">
              <b-card class="loanable-card">
                <div class="loanable-name">{{ loanable.name }}</div>
                <availability-status :loanable="loanable" />
                <div class="mt-2">
                  <router-link
                    v-for="loan in loanable.loans"
                    :key="loan.id"
                    class="text-decoration-none"
                    :to="`/loans/${loan.id}`"
                  >
                    <div
                      class="loanable-overview-loan loanable-loan-event"
                      :class="`loan-status-${loan.status}`"
                    >
                      <div class="font-weight-bold">
                        {{ loan.borrower_user.full_name }}
                      </div>
                      <div>
                        {{ loan.departure_at | datetime }}
                        <loan-status-icon
                          :status="loan.status"
                          :action-required="loan.owner_action_required"
                        />
                      </div>
                    </div>
                  </router-link>
                </div>
              </b-card>
            </router-link>
          </div>
        </div>
      </div>
      <div v-else class="text-center font-italic m-3">Aucun véhicule, pour l'instant!</div>
    </transition>

    <b-pagination
      v-if="totalCount"
      :value="page"
      :per-page="perPage"
      :total-rows="totalCount"
      align="right"
      @input="
        page = $event;
        loadLoanables();
      "
    />
  </div>
</template>

<style scoped lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-leave-active {
  position: absolute;
  width: 100%;
}
.loanable-card {
  .card-body {
    padding: 1rem;
    font-size: 0.875rem;
    .loanable-name {
      color: $dark;
      font-weight: 600;
    }
    .status-list {
      margin-top: 0.25rem;
      margin-left: -0.1rem;
    }
  }
}
.loanable-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  margin-bottom: 1rem;
  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-down(xs) {
    grid-template-columns: 1fr;
  }

  .loanable-card-skeleton {
    height: 6rem;
    opacity: 0.25;
    position: relative;
    .b-skeleton-card {
      position: absolute;
      height: 6rem;
      border-radius: 1rem;
      width: 100%;
    }
    .b-skeleton-text {
      margin-left: 1rem;
      width: calc(100% - 2rem);
    }
    .loanable-name-skeleton {
      margin-top: 1rem;
    }
  }
}
.loanable-overview {
  transition-duration: 0.3s;
  &.loading {
    opacity: 0.5;
  }
}
.loanable-card {
  transition-duration: 0.15s;
  box-shadow: $small-shadow;
  &:hover {
    box-shadow: $medium-shadow;
  }
}
.loanable-overview-loan {
  border-radius: 0.25rem;
  margin-top: 0.25rem;
  border: 1px;
  padding: 0.25rem;
  transition-duration: 0.3s;
  &:hover {
    text-decoration: none;
  }
}
</style>
