<template>
  <div class="description" :class="{ 'small-description': small }">
    <template v-if="loanable.deleted_at">
      <div class="p-2 text-muted">Véhicule archivé</div>
    </template>
    <template v-else-if="loanable.type === 'bike'">
      <div class="description-list">
        <div class="description-item">
          <div class="description-item-title">
            <loanable-icon :loanable="loanable" />
          </div>
          <div class="description-item-value">
            {{ $t(`loanables.bike_types.${loanable.details.bike_type}`) | capitalize }}
          </div>
        </div>

        <div
          v-if="loanable.details.model && loanable.details.model !== ''"
          class="description-item"
        >
          <div v-b-tooltip="'Modèle'" class="description-item-title">
            <b-icon icon="tags" />
          </div>
          <div class="description-item-value">
            {{ loanable.details.model }}
          </div>
        </div>

        <div v-if="loanable.details.size && loanable.details.size !== ''" class="description-item">
          <div v-b-tooltip="'Taille'" class="description-item-title">
            <b-icon icon="arrows-angle-expand" />
          </div>
          <div class="description-item-value">
            {{ $t(`loanables.sizes.${loanable.details.size}`) | capitalize }}
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="loanable.type === 'trailer'">
      <div class="description-list">
        <div v-if="small" class="description-item">
          <div class="description-item-title">
            <loanable-icon :loanable="loanable" />
          </div>
          <div class="description-item-value">
            {{ $t("loanables.fields.types.trailer") | capitalize }}
          </div>
        </div>
        <div
          v-if="loanable.details.maximum_charge && loanable.details.maximum_charge !== ''"
          class="description-item"
        >
          <div v-b-tooltip="'Charge maximale'" class="description-item-title">
            <weight-hanging />
          </div>
          <div class="description-item-value">
            {{ loanable.details.maximum_charge }}
          </div>
        </div>
        <div
          v-if="loanable.details.dimensions && loanable.details.dimensions !== ''"
          class="description-item"
        >
          <div v-b-tooltip="'Dimensions'" class="description-item-title">
            <b-icon icon="arrows-angle-expand" />
          </div>
          <div class="description-item-value">
            {{ loanable.details.dimensions }}
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="loanable.type === 'car'">
      <div class="description-list">
        <div class="description-item">
          <div class="description-item-title">
            <loanable-icon :loanable="loanable" />
          </div>
          <div class="description-item-value">
            <span>{{ loanable.details.brand }} {{ loanable.details.model }}</span>
            <span v-if="!small"> {{ loanable.details.year_of_circulation }}</span>
          </div>
        </div>

        <div class="description-item">
          <div class="description-item-title">
            <transmission />
          </div>
          <div class="description-item-value">
            {{
              $t(`loanables.transmission_modes.${loanable.details.transmission_mode}`) | capitalize
            }}
          </div>
        </div>

        <div class="description-item">
          <div class="description-item-title"><fuel /></div>
          <div class="description-item-value">
            {{ $t(`loanables.engines.${loanable.details.engine}`) | capitalize }}
          </div>
        </div>

        <div v-if="!small" class="description-item">
          <div class="description-item-title">
            <documents />
          </div>
          <div class="description-item-value">
            Papiers {{ $t(`loanables.papers_locations.${loanable.details.papers_location}`) }}
          </div>
        </div>
        <template v-if="loanable.details.report && !small">
          <div class="description-item">
            <div class="description-item-title"><magnifier-bug /></div>
            <div class="description-item-value">
              <safe-file :file="loanable.details.report"
                ><b-icon class="mr-1" icon="download" />{{
                  $t("loanables.fields.report") | capitalize
                }}
              </safe-file>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import Documents from "@/assets/svg/documents.svg";
import Fuel from "@/assets/svg/fuel.svg";
import MagnifierBug from "@/assets/svg/magnifier-bug.svg";
import Transmission from "@/assets/svg/transmission.svg";
import WeightHanging from "@/assets/svg/weight-hanging.svg";
import LoanableIcon from "@/components/Loanable/LoanableIcon.vue";
import SafeFile from "@/components/shared/SafeFile.vue";
import { isAdminOfLoanable } from "@/helpers/permissions/loanables";

export default {
  name: "PrettyDescription",
  components: {
    LoanableIcon,
    SafeFile,
    fuel: Fuel,
    magnifierBug: MagnifierBug,
    transmission: Transmission,
    documents: Documents,
    weightHanging: WeightHanging,
  },
  props: {
    loanable: {
      type: Object,
      required: false,
      default: undefined,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isAdminOfLoanable() {
      return isAdminOfLoanable(this.$store.state.user, this.loanable);
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";
.description {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .description-list {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
  }

  .description-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    flex-basis: 10rem;
    flex-grow: 0;
    padding: 0 1rem;
    min-width: 8rem;
    text-align: center;
    + .description-item {
      border-left: 1px solid $light-grey;
    }
  }

  .description-item-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
      height: 1.5rem;
      width: 100%;
      fill: $secondary;
      path {
        fill: $secondary;
      }
    }
  }

  .description-item-value {
    font-weight: 400;
    overflow: hidden;
  }
  &.small-description {
    padding: 0.5rem 0;
    .description-list {
      width: 100%;
    }
    .description-item {
      min-width: 4rem;
      flex-basis: 5rem;
      flex-grow: 0;
      font-size: 0.75rem;
      gap: 0.125rem;
      border-radius: 0.5rem;
      background: transparentize($secondary, 0.9);
      padding: 0.25rem 0.125rem;
      margin-left: 0.25rem;
      justify-content: center;
      &:first-child {
        margin-left: 0.5rem;
      }
      &:last-child {
        margin-right: 0.5rem;
      }
      .description-item-title svg {
        height: 1.25rem;
        path {
          fill: $secondary;
        }
      }

      + .description-item {
        border: none;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .description {
    display: flex;
    flex-direction: column;
  }
}
</style>
