<script>
import AdminListActions from "@/components/Admin/ListActions.vue";
import MailListForm from "@/components/Admin/MailListForm.vue";
import IconButton from "@/components/shared/IconButton.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column } from "@/components/shared/PaginatedTableColumns";
import { capitalize } from "@/helpers/filters";
import { del } from "@/requests/server";

export default {
  name: "AdminMailingList",
  components: { AdminListActions, MailListForm, IconButton, PaginatedTable },
  data() {
    return {
      columns: [
        new Column("id", "ID", "id"),
        new Column("provider", "Fournisseur", "text", {
          formatter: capitalize,
        }),
        new Column("list_name", "Liste", "text"),
        Column.withRelation(
          "created_by_user.full_name",
          "Créé par",
          {
            relation: "users",
            label: "full_name",
            field: "id",
            params: { for: "admin" },
          },
          "created_by_user_id",
          {
            urlFct: (mailingList) => `/admin/users/${mailingList.created_by_user.id}`,
          }
        ),
        Column.withRelation(
          "community.name",
          "Communauté",
          {
            relation: "communities",
            label: "name",
            field: "id",
            params: { for: "admin" },
          },
          "community_id",
          {
            urlFct: (mailingList) => `/admin/communities/${mailingList.community.id}`,
          }
        ),
        Column.withoutFilter("api_key", "Clé d'API", "text", { sortable: false }),
        Column.withSelect("status", "Statut", [
          { value: null, label: "tous" },
          {
            value: "active",
            label: "Active",
            variant: "success",
          },
          {
            value: "synchronizing",
            label: "Synchronisation",
            variant: "warning",
          },
          {
            value: "suspended",
            label: "suspendue",
            variant: "danger",
          },
        ]),
        Column.withoutFilter("last_error", "derniére erreur", "text"),
        new Column("community_editable", "Gérée par la communauté", "boolean", {
          showByDefault: false,
        }),
      ],
    };
  },
  computed: {},
  methods: {
    async deleteIntegration(item) {
      await del(`/mailinglists/${item.id}`, {
        notifications: {
          action: "suppression de l'intégration",
          onSuccess: "Intégration supprimée",
        },
      });
      this.$refs.table.refresh();
    },
    copyErrorMessage(errorMessage) {
      navigator.clipboard.writeText(errorMessage).then(() => {
        this.$store.commit("addNotification", {
          title: "Texte de l'erreur copiée",
          variant: "success",
        });
      });
    },
  },
};
</script>

<template>
  <div>
    <h2>Listes de diffusion</h2>
    <paginated-table
      ref="table"
      endpoint="mailinglists"
      :columns="columns"
      :show-generate-csv="false"
    >
      <template #head-buttons>
        <icon-button v-b-modal="'add-mailing-list-modal'" role="add"> Ajouter </icon-button>
      </template>
      <template #cell(actions)="{ item }">
        <admin-list-actions
          slug="mailinglists"
          archive-label="supprimer"
          :destroy-action="() => deleteIntegration(item)"
          item-name="cette intégration"
          :item="item"
          :show-restore="false"
        />
      </template>
      <template #cell(last_error)="{ item }">
        <b-modal
          v-if="item.last_error"
          :id="`mailing-list-error-${item.id}`"
          hide-footer
          hide-header
          size="lg"
          centered
        >
          <h4>Erreur de synchronisation</h4>
          <pre class="text-monospace">{{ item.last_error }}</pre>

          <icon-button icon="files" class="mt-2" @click="() => copyErrorMessage(item.last_error)"
            >Copier</icon-button
          >
        </b-modal>
        <icon-button
          v-if="item.last_error"
          v-b-modal="`mailing-list-error-${item.id}`"
          variant="ghost-secondary"
          size="sm"
        >
          Afficher
        </icon-button>
      </template>
    </paginated-table>

    <b-modal id="add-mailing-list-modal" hide-header hide-footer>
      <mail-list-form
        community-editable
        @added="
          () => {
            $refs.table.refresh();
            $bvModal.hide('add-mailing-list-modal');
          }
        "
      />
    </b-modal>
  </div>
</template>

<style scoped lang="scss">
.card {
  box-shadow: $small-shadow;
  + .card {
    margin-top: 1rem;
  }
}
</style>
