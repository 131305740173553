<script>
import Bike from "@/assets/svg/bike.svg";
import Car from "@/assets/svg/car.svg";
import Trailer from "@/assets/svg/trailer.svg";
import { isAdminOfCommunity } from "@/helpers/permissions/communities";
import { isGlobalAdmin } from "@/helpers/permissions/users";
import Vue from "vue";

export default Vue.extend({
  name: "CommunityOverviewCard",
  components: {
    "svg-bike": Bike,
    "svg-car": Car,
    "svg-trailer": Trailer,
  },
  props: {
    community: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    muted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isLoggedIn() {
      return !!this.$store.state.user;
    },
    isGlobalAdmin() {
      return this.isLoggedIn && isGlobalAdmin(this.$store.state.user);
    },
    showCommunityLink(community) {
      return (
        this.isLoggedIn &&
        (this.isGlobalAdmin || isAdminOfCommunity(this.$store.state.user, community))
      );
    },
  },
});
</script>

<template>
  <b-card
    :key="community.id"
    class="community-overview__community"
    :class="{
      'community-overview__community_unselected': muted,
      'community-overview__community_selected': selected,
    }"
    @click="$emit('click')"
  >
    <template #header>
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="community-overview__community__title">
          <router-link v-if="showCommunityLink" :to="`/admin/communities/${community.id}`">
            {{ community.name }}
          </router-link>
          <span v-else>{{ community.name }}</span>
        </h2>
        <div class="text-nowrap">
          <b-icon icon="person" />
          {{ community.approved_users_count }}
        </div>
      </div>
    </template>

    <div class="community-overview__community__attributes">
      <div
        v-if="community.shared_loanable_types.includes('trailer')"
        class="community-overview__community__attribute"
      >
        <div class="loanable_type_icons">
          <svg-trailer />
        </div>
        {{ community.loanables_count_by_type["trailer"] || 0 }}
      </div>
      <div
        v-if="community.shared_loanable_types.includes('bike')"
        class="community-overview__community__attribute"
      >
        <div class="loanable_type_icons">
          <svg-bike />
        </div>
        {{ community.loanables_count_by_type["bike"] || 0 }}
      </div>
      <div
        v-if="community.shared_loanable_types.includes('car')"
        class="community-overview__community__attribute"
      >
        <div class="loanable_type_icons">
          <svg-car />
        </div>
        {{ community.loanables_count_by_type["car"] || 0 }}
      </div>
    </div>
    <div
      v-if="community.contact_email || community.chat_group_url || community.starting_guide_url"
      class="community-overview__community__links"
    >
      <div v-if="community.contact_email" class="community-overview__community__link">
        <b-icon class="mr-1" icon="envelope-open" shift-v="-1" font-scale="1.25" />
        <a target="_blank" :href="`mailto:${community.contact_email}`">
          {{ community.contact_email }}
        </a>
      </div>
      <div v-if="community.chat_group_url" class="community-overview__community__link">
        <b-icon class="mr-1" icon="chat-dots" shift-v="-1" font-scale="1.25" />
        <a target="_blank" :href="community.chat_group_url"> Groupe de discussion </a>
      </div>
      <div v-if="community.starting_guide_url" class="community-overview__community__link">
        <b-icon class="mr-1" icon="clipboard-check" shift-v="-1" font-scale="1.25" />
        <a target="_blank" :href="community.starting_guide_url"> Guide de départ </a>
      </div>
    </div>
    <!-- whitespace: pre-wrap adds a space if end of opening tag doesn't touch the text, but prettier
       insists on putting it on two lines -->
    <!-- prettier-ignore -->
    <div v-if="community.description" class="community-overview__community__description_wrapper">
      <p class="community-overview__community__description" >
        {{ community.description }}
      </p>
    </div>
    <template #footer>
      <div class="community-overview__community__badges">
        <b-badge v-if="community.type === 'borough'" variant="success">Quartier</b-badge>
        <b-badge v-if="community.type === 'private'" variant="secondary">Privée</b-badge>
        <b-badge v-if="community.requires_residency_proof" variant="warning"
          >Preuve de résidence requise</b-badge
        >
        <b-badge v-if="community.requires_identity_proof" variant="warning"
          >Preuve d'identité requise</b-badge
        >
        <b-badge v-if="community.requires_custom_proof" variant="warning"
          >Preuve spécifique requise</b-badge
        >
      </div>
    </template>
  </b-card>
</template>

<style lang="scss">
.community-overview__community {
  cursor: pointer;
  &__title {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1;
    margin: 0;
    color: $locomotion-light-green;
  }
  &__stats,
  &__links {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &.card {
    box-shadow: $small-shadow;
    border-radius: 0.5rem;
    .card-body {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    margin: 0.5rem 0;
  }

  .card-header,
  .card-footer {
    background: $main-bg;
  }

  &_unselected.card {
    opacity: 0.5;
  }

  &_selected.card {
    opacity: 1;
    box-shadow:
      $small-shadow,
      0 0 2px 1px $locomotion-green;
  }

  &__badges .badge:not(:first-child) {
    margin-left: 0.5rem;
  }

  &__description_wrapper {
    max-height: 15vh;
    overflow: auto;
  }

  &__description {
    white-space: pre-wrap;
    margin-bottom: 0;
    line-height: normal;
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  &__link {
    color: $locomotion-light-green;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__attributes {
    display: grid;
    grid-template-columns: repeat(3, 4rem);
    gap: 1rem;
    a {
      text-decoration: underline;

      &:hover {
        box-shadow: 0 1px 4px transparentize($locomotion-green, 0.3);
      }
    }
  }

  &__attribute {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    padding-block: 0.5rem;
    border-radius: 0.5rem;
    background: $main-bg;
    color: $secondary;
    font-size: 0.8rem;
    font-weight: 600;
    box-shadow: $small-shadow;
    min-height: 3.5rem;
  }

  &__attribute .b-icon.bi {
    flex: 1;
    width: 32%;
  }

  .loanable_type_icons {
    position: relative;
    width: 40%;
    flex: 1;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .loanable_type_icons svg {
    position: absolute;
    top: 0.25rem;
    width: 100%;
    opacity: 1;
    fill: $secondary;
  }
}
</style>
