<script>
import { debounce } from "@/helpers/debounce";
import rehypeExternalLinks from "rehype-external-links";
import rehypeSanitize from "rehype-sanitize";
import rehypeStringify from "rehype-stringify";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import { unified } from "unified";
import { truncate } from "@/helpers/truncate";
export default {
  name: "MarkdownContent",
  props: {
    content: {
      type: String,
      default: null,
    },
    maxHeight: {
      type: Number,
      default: null,
    },
    moreLink: {
      type: String,
      default: null,
    },
  },
  data: function () {
    return {
      renderedMarkdown: null,
      link: null,
      resizeObserver: null,
    };
  },
  computed: {
    hasMoreLink() {
      return this.moreLink || this.$slots.moreLink;
    },
  },
  beforeMount() {
    this.render();
  },
  mounted() {
    if (this.hasMoreLink && this.$refs.link && !this.link) {
      this.link = this.$el.removeChild(this.$refs.link);
    }
    this.truncate();
    this.debouncedTruncate = debounce(this.truncate);
    this.resizeObserver = new ResizeObserver(this.debouncedTruncate);
    this.resizeObserver.observe(this.$refs.markdown);
  },
  beforeDestroy() {
    this.resizeObserver.unobserve(this.$refs.markdown);
  },
  updated() {
    this.$nextTick(() => this.truncate());
  },
  methods: {
    truncate() {
      truncate(this.$refs.markdown, this.maxHeight, this.renderedMarkdown, this.link);
    },
    async render() {
      if (!this.content) {
        return;
      }
      this.renderedMarkdown = String(
        await unified()
          .use(remarkParse)
          .use(remarkBreaks)
          .use(remarkGfm)
          .use(remarkRehype)
          .use(rehypeSanitize)
          .use(rehypeExternalLinks, {
            rel: ["nofollow", "noopener", "noreferrer"],
            target: "_blank",
            protocols: ["http", "https", "mailto"],
          })
          .use(rehypeStringify)
          .process(this.content)
      );
    },
  },
};
</script>

<template>
  <div :style="maxHeight ? `max-height: ${maxHeight}px; overflow: hidden;` : ''">
    <!--  eslint-disable-next-line -->
    <div class="markdown-content" v-html="renderedMarkdown" ref="markdown"></div>
    <span v-if="moreLink || $slots.moreLink" ref="link" class="more-link"
      >&mldr;<slot name="moreLink"><a :href="moreLink">(plus)</a></slot>
    </span>
  </div>
</template>

<style lang="scss">
.markdown-content {
  line-height: 1.5;

  .more-link {
    text-decoration: none;
    font-weight: 400;
    font-size: 1em;
    font-style: normal;
  }

  p {
    line-height: 1.5;
    margin-bottom: 0.5em;
  }

  table {
    width: 100%;
    margin-bottom: 1em;

    th,
    td {
      padding: 0.25em 0.5em;
    }

    thead {
      text-align: left;
    }

    th {
      text-align: revert-layer;
    }
  }

  img {
    max-width: 200px;
  }

  blockquote {
    margin-left: 0.5em;
    padding: 0.25em 0 0.25em 0.5em;
    border-left: 3px solid $light-grey;
  }

  *:last-child {
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0 !important;
    margin-bottom: 0.5em !important;
    line-height: 1.5 !important;
  }

  h1 {
    font-size: 1.5em !important;
    font-weight: 400 !important;
  }

  h2 {
    font-size: 1.25em !important;
    font-weight: 600 !important;
  }

  h3 {
    font-size: 1.25em !important;
    font-weight: 500 !important;
  }

  h4 {
    font-size: 1em !important;
    font-weight: bold !important;
  }

  h5,
  h6 {
    font-size: 1em !important;
  }
}
</style>
