<template>
  <conditional-context
    class="pricing-form-context"
    :show="isFormDisabled"
    label="réservé aux admins globaux"
    background="white"
    additional-info="Vous n'avez pas les permissions pour modifier les tarifications de ce type."
  >
    <validation-observer v-slot="{ passes }" class="pricing-form">
      <b-form @submit.stop.prevent="passes(save)">
        <b-row>
          <b-col md="6">
            <forms-validated-input
              v-model="pricing.name"
              type="text"
              :name="`name_${pricing.id}`"
              label="Nom"
              :disabled="isFormDisabled || hasEnded"
              :rules="{
                required: true,
              }"
              @input="emitChange"
            />
            <b-row>
              <b-col md="6">
                <forms-validated-input
                  v-model="pricing.start_date"
                  type="date"
                  :name="`start_date_${pricing.id}`"
                  label="Date de début"
                  :disabled-dates-fct="isBeforeNow"
                  :disabled="isFormDisabled || hasStarted"
                  :rules="{
                    required: true,
                  }"
                  @input="emitChange"
                />
              </b-col>
              <b-col md="6">
                <forms-validated-input
                  v-model="pricing.end_date"
                  type="date"
                  :name="`end_date_${pricing.id}`"
                  label="Date de fin"
                  :disabled-dates-fct="isBeforeNowOrStartDate"
                  :disabled="isFormDisabled || hasEnded"
                  :rules="{
                    required: false,
                  }"
                  @input="emitChange"
                />
              </b-col>
            </b-row>

            <forms-validated-input
              v-model="pricing.description"
              :disabled="isFormDisabled || hasEnded"
              type="textarea"
              :name="`description_${pricing.id}`"
              label="Description"
              :max-rows="2"
              :rows="1"
              placeholder="ex. Permet de financer le maintien de la flotte et l'achat d'une nouvelle remorque."
              :rules="{
                max: 150,
              }"
              @input="emitChange"
            />

            <forms-validated-input
              v-model="pricing.pricing_type"
              :disabled="isFormDisabled || hasStarted"
              type="select"
              :name="`pricing_type_${pricing.id}`"
              :options="pricingTypeOptions"
              label="Type"
              :rules="{
                required: true,
              }"
              @input="emitChange"
            />
            <b-alert
              v-if="exemptFromContributions && pricing.pricing_type === 'contribution'"
              variant="warning"
              show
            >
              Cette communauté est exempte des contributions. Cette tarification n'aura donc aucun
              effet.
            </b-alert>

            <conditional-context
              v-if="pricing.pricing_type === 'contribution'"
              show
              class="mt-4 mx-0"
              label="Options de contribution"
              background="white"
            >
              <forms-validated-input
                v-if="pricing.community_id"
                v-model="pricing.is_mandatory"
                :disabled="isFormDisabled || hasStarted"
                type="checkbox"
                :name="`is_mandatory_${pricing.id}`"
                label="Contribution obligatoire"
                @input="emitChange"
              />
              <forms-validated-input
                v-model="pricing.yearly_target_per_user"
                type="currency"
                :name="`yearly_target_per_user_${pricing.id}`"
                :disabled="isFormDisabled || hasStarted"
                label="Cible annuelle par membre ($)"
                @input="emitChange"
              />
            </conditional-context>
          </b-col>
          <b-col md="6">
            <conditional-context show class="mt-4 mx-0" label="Véhicules ciblés" background="white">
              <forms-validated-input
                v-if="
                  pricing.pricing_type === 'insurance' || pricing.pricing_type === 'contribution'
                "
                v-model="pricing.loanable_ownership_type"
                type="select"
                :name="`loanable_ownership_type_${pricing.id}`"
                :disabled="isFormDisabled || hasStarted"
                :options="vehicleOwnershipOptions"
                label="Véhicules partagés par"
                :rules="{
                  required: true,
                }"
                @input="emitChange"
              />

              <b-form-group
                label="Types de véhicules"
                label-for="pricing_loanable_types"
                class="pricing-loanable-types"
              >
                <div class="position-relative">
                  <b-checkbox
                    v-if="showBikes"
                    class="group-select-all"
                    :checked="allBikeChecked"
                    :indeterminate="someBikeChecked"
                    :disabled="isFormDisabled || hasStarted"
                    @change="allBikesClicked"
                  >
                    Tous les vélos
                  </b-checkbox>
                  <validation-provider
                    v-slot="{ validated, valid, errors }"
                    name="Types de véhicules"
                    :rules="{
                      required: true,
                    }"
                  >
                    <b-checkbox-group
                      v-if="showBikes"
                      v-model="pricing.pricing_loanable_types"
                      :options="bikeOptions"
                      class="checkbox-group"
                      :disabled="isFormDisabled || hasStarted"
                      @input="emitChange"
                    />

                    <b-checkbox-group
                      v-if="showTrailers"
                      v-model="pricing.pricing_loanable_types"
                      :disabled="isFormDisabled || hasStarted"
                      :options="trailerOptions"
                      class="checkbox-group"
                      @input="emitChange"
                    />

                    <div v-if="showCars" class="position-relative">
                      <b-checkbox
                        :disabled="isFormDisabled || hasStarted"
                        class="group-select-all"
                        :checked="allCarsChecked"
                        :indeterminate="someCarsChecked"
                        @change="allCarsClicked"
                      >
                        Toutes les autos
                      </b-checkbox>
                      <b-checkbox-group
                        v-model="pricing.pricing_loanable_types"
                        :disabled="isFormDisabled || hasStarted"
                        :options="carOptions"
                        class="checkbox-group"
                        @input="emitChange"
                      />
                    </div>

                    <b-form-invalid-feedback :state="validated ? valid : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </b-form-group>
            </conditional-context>
          </b-col>
        </b-row>
        <forms-validated-input
          v-model="pricing.rule"
          class="formula-input"
          type="textarea"
          :name="`rule_${pricing.id}`"
          :disabled="isFormDisabled || hasStarted"
          label="Formule par emprunt"
          :rules="{
            required: true,
          }"
          @input="emitChange"
        />

        <icon-button
          v-if="!hasEnded"
          :loading="saving"
          role="save"
          type="submit"
          :disabled="saving || deleting || !pricing._changed || isFormDisabled"
        >
          {{ pricing.id ? "Enregistrer" : "Créer" }}
        </icon-button>
        <validated-button
          v-if="pricing.id && !hasStarted"
          class="ml-2"
          :disabled="saving || deleting || isFormDisabled"
          :action="deletePricing"
          variant="outline-danger"
          label="Archiver"
          question="Êtes-vous sûr de vouloir archiver cette tarification?"
          icon="trash"
        />
        <icon-button
          v-if="pricing.id && !hasEnded"
          class="ml-2"
          :disabled="saving || deleting || isFormDisabled || !pricing._changed"
          role="reset"
          @click="reset"
        >
          Réinitialiser
        </icon-button>
        <icon-button v-if="!pricing.id" class="ml-2" role="remove-item" @click="deletePricing">
          Annuler
        </icon-button>
        <div class="mt-1">
          <span v-if="hasEnded" class="text-muted"
            >Cette tarification est terminée et ne peut donc plus être modifiée.</span
          >
          <span v-else-if="hasStarted" class="text-muted"
            >Cette tarification est en cours et ne peut plus être archivée.</span
          >
        </div>
      </b-form>
    </validation-observer>
  </conditional-context>
</template>

<script>
import ValidatedButton from "@/components/Admin/ValidatedButton.vue";
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import ConditionalContext from "@/components/shared/ConditionalContext.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { capitalize } from "@/helpers/filters";
import { isGlobalAdmin } from "@/helpers/permissions/users";
import { del, put } from "@/requests/server";
import dayjs from "dayjs";

const typeMapping = {
  car: ["car_small", "car_large", "car_small_electric", "car_large_electric"],
  bike: ["bike_regular", "bike_electric", "bike_cargo_regular", "bike_cargo_electric"],
  trailer: ["trailer"],
};

export default {
  name: "PricingForm",
  components: { IconButton, ValidatedButton, ConditionalContext, FormsValidatedInput },
  props: {
    pricing: {
      type: Object,
      required: true,
    },
    initialPricing: {
      type: Object,
      default: undefined,
    },
    availableLoanableTypes: {
      type: Array,
      default: () => ["car", "bike", "trailer"],
    },
    onPricingSaved: {
      type: Function,
      default: undefined,
    },
    exemptFromContributions: {
      type: Boolean,
      default: false,
    },
    isPrivateCommunity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      saving: false,
      deleting: false,
      vehicleOwnershipOptions: [
        { value: "all", text: capitalize(this.$t("pricings.loanable_ownership_type.all")) },
        { value: "fleet", text: capitalize(this.$t("pricings.loanable_ownership_type.fleet")) },
        {
          value: "non_fleet",
          text: capitalize(this.$t("pricings.loanable_ownership_type.non_fleet")),
        },
      ],
    };
  },
  computed: {
    isGlobalAdmin() {
      return isGlobalAdmin(this.$store.state.user);
    },
    isFormDisabled() {
      return (
        this.pricing &&
        this.pricing.pricing_type &&
        !this.pricingTypeOptions.map((p) => p.value).includes(this.pricing.pricing_type)
      );
    },
    pricingTypeOptions() {
      const options = [
        {
          value: "contribution",
          text: capitalize(this.$t("pricings.types.contribution")),
        },
      ];

      if (this.isGlobalAdmin || this.isPrivateCommunity) {
        options.push({
          value: "price",
          text: capitalize(this.$t("pricings.types.price")),
        });
      }

      if (this.isGlobalAdmin) {
        options.push({
          value: "insurance",
          text: capitalize(this.$t("pricings.types.insurance")),
        });
      }
      return options;
    },
    showBikes() {
      return this.availableLoanableTypes.includes("bike");
    },
    showCars() {
      return this.availableLoanableTypes.includes("car");
    },
    showTrailers() {
      return this.availableLoanableTypes.includes("trailer");
    },
    bikeOptions() {
      return typeMapping.bike.map((t) => ({
        value: t,
        text: capitalize(this.$t(`pricings.loanableTypes.${t}`)),
      }));
    },
    carOptions() {
      return typeMapping.car.map((t) => ({
        value: t,
        text: capitalize(this.$t(`pricings.loanableTypes.${t}`)),
      }));
    },
    trailerOptions() {
      return typeMapping.trailer.map((t) => ({
        value: t,
        text: capitalize(this.$t(`pricings.loanableTypes.${t}`)),
      }));
    },

    allBikeChecked() {
      return this.includesAll(this.pricing.pricing_loanable_types, typeMapping.bike);
    },
    allCarsChecked() {
      return this.includesAll(this.pricing.pricing_loanable_types, typeMapping.car);
    },
    someBikeChecked() {
      return (
        !this.allBikeChecked &&
        this.includesAny(this.pricing.pricing_loanable_types, typeMapping.bike)
      );
    },
    someCarsChecked() {
      return (
        !this.allCarsChecked &&
        this.includesAny(this.pricing.pricing_loanable_types, typeMapping.car)
      );
    },
    hasPricingChanged() {
      if (!this.initialPricing) {
        return true;
      }

      for (const pricingKey in this.pricing) {
        if (pricingKey.startsWith("_")) {
          continue;
        }
        if (pricingKey === "pricing_loanable_types") {
          const same =
            this.pricing.pricing_loanable_types.length ===
              this.initialPricing.pricing_loanable_types.length &&
            this.pricing.pricing_loanable_types.every((p) =>
              this.initialPricing.pricing_loanable_types.includes(p)
            );
          if (same) {
            continue;
          }

          return true;
        }

        if (this.pricing[pricingKey] !== this.initialPricing[pricingKey]) {
          return true;
        }
      }
      return false;
    },
    hasStarted() {
      return this.pricing.start_date && dayjs().isSameOrAfter(this.pricing.start_date, "day");
    },
    hasEnded() {
      return this.pricing.end_date && dayjs().isSameOrAfter(this.pricing.end_date, "day");
    },
  },
  methods: {
    reset() {
      Object.assign(this.pricing, this.initialPricing);
      this.pricing._showDetails = true;
      this.emitChange();
    },
    isBeforeNow(date) {
      return dayjs(date).isSameOrBefore(dayjs(), "day");
    },
    isBeforeNowOrStartDate(date) {
      if (this.isBeforeNow(date)) {
        return true;
      }

      return this.pricing.start_date && dayjs(date).isSameOrBefore(this.pricing.start_date, "day");
    },
    allCarsClicked() {
      if (this.allCarsChecked) {
        this.updateLoanableTypes(
          this.pricing.pricing_loanable_types.filter((lt) => !typeMapping.car.includes(lt))
        );
      } else {
        this.updateLoanableTypes([
          ...new Set([...this.pricing.pricing_loanable_types, ...typeMapping.car]),
        ]);
      }
    },
    allBikesClicked() {
      if (this.allBikeChecked) {
        this.updateLoanableTypes(
          this.pricing.pricing_loanable_types.filter((lt) => !typeMapping.bike.includes(lt))
        );
      } else {
        this.updateLoanableTypes([
          ...new Set([...this.pricing.pricing_loanable_types, ...typeMapping.bike]),
        ]);
      }
    },
    includesAll(array, values) {
      for (const value of values) {
        if (!array.includes(value)) {
          return false;
        }
      }
      return true;
    },
    includesAny(array, values) {
      for (const value of values) {
        if (array.includes(value)) {
          return true;
        }
      }
      return false;
    },
    emitChange() {
      this.pricing._changed = this.hasPricingChanged;
      this.$emit("change", this.pricing);
    },
    updateLoanableTypes(loanableTypes) {
      this.pricing.pricing_loanable_types = loanableTypes;
      this.emitChange();
    },
    async save() {
      this.saving = true;
      if (!this.pricing.id) {
        // create
        const { data } = await put(`/pricings`, this.pricing, {
          notifications: {
            action: "création de la tarification",
            onSuccess: "tarification créée!",
          },
          cleanupCallback: () => (this.saving = false),
        });
        Object.assign(this.pricing, data);
        this.$emit("created");
      } else {
        // Save
        const { data } = await put(`/pricings/${this.pricing.id}`, this.pricing, {
          notifications: {
            action: "mise à jour de la tarification",
            onSuccess: "tarification mise à jour!",
          },
          cleanupCallback: () => (this.saving = false),
        });
        Object.assign(this.pricing, data);
      }

      this.pricing._changed = false;
      if (this.onPricingSaved) {
        this.onPricingSaved(this.pricing);
      }
      this.$emit("change", this.pricing);
    },
    async deletePricing() {
      if (!this.pricing.id) {
        this.$emit("cancel-create");
        return;
      }
      this.deleting = true;
      await del(`/pricings/${this.pricing.id}`, {
        notifications: {
          action: "archivage de la tarification",
          onSuccess: "tarification archivée!",
        },
        cleanupCallback: () => (this.deleting = false),
      });
      this.$emit("deleted");
    },
  },
};
</script>

<style lang="scss">
.pricing-form-context.conditional-context {
  margin: 0;
  padding-bottom: 1rem;
}
.pricing-form {
  .formula-input textarea {
    font-family: var(--font-family-monospace);
    white-space: pre-wrap;
  }

  .pricing-loanable-types {
    .group-select-all {
      display: inline-block;
      position: absolute;
      background: white;
      left: calc(0.75rem + 1px);
      top: -0.5rem;
      font-weight: bold;
    }

    .checkbox-group {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
      margin-top: 1rem;
      border-radius: 0.5rem;
      border: 1px solid $light-grey;
      padding: 1rem 0.75rem;
    }
  }
}
</style>
