import { isGlobalAdmin } from "@/helpers/permissions/users";

export function isAdminOfCommunity(user, community) {
  if (!community) {
    return false;
  }

  const communityUser = (user?.user_communities || []).find((c) => c.community_id === community.id);
  return communityUser && communityUser.role === "admin";
}

export function isAdminOfUserCommunity(accessingUser, accessedUser) {
  const adminCommunityIds = getAdminCommunities(accessingUser);
  const userCommunityIds = (accessedUser?.user_communities || []).map((c) => c.community_id);

  return adminCommunityIds.filter((id) => userCommunityIds.includes(id)).length > 0;
}

export function getAdminCommunities(user) {
  return (user?.user_communities || [])
    .filter((c) => c.role === "admin")
    .map((c) => c.community_id);
}

export function isAdminOfSomeCommunity(user) {
  return getAdminCommunities(user).length > 0;
}

export function canSeeDeletedCommunity(user) {
  return isGlobalAdmin(user);
}
