<template>
  <header class="layout-header">
    <div class="layout-header__navbar">
      <main-navbar :title="title" />
    </div>
  </header>
</template>

<script>
import MainNavbar from "@/components/Layout/Navbar.vue";

export default {
  name: "LayoutHeader",
  components: {
    MainNavbar,
  },
  props: {
    title: {
      type: String,
      require: false,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.suspended-message {
  background-color: rgb(255, 200, 200);
  text-align: center;
  padding: 0.5em;
}
</style>
