<script>
import IconButton from "@/components/shared/IconButton.vue";
import LazyLoad from "@/components/shared/Lazy.vue";
import MarkdownContent from "@/components/shared/MarkdownContent.vue";
import { defaultHandlers } from "hast-util-to-mdast";
import rehypeParse from "rehype-parse";
import rehypeRemark from "rehype-remark";
import rehypeRemoveComments from "rehype-remove-comments";
import remarkStringify from "remark-stringify";
import { unified } from "unified";
import { visit } from "unist-util-visit";

export default {
  name: "MarkdownEditor",
  components: { LazyLoad, MarkdownContent, IconButton },
  props: {
    save: {
      type: Function,
      required: false,
      default: null,
    },
    sendSample: {
      type: Function,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: null,
    },
    showPreviewInitially: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      saving: false,
      text: this.value,
      showPreview: this.showPreviewInitially,
      history: [],
      // Cursor for change history: index in history array of latest entry
      currentHistoryIndex: -1,
      inputCooldown: 0,
      shiftKeyPressed: false,
    };
  },
  mounted() {
    if (this.value || this.placeholder) {
      this.updateInputHeight();
    }
  },
  methods: {
    async onSave() {
      this.saving = true;
      try {
        await this.save(this.text);
        this.text = null;
        this.showPreview = false;
        if (this.$refs.textarea) {
          this.$refs.textarea.style.height = "";
        }
      } finally {
        this.saving = false;
      }
    },
    updateInputHeight() {
      if (this.$refs.textarea && this.$refs.textarea.scrollHeight > 0) {
        this.$refs.textarea.style.height = "";
        this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + "px";
      }
    },
    onInput() {
      this.updateInputHeight();
      this.$emit("input", this.text);
      this.recordHistory();
    },
    recordHistory(force = false) {
      // If recording after undo, truncate history
      if (this.currentHistoryIndex < this.history.length - 1) {
        this.history = this.history.slice(0, this.currentHistoryIndex + 1);
      }
      this.inputCooldown++;
      if (this.inputCooldown < 5 && !force) {
        return;
      }
      this.inputCooldown = 0;
      let currentHistoryEntry = {
        text: this.text,
        start: this.$refs.textarea.selectionStart,
        end: this.$refs.textarea.selectionEnd,
      };

      this.history.push(currentHistoryEntry);
      if (this.history.length > 20) {
        this.history = this.history.slice(this.history.length - 20);
      }

      this.currentHistoryIndex = this.history.length - 1;
    },
    undo() {
      if (this.currentHistoryIndex <= 0) {
        return;
      }
      this.inputCooldown = 0;
      this.currentHistoryIndex -= 1;
      this.setState(this.history[this.currentHistoryIndex]);
    },
    redo() {
      if (this.currentHistoryIndex >= this.history.length - 1) {
        return;
      }
      this.inputCooldown = 0;
      this.currentHistoryIndex += 1;
      this.setState(this.history[this.currentHistoryIndex]);
    },
    setState(state) {
      this.text = state.text;
      this.$refs.textarea.value = state.text;
      this.updateInputHeight();
      this.$emit("input", state.text);
      this.changeSelection(state.start, state.end);
    },
    currentLineStartIndex() {
      let lineStart = this.$refs.textarea.selectionStart;
      while (lineStart > 0 && this.text[lineStart - 1] !== "\n") {
        lineStart--;
      }
      return lineStart;
    },
    onShortcut(event) {
      if (event.key === "b") {
        event.preventDefault();
        return this.bold();
      }
      if (event.key === "i") {
        event.preventDefault();
        return this.italic();
      }
      if (event.key === "k") {
        event.preventDefault();
        return this.link();
      }
      if (event.key === "h") {
        event.preventDefault();
        return this.titleH3();
      }
      if (event.key === "-") {
        event.preventDefault();
        return this.strikethrough();
      }
      if (this.text && this.text.length > 0 && event.key === "p" && event.altKey) {
        event.preventDefault();
        this.togglePreview();
      }
      if (event.key === "z") {
        event.preventDefault();
        return this.undo();
      }
      if (event.key === "Z") {
        event.preventDefault();
        return this.redo();
      }
    },
    handlePreviewKeydown(event) {
      if (event.ctrlKey && event.key === "p" && event.altKey) {
        event.preventDefault();
        this.togglePreview();
      }
    },
    togglePreview() {
      this.showPreview = !this.showPreview;
      if (this.showPreview) {
        this.$refs.preview.render();
        this.$refs["preview-button"].$el.focus();
      } else {
        // Next tick, since currently it would not be visible (and not focusable)
        this.$nextTick(() => this.$refs.textarea?.focus());
      }
    },
    onTab(event) {
      let lineStart = this.currentLineStartIndex();
      let start = this.$refs.textarea.selectionStart;
      let end = this.$refs.textarea.selectionEnd;
      let prefix = this.text.slice(lineStart);
      if (event.shiftKey) {
        // Check if starts with prefix, and maybe remove prefix
        let prefixMatch = prefix.match(
          /^([^\S\r\n]{4,})*(([*-]|\d+\.|([^\S\r\n]*>)+)([^\S\r\n]\[(x|[^\S\r\n])\])?[^\S\r\n]+)/
        );
        if (!prefixMatch) {
          return;
        }
        // By default unindent by 4 spaces
        let lengthToRemove = 4;
        // No space before list bullets
        if (!prefixMatch[1]) {
          // remove length of list prefix
          lengthToRemove = prefixMatch[2].length;
        }
        this.setText(this.text.slice(0, lineStart) + this.text.slice(lineStart + lengthToRemove));
        this.changeSelection(start - lengthToRemove, end - lengthToRemove);
        event.preventDefault();
        return;
      }
      let prefixMatch = prefix.match(
        /^[^\S\r\n]*([*-]|\d+\.|([^\S\r\n]*>)+)([^\S\r\n]\[(x|[^\S\r\n])\])?[^\S\r\n]/
      );
      if (prefixMatch) {
        this.prefixLine("    ", /*canUnprefix: */ false);
        event.preventDefault();
      }
    },
    onEnter(event) {
      let lineStart = this.currentLineStartIndex();

      // Compare prefix with ("*", "-", "* [ ]", "* [x]", "1. "1. [ ]" with any amount of space before )
      let line = this.text.slice(lineStart);
      let prefixMatch = line.match(
        /^[^\S\r\n]*([*-]|(\d+)\.|([^\S\r\n]*>)+)([^\S\r\n]\[(x|[^\S\r\n])\])?[^\S\r\n]/
      );
      if (prefixMatch) {
        let prefix = prefixMatch[0];
        // increment number list, if present (\d+)
        if (prefixMatch[2]) {
          prefix = prefix.replace(prefixMatch[2], parseInt(prefixMatch[2]) + 1);
        }

        let currentCursor = this.$refs.textarea.selectionStart;
        this.setText(
          this.text.slice(0, currentCursor) + "\n" + prefix + this.text.slice(currentCursor)
        );

        // +1 For the line break
        this.changeSelection(currentCursor + prefix.length + 1, currentCursor + prefix.length + 1);
        event.preventDefault();
      }
    },
    onKeyUp() {
      this.shiftKeyPressed = false;
    },
    onKeydown(event) {
      if (event.shiftKey) {
        this.shiftKeyPressed = true;
      }
      if (event.ctrlKey) {
        return this.onShortcut(event);
      }
      if (event.code === "Enter") {
        return this.onEnter(event);
      }
      if (event.code === "Tab") {
        return this.onTab(event);
      }
    },
    async onPaste(event) {
      if (!event.target) return;
      let md = "";
      if (this.shiftKeyPressed) {
        md = event.clipboardData.getData("text/plain");
      } else {
        const html = event.clipboardData.getData("text/html");
        if (!html) {
          return;
        }
        event.preventDefault();
        md = await unified()
          .use(rehypeParse, { fragment: true })
          // We strip <p role="presentation">... which are not semantic paragraphs and are
          // present in the html from google docs. This removes unnecessary line breaks within
          // list items when copying from gDocs.
          .use(function removePresentationParagraphs() {
            return function (tree) {
              visit(
                tree,
                (node) => node.type === "element" && node.tagName === "p",
                function (node, index, parent) {
                  if (
                    typeof index === "number" &&
                    parent &&
                    node.properties.role === "presentation"
                  ) {
                    parent.children.splice(index, 1, ...node.children);
                    return index;
                  }
                }
              );
            };
          })
          .use(rehypeRemoveComments)
          .use(rehypeRemark, {
            handlers: {
              b(state, node) {
                // b with font-weight normal should not be bold.
                // google docs wrap the whole html fragment in an unnecessary b
                if (node.properties.style?.match("font-weight:(normal|400)")) {
                  return state.all(node);
                }
                return defaultHandlers.strong(state, node);
              },
              span(state, node) {
                if (node.properties.style?.match("font-weight:[5-9]00")) {
                  return defaultHandlers.strong(state, node);
                }

                if (node.properties.style?.match("font-style:italic")) {
                  return defaultHandlers.em(state, node);
                }
                return state.all(node);
              },
            },
          })
          .use(remarkStringify, {
            emphasis: "_",
            rule: "-",
          })
          .process(html);
      }
      if (!this.text) {
        this.text = "";
      }
      this.setText(
        this.text.slice(0, event.target.selectionStart) +
          String(md) +
          this.text.slice(event.target.selectionEnd)
      );
      event.preventDefault();
      return false;
    },
    bold() {
      this.wrapText("**");
    },
    italic() {
      this.wrapText("*");
    },
    strikethrough() {
      this.wrapText("~~");
    },
    titleH3() {
      this.prefixLine("### ");
    },
    listOl() {
      this.prefixLine("1. ");
    },
    listUl() {
      this.prefixLine("* ");
    },
    blockquote() {
      this.prefixLine("> ");
    },
    link() {
      this.wrapText("[", "](url)");
    },
    unprefixLine(prefix) {
      const lineStart = this.currentLineStartIndex();
      const start = this.$refs.textarea.selectionStart;
      const end = this.$refs.textarea.selectionEnd;
      const line = this.text.slice(lineStart);
      if (!line.startsWith(prefix)) {
        return false;
      }
      this.setText(this.text.slice(0, lineStart) + this.text.slice(lineStart + prefix.length));
      this.changeSelection(start - prefix.length, end - prefix.length);
      return true;
    },
    prefixLine(prefix, canUnprefix = true) {
      if (!this.$refs.textarea) {
        return;
      }
      if (!this.text) {
        this.text = "";
      }
      if (canUnprefix && this.unprefixLine(prefix)) {
        return;
      }
      const lineStart = this.currentLineStartIndex();
      const start = this.$refs.textarea.selectionStart;
      const end = this.$refs.textarea.selectionEnd;
      this.setText(this.text.slice(0, lineStart) + prefix + this.text.slice(lineStart));
      this.changeSelection(start + prefix.length, end + prefix.length);
    },
    unwrapText(before, after) {
      if (!this.$refs.textarea) {
        return false;
      }
      if (!after) {
        after = before;
      }
      let text = this.text;
      if (!text || text.length < before.length + after.length) {
        return false;
      }
      const start = this.$refs.textarea.selectionStart;
      const end = this.$refs.textarea.selectionEnd;
      // Wrapper is just outside selection
      if (
        text.slice(start - before.length, start) === before &&
        text.slice(end, end + after.length) === after
      ) {
        // remove unwanted wrapper
        this.setText(
          text.slice(0, start - before.length) +
            text.slice(start, end) +
            text.slice(end + after.length)
        );

        this.changeSelection(start - before.length, end - before.length);
        return true;
      }
      // Wrapper is just inside selection
      if (
        text.slice(start, start + before.length) === before &&
        text.slice(end - after.length, end) === after
      ) {
        this.setText(
          text.slice(0, start) +
            text.slice(start + before.length, end - after.length) +
            text.slice(end)
        );

        this.changeSelection(start, end - before.length - after.length);
        return true;
      }
      return false;
    },
    wrapText(before, after) {
      if (!this.$refs.textarea) {
        return;
      }
      if (!this.text) {
        this.text = "";
      }

      if (!after) {
        after = before;
      }

      if (this.unwrapText(before, after)) {
        return;
      }

      let start = this.$refs.textarea.selectionStart;
      let end = this.$refs.textarea.selectionEnd;
      this.setText(
        this.text.slice(0, start) +
          before +
          this.text.slice(start, end) +
          after +
          this.text.slice(end)
      );

      this.changeSelection(start + before.length, end + before.length);
    },
    setText(text) {
      this.text = text;
      // Change value eagerly (do not wait for vue to update) for less latency
      this.$refs.textarea.value = text;
      this.updateInputHeight();
      this.$emit("input", text);
      this.recordHistory(true);
    },
    changeSelection(start, end) {
      this.$refs.textarea.focus();
      this.$refs.textarea.selectionStart = start;
      this.$refs.textarea.selectionEnd = end;
    },
    tooltip(name, shortcutKeys) {
      let shortcuts = [];
      for (const shortcutKey of shortcutKeys) {
        shortcuts.push(`<span class="shortcut-key">${shortcutKey}</span>`);
      }
      return `${name} <br /> ${shortcuts.join(" ")}`;
    },
  },
};
</script>

<template>
  <div class="send-comment-input form-control" :class="{ 'showing-preview': showPreview }">
    <div class="d-flex flex-row align-items-start">
      <div class="flex-grow-1 input-content">
        <markdown-content v-if="text" v-show="showPreview" ref="preview" :content="text" />

        <div v-show="!showPreview">
          <!-- Lazy loading the editor, so that initial height computes accurately when first shown -->
          <lazy-load @load="() => $nextTick(updateInputHeight)">
            <textarea
              ref="textarea"
              v-model="text"
              :disabled="saving || disabled"
              rows="1"
              :name="name"
              :placeholder="placeholder"
              @input="onInput"
              @paste="onPaste"
              @keydown="onKeydown"
              @keyup="onKeyUp"
            />
          </lazy-load>
        </div>
      </div>
      <icon-button
        v-if="text && text.length > 0"
        ref="preview-button"
        v-b-tooltip.hover.html.ds1000.bottom="
          tooltip(showPreview ? 'Modifier' : 'Aperçu', ['ctrl', 'alt', 'p'])
        "
        variant="transparent-primary"
        size="sm"
        :pressed="showPreview"
        :icon="showPreview ? 'pencil' : 'eye'"
        @click="togglePreview"
        @keydown="handlePreviewKeydown"
      />
    </div>
    <div class="d-flex flex-row justify-content-between align-items-end input-action-row">
      <div class="button-list">
        <icon-button
          v-b-tooltip.html.ds1000.bottom="tooltip('Gras', ['ctrl', 'b'])"
          variant="transparent-secondary"
          icon="type-bold"
          :disabled="showPreview || disabled"
          size="sm"
          @click="bold"
        />
        <icon-button
          v-b-tooltip.html.ds1000.bottom="tooltip('Italique', ['ctrl', 'i'])"
          variant="transparent-secondary"
          icon="type-italic"
          :disabled="showPreview || disabled"
          size="sm"
          @click="italic"
        />
        <icon-button
          v-b-tooltip.html.ds1000.bottom="tooltip('Barré', ['ctrl', '-'])"
          variant="transparent-secondary"
          icon="type-strikethrough"
          :disabled="showPreview || disabled"
          size="sm"
          @click="strikethrough"
        />
        <icon-button
          v-b-tooltip.html.ds1000.bottom="tooltip('Titre', ['ctrl', 'h'])"
          variant="transparent-secondary"
          icon="type-h3"
          :disabled="showPreview || disabled"
          size="sm"
          @click="titleH3"
        />
        <div class="button-separator"></div>
        <div class="button-list d-none d-lg-flex">
          <icon-button
            v-b-tooltip.html.ds1000.bottom="'Citation'"
            variant="transparent-secondary"
            icon="blockquote-left"
            :disabled="showPreview || disabled"
            size="sm"
            @click="blockquote"
          />
          <icon-button
            v-b-tooltip.html.ds1000.bottom="tooltip('Lien', ['ctrl', 'k'])"
            variant="transparent-secondary"
            icon="link45deg"
            :disabled="showPreview || disabled"
            size="sm"
            @click="link"
          />
          <icon-button
            v-b-tooltip.html.ds1000.bottom="'Liste numérotée'"
            variant="transparent-secondary"
            icon="list-ol"
            :disabled="showPreview || disabled"
            size="sm"
            @click="listOl"
          />
          <icon-button
            v-b-tooltip.html.ds1000.bottom="'Liste par puces'"
            variant="transparent-secondary"
            icon="list-ul"
            :disabled="showPreview || disabled"
            size="sm"
            @click="listUl"
          />
        </div>
        <icon-button
          id="input-more"
          variant="transparent-secondary"
          icon="three-dots"
          :disabled="showPreview || disabled"
          class="d-lg-none"
        />
        <b-popover target="input-more" placement="top" triggers="focus">
          <div class="button-list">
            <icon-button
              variant="transparent-secondary"
              icon="blockquote-left"
              :disabled="showPreview || disabled"
              size="sm"
              @click="blockquote"
            />
            <icon-button
              variant="transparent-secondary"
              icon="link45deg"
              :disabled="showPreview || disabled"
              size="sm"
              @click="link"
            />
            <icon-button
              variant="transparent-secondary"
              icon="list-ol"
              :disabled="showPreview || disabled"
              size="sm"
              @click="listOl"
            />
            <icon-button
              variant="transparent-secondary"
              icon="list-ul"
              :disabled="showPreview || disabled"
              size="sm"
              @click="listUl"
            />
          </div>
        </b-popover>
      </div>
      <icon-button
        v-if="save"
        variant="primary"
        :disabled="!text || disabled"
        role="send"
        :onclick="onSave"
      ></icon-button>
      <icon-button
        v-if="sendSample"
        v-b-tooltip.hover="'Vous recevrez un example du courriel.'"
        :disabled="!text"
        size="sm"
        variant="ghost-secondary"
        icon="envelope"
        :onclick="sendSample || disabled"
      >
        Tester
      </icon-button>
    </div>
  </div>
</template>

<style>
.b-tooltip {
  .tooltip-inner {
    text-align: center;
  }
  .shortcut-key {
    display: inline-block;
    padding: 1px 4px;
    border-radius: 4px;
    background: #413737;
    border: 1px solid #808080;
    margin-top: 2px;
    font-size: 0.8em;
  }
}
</style>

<style scoped lang="scss">
.input-action-row {
  margin: 0rem 0 0 -0.5rem;
}
.button-list {
  gap: 0.125rem;
  button {
    padding: 0.125rem 0.4rem;
    transition-duration: 0s;
    &.disabled:hover {
      background: transparent;
    }
  }
  .button-separator {
    width: 1px;
    background: $light-grey;
    margin: 0.25rem 0;
  }
}

.send-comment-input {
  height: auto;
  display: block;
  padding: 0.5rem 0.5rem 0.25rem 0.75rem;
  &.showing-preview {
    background: #e5f8f6;
  }
  &:focus-within {
    box-shadow: 0 0 0 0.2rem rgba(22, 165, 158, 0.25);
  }
  .input-content {
    min-height: 2rem;
  }
  textarea {
    color: $dark;
    border: none;
    padding: 0;
    margin: 0 0 -0.25rem;
    width: 100%;
    box-shadow: none;
    background: transparent;
    resize: none;
    overflow: hidden;
    line-height: 1.5;
    vertical-align: top;
    &:focus,
    &.focus {
      box-shadow: none;
      border: none;
      outline: none;
    }
  }
}
</style>
