/**
 * @param {Element} root root element of the content to truncate
 * @param {number} maxHeight max height of the root element
 * @param {Node} lastNode last node of the content to truncate
 * @param {?Element} elementToAppend element to append to the end
 * @return {boolean} true if the height of the root container is below the max height
 */
function checkHeight(root, maxHeight, lastNode, elementToAppend = null) {
  // If appendElement doesn't exist, we simply check the element height
  if (!elementToAppend) {
    return root.offsetHeight <= maxHeight;
  }
  // If appendElement exists, we must try adding it at the end which may impact the height
  let appendedElement = appendElement(root, lastNode, elementToAppend);
  let result = root.offsetHeight <= maxHeight;
  appendedElement.parentNode.removeChild(appendedElement);
  return result;
}

/**
 * Adds the `elementToAppend` to the given node, or the first parent (below or equal to the root) that can receive it.
 *
 * @param {Element} root root element of the content to truncate
 * @param {Node} node to which the element will be appended
 * @param {Element} elementToAppend element to append
 * @return {Element}
 */
function appendElement(root, node, elementToAppend) {
  let currentNode = node;
  // Find the first parent which can receive the link (p, li or h1-6)
  while (
    currentNode !== root &&
    !(
      currentNode instanceof HTMLParagraphElement ||
      currentNode instanceof HTMLLIElement ||
      currentNode instanceof HTMLHeadingElement
    )
  ) {
    currentNode = currentNode.parentElement;
  }
  // in case where initial `node` is a text node within root (which may be just a \n for the browser) we want to append at the last element of the root
  if (currentNode === root) {
    let lastChild = currentNode.lastElementChild;
    if (
      lastChild instanceof HTMLParagraphElement ||
      lastChild instanceof HTMLLIElement ||
      lastChild instanceof HTMLHeadingElement
    ) {
      currentNode = lastChild;
    }
  }
  return currentNode.appendChild(elementToAppend);
}

/**
 * Truncates the element content so it fits within the specified height
 * @param {Element} root root element of the content to truncate
 * @param {number} maxHeight max height of the root element
 * @param {string} initialHtml initial html of the root element
 * @param {?Element} elementToAppend element to append to the end
 */
export function truncate(root, maxHeight, initialHtml = null, elementToAppend = null) {
  if (!root) {
    // If element has been destroyed, do nothing
    return;
  }
  if (!maxHeight) {
    return;
  }

  let lastNode = null;

  let currentTrimmedNode = root;
  if (initialHtml) {
    currentTrimmedNode.innerHTML = initialHtml;
  }

  if (root.offsetHeight <= maxHeight) {
    // No need for truncation: full markdown content is smaller than max height
    return true;
  }

  let currentOriginalNode = currentTrimmedNode.cloneNode(true);
  let originalRoot = currentOriginalNode;
  currentTrimmedNode.innerHTML = "";

  // Loop through original nodes, moving to the trimmed node using a depth first approach, until originalRoot is an empty shell and trimmed content is full.
  while (currentOriginalNode !== originalRoot || currentOriginalNode.childNodes.length > 0) {
    if (currentOriginalNode.childNodes.length <= 0) {
      // No more children, go up the stack and remove current node from original
      currentTrimmedNode = currentTrimmedNode.parentNode;
      const originalParent = currentOriginalNode.parentNode;
      originalParent.removeChild(currentOriginalNode);
      currentOriginalNode = originalParent;
      continue;
    }
    let originalChildNode = currentOriginalNode.firstChild;
    // Append a copy of the original child without its content to the trimmed node
    currentTrimmedNode = currentTrimmedNode.appendChild(originalChildNode.cloneNode(false));
    currentOriginalNode = originalChildNode;
    if (currentTrimmedNode instanceof Text && currentTrimmedNode.wholeText === "\n") {
      // Simple html separator element
      continue;
    }
    if (checkHeight(root, maxHeight, currentTrimmedNode, elementToAppend)) {
      // We can still add more content, keep looping
      continue;
    }

    // We are over the required height, attempt to trim the text of the last node
    const textContent = currentTrimmedNode.textContent;
    let parent = currentTrimmedNode.parentNode;
    // return early if no text
    if (!textContent || textContent.length === 0) {
      parent.removeChild(currentTrimmedNode);
      lastNode = parent.lastElementChild;
      break;
    }

    // Replace text node with span element, so we can add the link in that text
    if (currentTrimmedNode instanceof Text) {
      parent.removeChild(currentTrimmedNode);
      currentTrimmedNode = document.createElement("span");
      currentTrimmedNode.innerHTML = textContent;
      parent.appendChild(currentTrimmedNode);
      // Check with link within the text span
      if (checkHeight(root, maxHeight, currentTrimmedNode, elementToAppend)) {
        lastNode = currentTrimmedNode;
        break;
      }
    }

    // try to reduce the content of the last node
    let text = textContent.split(" ");
    currentTrimmedNode.innerHTML = "";
    let i = 0;
    while (checkHeight(root, maxHeight, currentTrimmedNode, elementToAppend) && i <= text.length) {
      currentTrimmedNode.innerHTML = text.slice(0, i).join(" ");
      i++;
    }

    // Could not add anything
    if (i <= 1) {
      parent.removeChild(currentTrimmedNode);
      lastNode = parent.lastElementChild;
    } else {
      currentTrimmedNode.innerHTML = text.slice(0, i - 2).join(" ");
      lastNode = currentTrimmedNode;
    }
    break;
  }

  if (lastNode) {
    appendElement(root, lastNode, elementToAppend);
  }
  return true;
}
