<template>
  <div class="profile-payment_methods">
    <h2>{{ $t("paymentMethods.saved_payment_methods") }}</h2>
    <ul class="profile-payment_methods__payment_methods">
      <li
        v-for="paymentMethod in paymentMethods"
        :key="paymentMethod.id"
        class="d-flex align-items-center"
      >
        <router-link :to="'/profile/payment_methods/' + paymentMethod.id"
          >{{ paymentMethod.name }}
        </router-link>
        <icon-button
          size="sm"
          role="remove-item"
          class="ml-2"
          :loading="deletingIds[paymentMethod.id]"
          @click="() => destroy(paymentMethod.id)"
        >
          {{ $t("forms.delete") | capitalize }}
        </icon-button>
      </li>
    </ul>
    <div>
      <icon-button v-if="!addingPaymentMethod" role="add" @click="addPaymentMethod">
        {{ $t("paymentMethods.add_new") }}
      </icon-button>
      <b-card v-else>
        <payment-method-form
          show-cancel
          @created="addedMethod"
          @cancel="addingPaymentMethod = false"
        />
      </b-card>
    </div>
    <hr />
    <bank-details-form />
  </div>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";
import BankDetailsForm from "@/components/PaymentMethod/BankDetailsForm.vue";

export default {
  name: "ProfilePaymentMethods",
  components: {
    IconButton,
    // Lazy load the component to avoid loading stripe api unless needed
    PaymentMethodForm: () => import("@/components/PaymentMethod/PaymentMethodForm.vue"),
    BankDetailsForm,
  },
  data() {
    return {
      deletingIds: {},
      addingPaymentMethod: false,
    };
  },
  computed: {
    paymentMethods() {
      return this.$store.state.user.payment_methods;
    },
  },
  methods: {
    async destroy(id) {
      this.$set(this.deletingIds, id, true);
      await this.$store.dispatch("paymentMethods/destroy", { id });
      const currentMethods = this.$store.state.user.payment_methods;
      this.$store.commit("user", {
        ...this.$store.state.user,
        payment_methods: currentMethods.filter((m) => m.id !== id),
      });
      this.deletingIds[id] = false;
    },
    addPaymentMethod() {
      this.addingPaymentMethod = true;
    },
    async addedMethod() {
      this.addingPaymentMethod = false;
    },
  },
};
</script>
<style lang="scss">
.profile-payment_methods {
  &__payment_methods {
    list-style: none;
    padding-left: 0;
    li {
      margin-top: 0.5rem;
    }
  }
}
</style>
