<template>
  <div v-if="!communityUsers" class="text-center">
    <p>Erreur de chargement des communautés.</p>
  </div>
  <div v-else>
    <b-card
      v-for="communityUser in communityUsers"
      :key="communityUser.id"
      class="profile-communities__community mb-3"
      @click="$router.push(`/profile/communities/${communityUser.community.id}`)"
    >
      <div class="profile-communities__community_content">
        <div class="profile-communities__community_header">
          <h4 class="mb-0">{{ communityUser.community.name }}</h4>

          <div class="profile-communities__subtitle">
            <span
              >Communauté
              {{ communityUser.community.type === "borough" ? "publique" : "privée" }}</span
            >
            &bull;
            <span> {{ communityUser.community.approved_users_count }} membres approuvé-es </span>
          </div>
        </div>

        <ul
          v-if="
            communityUser.community.starting_guide_url ||
            communityUser.community.chat_group_url ||
            communityUser.community.contact_email
          "
          class="profile-communities__resources__list"
        >
          <li
            v-if="communityUser.community.contact_email"
            class="community-overview__community__link"
          >
            <a target="_blank" :href="`mailto:${communityUser.community.contact_email}`">
              <b-icon class="mr-1" icon="envelope-open" />
              {{ communityUser.community.contact_email }}
            </a>
          </li>
          <li v-if="communityUser.community.starting_guide_url">
            <a :href="communityUser.community.starting_guide_url" target="_blank">
              <b-icon icon="clipboard-check" class="mr-1" />
              Guide de départ
            </a>
          </li>
          <li v-if="communityUser.community.chat_group_url">
            <a :href="communityUser.community.chat_group_url" target="_blank">
              <b-icon icon="chat-dots" class="mr-1" />
              Discuter avec ma communauté
            </a>
          </li>
        </ul>

        <div class="profile-communities__tags">
          <div class="profile-communities__tags__item">
            <span class="profile-communities__tags__item__label">Membrariat</span>
            <span>
              <b-badge v-if="communityUser.join_method === 'geo'" variant="success">
                Géographique
              </b-badge>
              <b-badge v-if="communityUser.join_method === 'manual'" variant="warning">
                Ajout manuel
              </b-badge>
              <b-badge v-if="communityUser.join_method === 'invitation'" variant="primary">
                Invitation
              </b-badge>
            </span>
          </div>

          <div class="profile-communities__tags__item">
            <span class="profile-communities__tags__item__label">Statut</span>
            <span>
              <b-badge v-if="communityUser.status === 'approved'" variant="success">
                Approuvé
              </b-badge>
              <b-badge v-else-if="communityUser.status === 'suspended'" variant="danger">
                <b-icon icon="exclamation-triangle" /> Suspendu
              </b-badge>
              <b-badge v-else-if="communityUser.proof_state === 'absent'" variant="danger">
                Preuves manquantes
              </b-badge>
              <b-badge v-else-if="communityUser.proof_state === 'partial'" variant="warning">
                Preuves partielles
              </b-badge>
              <b-badge v-else-if="communityUser.proof_state === 'unevaluated'" variant="warning">
                Preuves soumises
              </b-badge>
              <b-badge v-else-if="communityUser.proof_state === 'invalid'" variant="danger">
                Preuves à corriger
              </b-badge>
            </span>
          </div>

          <div
            v-if="
              communityUser.current_paid_subscription || communityUser.current_granted_subscription
            "
            class="profile-communities__tags__item"
          >
            <template v-if="communityUser.current_granted_subscription">
              <span class="profile-communities__tags__item__label">Gratuité</span>
              <b-badge variant="success">
                jusqu'au {{ communityUser.current_granted_subscription.end_date | date }}
              </b-badge>
            </template>
            <template v-else>
              <span class="profile-communities__tags__item__label">Contribution</span>
              <b-badge variant="success">
                jusqu'au {{ communityUser.current_paid_subscription.end_date | date }}
              </b-badge>
            </template>
          </div>
        </div>
      </div>
      <div class="profile-communities__community_actions">
        <icon-button
          variant="ghost-secondary"
          :icon="communityUser.status === 'approved' ? 'three-dots' : 'person-badge'"
          :to="`/profile/communities/${communityUser.community.id}`"
        >
          {{ communityUser.status === "approved" ? "Détails" : "Mes preuves" }}
        </icon-button>
      </div>
    </b-card>

    <b-card v-if="communityUsers.length === 0" class="profile-communities__nocommunities">
      <div>
        <h3>Vous n'êtes pas encore inscrit au sein d'une communauté soutenue par LocoMotion.</h3>
      </div>
      <b-card-body>
        <p>
          Il se peut que LocoMotion ne soit pas encore ouvert dans votre quartier ou que votre
          adresse n'est pas à jour.
        </p>
        <icon-button variant="primary" to="/profile/locomotion"> Modifier mon adresse</icon-button>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";

export default {
  name: "ProfileCommunity",
  components: { IconButton },
  computed: {
    user() {
      return this.$store.state.user;
    },
    communityUsers() {
      return this.$store.state.user.user_communities;
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";
.profile-communities {
  &__headers {
    padding-left: 15px;

    h4 {
      color: grey;
      font-size: 16px;
    }
  }

  &__community {
    box-shadow: $small-shadow;
    cursor: pointer;
    transition: box-shadow 0.2s ease-in-out;
    &:hover {
      box-shadow: $medium-shadow;
    }
  }

  &__nocommunities {
    .btn {
      margin-left: 0;
    }
  }

  &__tags {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  &__community .card-body {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
    }

    @include media-breakpoint-down(sm) {
      align-items: stretch;
    }
  }

  &__community_content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: space-between;
    flex: 1;
  }

  &__community_header {
    display: flex;
    flex-direction: column;
  }

  &__subtitle {
    font-size: 0.8rem;
    color: $text-muted;
  }

  &__resources__list {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 0.25rem;
    }
  }

  &__tags {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  &__tags__item {
    border: 1px solid $light-grey;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  &__community_actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
</style>
