<template>
  <div v-if="itemLoaded && routeDataLoaded" class="admin-invoice-form">
    <vue-headful :title="fullTitle" />

    <b-container fluid>
      <b-row>
        <b-col>
          <h1 v-if="item.period">{{ item.period }}</h1>
          <h1 v-else>
            <em>{{ $tc("invoices.invoice", 1) | capitalize }}</em>
          </h1>
        </b-col>
      </b-row>

      <single-invoice v-if="item.id" :invoice="item" />
      <div v-else>
        <b-row>
          <b-col>
            <div>
              <b-row>
                <b-col sm="6">
                  <p class="text-left">
                    Réseau LocoMotion<br />
                    6450, Ave Christophe-Colomb<br />
                    Montréal, QC<br />
                    H2S 2G7
                  </p>
                </b-col>

                <b-col sm="6">
                  <p class="text-right">
                    {{ user.full_name }}<br />
                    {{ user.address }}<br />
                    {{ user.postal_code }}
                  </p>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>

        <validation-observer ref="observer" v-slot="{ passes }">
          <b-row class="mt-3 mb-3">
            <b-col>
              <forms-validated-input
                v-model="item.period"
                inline="auto"
                label="Titre"
                type="text"
                name="period"
                :rules="{ required: true }"
              />
            </b-col>
          </b-row>

          <b-form class="form" @submit.prevent="passes(mergeUserAndSubmit)">
            <b-row>
              <b-col>
                <b-table
                  class="bill-item-table"
                  :items="item.bill_items"
                  :fields="fields"
                  no-local-sorting
                  no-sort-reset
                >
                  <template #cell(label)="row">
                    <forms-validated-input
                      v-model="row.item.label"
                      label="Description"
                      type="text"
                      name="label"
                      :rules="{ required: true }"
                      hide-label
                    />
                  </template>

                  <template #cell(amount)="row">
                    <forms-validated-input
                      v-model="row.item.amount"
                      label="Montant"
                      type="currency"
                      name="amount"
                      :rules="{ required: true }"
                      hide-label
                    />
                  </template>

                  <template #cell(item_type)="row">
                    <forms-validated-input
                      v-model="row.item.item_type"
                      label="Type"
                      type="select"
                      name="item_type"
                      :options="billItemTypes"
                      :rules="{ required: true }"
                      hide-label
                    />
                  </template>

                  <template #cell(actions)="row">
                    <icon-button
                      size="sm"
                      class="mr-1"
                      variant="ghost-secondary"
                      :pressed.sync="row.detailsShowing"
                      @click="row.toggleDetails"
                    >
                      Taxes
                    </icon-button>
                    <icon-button size="sm" role="remove-item" @click="removeBillItem(row.item)" />
                  </template>

                  <template #row-details="row">
                    <forms-validated-input
                      v-model="row.item.taxes_tps"
                      label="TPS"
                      type="currency"
                      name="taxes_tps"
                      :rules="{ required: true }"
                    />
                    <forms-validated-input
                      v-model="row.item.taxes_tvq"
                      label="TVQ"
                      type="currency"
                      name="taxes_tvq"
                      :rules="{ required: true }"
                    />
                  </template>

                  <template #custom-foot>
                    <b-tr>
                      <b-td colspan="4">
                        <icon-button
                          block
                          class="justify-content-center"
                          role="add"
                          variant="ghost-secondary"
                          size="sm"
                          @click="createNewBillItem"
                        >
                          Nouvel item
                        </icon-button>
                      </b-td>
                    </b-tr>

                    <b-tr class="invoice-view__footer-row">
                      <b-td colspan="2">
                        Sous-total<br />
                        TPS<br />
                        TVQ<br />
                        Total
                      </b-td>
                      <b-td class="text-right tabular-nums">
                        {{ itemTotal | currency }}<br />
                        {{ itemTotalTps | currency }}<br />
                        {{ itemTotalTvq | currency }}<br />
                        {{ itemTotalWithTaxes | currency }}
                      </b-td>
                    </b-tr>
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <forms-validated-input
                  label="Payée"
                  type="checkbox"
                  :value="true"
                  name="paid_at"
                  disabled
                  description="Les factures créées manuellement sont présumées déjà payées."
                />
              </b-col>
            </b-row>

            <b-alert :variant="invoiceEffect.after < 0 ? 'danger' : 'success'" show>
              <p v-if="invoiceEffect.after < 0" class="font-weight-bold">
                Le solde de l'utilisateur après la sauvegarde ne peut être négatif.
              </p>
              <template v-else>
                <p class="font-weight-bold">
                  Une fois la facture créée, elle ne peut être modifiée.
                </p>
                <div>Assurez-vous que l'effet suivant est bel et bien attendu :</div>
              </template>

              <div class="my-2">
                <user-blurb :user="user" class="invoice-user-effect">
                  <template #description>
                    <div class="position-relative">
                      <layout-loading v-if="estimatingEffect" class="loading-indicator" />
                      <table :class="{ loading: estimatingEffect }">
                        <tr>
                          <td>Balance actuelle</td>
                          <td class="text-right tabular-nums">
                            {{ invoiceEffect.before | currency }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pb-1">Effet de la facture</td>
                          <td class="pb-1 text-right tabular-nums">
                            {{ invoiceEffect.change > 0 ? "+" : "" }}&nbsp;{{
                              invoiceEffect.change | currency
                            }}
                          </td>
                        </tr>
                        <tr class="border-top">
                          <td class="pt-1">Balance après sauvegarde</td>
                          <td class="pt-1 text-right tabular-nums">
                            {{ invoiceEffect.after | currency }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </template>
                </user-blurb>
              </div>

              <div class="form__buttons mt-3">
                <b-button-group>
                  <icon-button
                    variant="success"
                    type="submit"
                    :disabled="!changed || invoiceEffect.after < 0"
                    :loading="loading"
                    icon="file-earmark-arrow-down"
                  >
                    Enregistrer
                  </icon-button>
                </b-button-group>
              </div>
            </b-alert>
          </b-form>
        </validation-observer>
      </div>
    </b-container>
  </div>
  <layout-loading v-else />
</template>

<script>
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import SingleInvoice from "@/components/Invoice/Single.vue";
import LayoutLoading from "@/components/Layout/Loading.vue";
import IconButton from "@/components/shared/IconButton.vue";
import UserBlurb from "@/components/User/UserBlurb.vue";

import { debounce } from "@/helpers/debounce";
import { capitalize } from "@/helpers/filters";

import DataRouteGuards from "@/mixins/DataRouteGuards";
import FormMixin from "@/mixins/FormMixin";
import { post } from "@/requests/server";

export default {
  name: "AdminInvoice",
  components: {
    IconButton,
    LayoutLoading,
    UserBlurb,
    FormsValidatedInput,
    SingleInvoice,
  },
  mixins: [DataRouteGuards, FormMixin],
  data() {
    return {
      fields: [
        { key: "label", label: "Description", sortable: false },
        { key: "item_type", label: "Type", sortable: false },
        { key: "amount", label: "Montant", sortable: false, tdClass: "text-right tabular-nums" },
        { key: "actions", label: "Actions", tdClass: "table__cell__actions" },
      ],
      newBillItemTemplate: {
        label: "",
        amount: null,
        taxes_tps: 0,
        taxes_tvq: 0,
      },
      invoiceEffect: {},
      estimatingEffect: true,
      showTaxesIndex: {},
    };
  },
  computed: {
    billItemTypes() {
      const values = [
        "balance.provision",
        "balance.refund",
        "loan.price",
        "loan.expenses",
        "loan.insurance",
        "donation.loan",
        "donation.balance",
        "fees.stripe",
      ];

      return values.map((v) => ({
        value: v,
        text: this.$t(`bill_items.fields.item_types["${v}"]`),
      }));
    },
    fullTitle() {
      const parts = [
        "LocoMotion",
        capitalize(this.$i18n.t("titles.admin")),
        capitalize(this.$i18n.tc("invoices.invoice", 2)),
      ];

      if (this.pageTitle) {
        parts.push(this.pageTitle);
      }

      return parts.reverse().join(" | ");
    },
    itemTotal() {
      return this.item.bill_items.reduce((acc, i) => parseFloat(i.amount) + acc, 0);
    },
    itemTotalTps() {
      return this.item.bill_items.reduce((acc, i) => parseFloat(i.taxes_tps) + acc, 0);
    },
    itemTotalTvq() {
      return this.item.bill_items.reduce((acc, i) => parseFloat(i.taxes_tvq) + acc, 0);
    },
    itemTotalWithTaxes() {
      return this.itemTotal + this.itemTotalTps + this.itemTotalTvq;
    },
    pageTitle() {
      return this.item.name || capitalize(this.$i18n.tc("invoices.invoice", 1));
    },
    user() {
      // Use the invoice linked user if present, fallback to previously edited user
      // (when doing 'add invoice' on user page)
      return this.item?.user || this.$store.state.users.item;
    },
    itemWithUserId() {
      return {
        user_id: this.user.id,
        ...this.item,
      };
    },
    // For estimate watcher
    billItems() {
      return (this.item?.bill_items || []).map((b) => {
        const item = { ...b };
        // Toggling details in the b-table adds this property to the row items
        delete item._showDetails;
        // The label should not have an effect on the estimate
        delete item.label;
        return item;
      });
    },
  },
  watch: {
    routeDataLoaded(loaded) {
      if (loaded) {
        this.debouncedEstimate();
      }
    },
    billItems(newValue, oldValue) {
      if (this.routeDataLoaded && JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.debouncedEstimate();
      }
    },
  },
  created() {
    this.debouncedEstimate = debounce(this.estimate);
  },
  methods: {
    createNewBillItem() {
      this.item.bill_items.push({ ...this.newBillItemTemplate });
    },
    async estimate() {
      // Do not estimate missing invoice or already created invoice, only new invoices.
      if (!this.item || this.item.id) {
        return;
      }

      this.estimatingEffect = true;
      const { data } = await post("/invoice/estimate", this.itemWithUserId, {
        notifications: { action: "estimation de l'effet de la facture" },
        requestOptions: { cancelId: "estimating-invoice" },
        cleanupCallback: () => (this.estimatingEffect = false),
      });
      this.invoiceEffect = Object.assign({}, this.invoiceEffect, data);
    },
    async mergeUserAndSubmit() {
      this.item.user_id = this.user.id;
      await this.submit();
    },
    removeBillItem(item) {
      const index = this.item.bill_items.indexOf(item);

      this.item.bill_items.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
.admin-invoice-form {
  .bill-item-table {
    tr:not(.b-table-details) .form-group {
      margin-bottom: 0;
    }
  }

  .invoice-user-effect {
    max-width: 25rem;

    .loading-indicator {
      position: absolute;
      height: 100%;
      width: 100%;
    }

    table {
      font-size: 0.9rem;
      width: 100%;

      &.loading {
        opacity: 0.3;
      }
    }
  }
}
</style>
