import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
  apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  version: "3.59",
});

const mapId = "c2e7914d6de2166a";

export { loader, mapId };
