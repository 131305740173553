<template>
  <layout-page name="dashboard" wide>
    <b-container fluid="lg">
      <b-row class="page__section page__section__main">
        <b-col class="page__content" xl="9" lg="8">
          <!-- main header -->
          <h1>{{ $t("views.dashboard.welcome_text", { name: user.name }) }}</h1>

          <h3 v-if="hasCommunity" class="dashboard-h3 mb-5">
            <span v-if="userHasPrivateCommunity"> Vous êtes {{ neighborCount }} membres dans </span>
            <span v-else>
              {{
                $t("views.dashboard.welcome_description", {
                  approvedUserCount: neighborCount,
                })
              }}
            </span>
            <template v-for="(c, i) of communitiesToDisplay">
              <span
                :id="`community-name-${c.id}`"
                :key="`community-${c.id}`"
                class="text-locomotion"
                >{{ c.name }}</span
              >
              <span v-if="i < communitiesToDisplay.length - 1" :key="`space-${i}`">{{
                i < user.user_communities.length - 2 ? ", " : " et "
              }}</span>
              <span v-else :key="`end-${c.id}`">.</span>
            </template>

            <b-popover
              v-if="$store.state.invitation.newlyAcceptedCommunityId"
              :show="showNewlyAcceptedCommunity"
              :target="`community-name-${$store.state.invitation.newlyAcceptedCommunityId}`"
              title="🎉 Invitation acceptée! 🎉"
              variant="success"
              placement="bottom"
            />
          </h3>

          <section v-if="!hasCommunity" class="page__section">
            <b-jumbotron
              bg-variant="light"
              class="no-communities-jumbotron"
              header="LocoMotion n'existe pas encore dans votre quartier"
              lead="Mais on y travaille! En attendant, devenez acteur de votre quartier et aidez LocoMotion à améliorer votre mobilité et celle de vos voisin-e-s."
            >
              <icon-button variant="primary" href="https://bit.ly/locoquartier" target="_blank">
                En savoir plus
              </icon-button>
            </b-jumbotron>
          </section>

          <!-- profile pending container -->
          <section v-else-if="waitingForProfileApproval" class="page__section">
            <b-jumbotron
              bg-variant="light"
              header="Votre profil est en attente de validation."
              :lead="waitingForApprovalText"
            >
            </b-jumbotron>

            <div v-if="hasTutorial('fill-your-driving-profile')" key="fill-profile-tutorial">
              <tutorial-block
                title="Remplissez votre dossier de conduite"
                subtitle="Pour emprunter des autos"
                to="/profile/borrower"
                bg-image="/img-voiture.png"
                variant="dark"
              />
            </div>
          </section>

          <section
            v-if="hasTutorial('upload-proofs')"
            class="page__section page__section__tutorials"
          >
            <h2 class="dashboard--margin-bottom">Pour commencer</h2>
            <tutorial-block
              :title="provideProofText"
              :subtitle="provideProofSubtitle"
              :to="provideProofLink"
              bg-image="/img-tetes.png"
              variant="light"
            />
          </section>
          <!---->
          <!-- We still want to show loans if user has moved and is not approved in any community
            anymore. -->
          <div v-if="!hasNoLoans || userIsApproved">
            <div class="page__section position-relative">
              <b-row>
                <b-col cols="12" sm><h2>Emprunts</h2></b-col>
                <b-col cols="auto" class="mb-2 mb-sm-0 d-flex align-items-center">
                  <icon-button
                    class="refresh-button mr-2"
                    role="load"
                    spin-icon
                    :loading="$store.state.dashboard.loansLoading"
                    @click="() => $store.dispatch('dashboard/loadLoans')"
                  />
                  <icon-button variant="ghost-secondary" to="/profile/loans">
                    Tous mes emprunts
                  </icon-button>
                </b-col>
              </b-row>
              <div class="loans-container">
                <b-tabs pills content-class="mt-3">
                  <b-tab
                    v-if="loans.need_approval && loans.need_approval.total > 0"
                    :title="`Nouvelles demandes ${formatLoanCount(loans.need_approval)}`"
                  >
                    <transition-group name="dashboard-list">
                      <div
                        v-for="loan in loans.need_approval.loans"
                        :key="loan.id"
                        class="dashboard-list-item dashboard__waiting-loans"
                      >
                        <loan-info-box :loan="loan" :user="user" />
                      </div>
                    </transition-group>
                  </b-tab>
                  <b-tab
                    v-if="loans.started && loans.started.total > 0"
                    :title="`En cours ${formatLoanCount(loans.started)}`"
                  >
                    <transition-group name="dashboard-list">
                      <div
                        v-for="loan in loans.started.loans"
                        :key="loan.id"
                        class="dashboard-list-item dashboard__ongoing-loans"
                      >
                        <loan-info-box :loan="loan" :user="user" :buttons="['view', 'cancel']" />
                      </div>
                    </transition-group>
                  </b-tab>
                  <b-tab
                    v-if="loans.waiting && loans.waiting.total > 0"
                    :title="`Attente d'approbation ${formatLoanCount(loans.waiting)}`"
                  >
                    <p class="dashboard__instructions">
                      La demande est envoyée! Maintenant contactez la personne propriétaire pour
                      valider votre demande.
                    </p>
                    <transition-group name="dashboard-list">
                      <div
                        v-for="loan in loans.waiting.loans"
                        :key="loan.id"
                        class="dashboard-list-item dashboard__waiting-loans"
                      >
                        <loan-info-box :loan="loan" :user="user" :buttons="['view', 'cancel']" />
                      </div>
                    </transition-group>
                  </b-tab>
                  <b-tab
                    v-if="loans.future && loans.future.total > 0"
                    :title="`À venir ${formatLoanCount(loans.future)}`"
                  >
                    <transition-group name="dashboard-list">
                      <div
                        v-for="loan in loans.future.loans"
                        :key="loan.id"
                        class="dashboard-list-item dashboard__upcoming-loans"
                      >
                        <loan-info-box
                          mode="upcoming"
                          :loan="loan"
                          :user="user"
                          :buttons="['view', 'cancel']"
                        />
                      </div>
                    </transition-group>
                  </b-tab>
                </b-tabs>
                <div class="position-relative">
                  <transition name="fadein" appear>
                    <info-box
                      v-if="hasNoLoans && $store.state.dashboard.loansLoading"
                      key="loan-skeleton"
                      skeleton
                      class="dashboard-list-item loan-info-box"
                    />
                  </transition>
                  <transition name="fadein" appear>
                    <div v-if="hasTutorial('find-vehicle')" key="find-vehicle-tutorial">
                      <tutorial-block
                        title="Empruntez un véhicule"
                        to="/search/map"
                        bg-image="/img-vehicules.png"
                        variant="light"
                      />
                    </div>
                  </transition>
                  <transition name="fadein">
                    <div
                      v-if="hasTutorial('fill-your-driving-profile')"
                      key="fill-profile-tutorial"
                    >
                      <tutorial-block
                        title="Remplissez votre dossier de conduite"
                        to="/profile/borrower"
                        bg-image="/img-voiture.png"
                        variant="dark"
                      />
                    </div>
                  </transition>
                </div>
              </div>
            </div>

            <section
              v-if="canAddLoanables || !hasNoLoanables"
              class="page__section position-relative"
            >
              <b-row>
                <b-col cols="12" sm>
                  <h2>Véhicules</h2>
                </b-col>
                <b-col cols="auto" class="mb-2 mb-sm-0 d-flex align-items-center">
                  <icon-button
                    class="refresh-button mr-2"
                    role="load"
                    spin-icon
                    :loading="$store.state.dashboard.loanablesLoading"
                    @click="() => $store.dispatch('dashboard/loadLoanables')"
                  />
                  <icon-button
                    v-if="!hasNoLoanables"
                    variant="ghost-secondary"
                    to="/profile/loanables"
                  >
                    Tous mes véhicules
                  </icon-button>
                </b-col>
              </b-row>

              <div
                class="dashboard__vehicles"
                :class="{ loading: $store.state.dashboard.loanablesLoading }"
              >
                <b-tabs pills content-class="mt-3" :value="0">
                  <b-tab
                    v-if="loanables && loanables.owned && loanables.owned.total > 0"
                    :title="`Mes véhicules ${formatLoanableCount(loanables.owned)}`"
                  >
                    <transition-group name="dashboard-list">
                      <loanable-info-box
                        v-for="loanable in loanables.owned.data"
                        :key="loanable.id"
                        class="dashboard-list-item"
                        :loanable="loanable"
                        :user="user"
                        @disabled="hideLoanable"
                      />
                    </transition-group>
                  </b-tab>
                  <b-tab
                    v-if="loanables && loanables.managed && loanables.managed.total > 0"
                    :title="`Véhicules gérés ${formatLoanableCount(loanables.managed)}`"
                  >
                    <transition-group name="dashboard-list">
                      <loanable-info-box
                        v-for="loanable in loanables.managed.data"
                        :key="loanable.id"
                        class="dashboard-list-item"
                        :loanable="loanable"
                        :buttons="['availability']"
                        :user="user"
                        @disabled="hideLoanable"
                      />
                    </transition-group>
                  </b-tab>
                  <b-tab
                    v-if="libraries && libraries.total > 0"
                    :title="`Flottes gérées ${formatLoanableCount(libraries)}`"
                  >
                    <transition-group name="dashboard-list">
                      <library-info-box
                        v-for="library in libraries.data"
                        :key="library.id"
                        class="dashboard-list-item"
                        :library="library"
                        :buttons="['availability']"
                        :user="user"
                        @disabled="hideLoanable"
                      />
                    </transition-group>
                  </b-tab>
                </b-tabs>

                <transition name="fadein">
                  <info-box
                    v-if="hasNoLoanables && $store.state.dashboard.loanablesLoading"
                    key="loanable-skeleton"
                    skeleton
                    class="dashboard-list-item skeleton loan-info-box"
                  />
                </transition>
                <transition name="fadein">
                  <div v-if="hasTutorial('add-vehicle')" key="add-vehicle-tutorial">
                    <tutorial-block
                      title="Ajoutez un véhicule"
                      to="/profile/loanables/new"
                      bg-image="/img-voiture.png"
                      variant="dark"
                    />
                  </div>
                </transition>
              </div>
            </section>
          </div>
          <!---->
        </b-col>

        <b-col tag="aside" class="page__sidebar" xl="3" lg="4">
          <!-- sidebar -->
          <b-card>
            <div>
              <dashboard-balance :user="user" />

              <hr />
            </div>

            <div>
              <dashboard-loan-history
                :past-loans="loans.completed.loans.slice(0, 3)"
                :upcoming-loans="loans.future.loans.slice(0, 3)"
                :ongoing-loans="loans.started.loans.slice(0, 3)"
                :waiting-loans="loans.waiting.loans.slice(0, 3)"
                :user="user"
              />

              <hr />
            </div>

            <dashboard-resources-list :user="user" />
          </b-card>
          <!---->
        </b-col>
      </b-row>
    </b-container>
  </layout-page>
</template>

<script>
import DashboardBalance from "@/components/Dashboard/Balance.vue";
import DashboardLoanHistory from "@/components/Dashboard/LoanHistory.vue";
import DashboardResourcesList from "@/components/Dashboard/ResourcesList.vue";
import TutorialBlock from "@/components/Dashboard/TutorialBlock.vue";
import LibraryInfoBox from "@/components/library/InfoBox.vue";
import LoanInfoBox from "@/components/Loan/InfoBox.vue";
import LoanableInfoBox from "@/components/Loanable/InfoBox.vue";
import IconButton from "@/components/shared/IconButton.vue";
import InfoBox from "@/components/shared/InfoBox.vue";
import {
  canFillDriverProfile,
  canLoanVehicle,
  canAddLoanables,
  userIsApproved,
  userIsRegistered,
} from "@/helpers/permissions/users";
import Authenticated from "@/mixins/Authenticated";
import store from "../store";

export default {
  name: "UserDashboard",
  components: {
    LibraryInfoBox,
    IconButton,
    InfoBox,
    DashboardBalance,
    DashboardLoanHistory,
    DashboardResourcesList,
    LoanInfoBox,
    LoanableInfoBox,
    TutorialBlock,
  },
  mixins: [Authenticated],
  beforeRouteEnter(to, from, next) {
    if (!store.state.user) {
      next("/login");
      return;
    }

    if (store.state.user.role === "admin") {
      next("/admin/dashboard");
      return;
    }

    if (!userIsRegistered(store.state.user)) {
      next("/register/2");
      return;
    }
    next();
  },
  data() {
    return { showNewlyAcceptedCommunity: false };
  },
  computed: {
    loans() {
      return this.$store.state.dashboard.loans ?? {};
    },
    hasNoLoans() {
      return Object.values(this.loans).filter((l) => l.total > 0).length === 0;
    },
    hasOngoingLoans() {
      let uncompletedLoans = {
        ...this.loans,
      };
      delete uncompletedLoans.completed;
      return Object.values(uncompletedLoans).filter((l) => l.total > 0).length > 0;
    },
    ownsNoLoanables() {
      return !this.loanables.owned || this.loanables.owned.total === 0;
    },
    coownsNoLoanables() {
      return !this.loanables.managed || this.loanables.managed.total === 0;
    },
    hasNoLoanables() {
      return this.ownsNoLoanables && this.coownsNoLoanables;
    },
    loanables() {
      return this.$store.state.dashboard.loanables ?? [];
    },
    libraries() {
      return this.$store.state.dashboard.libraries ?? [];
    },
    borrowerProfileNotSubmitted() {
      return (
        !this.user.borrower || (!this.user.borrower.submitted_at && !this.user.borrower.approved_at)
      );
    },
    missingProofCommunityUsers() {
      return this.user.user_communities
        .filter((cu) => cu.status === "new")
        .filter((cu) => {
          if (cu.community.requires_residency_proof && !this.user.residency_proof.length) {
            return true;
          }
          if (cu.community.requires_identity_proof && !this.user.identity_proof.length) {
            return true;
          }
          return cu.community.requires_custom_proof && !cu.custom_proof.length;
        });
    },
    missingProofs() {
      let missingProofs = this.user.user_communities
        // Only consider communities where user is not yet approved or suspended
        .filter((cu) => cu.status === "new")
        .reduce(
          (acc, communityUser) => {
            if (communityUser.community.requires_residency_proof) {
              acc.residency = true;
            }
            if (communityUser.community.requires_identity_proof) {
              acc.identity = true;
            }
            if (
              communityUser.community.requires_custom_proof &&
              communityUser.custom_proof.length === 0
            ) {
              acc.custom = true;
            }

            return acc;
          },
          {
            identity: false,
            residency: false,
            custom: false,
          }
        );

      if (this.user.identity_proof.length > 0) {
        missingProofs.identity = false;
      }
      if (this.user.residency_proof.length > 0) {
        missingProofs.residency = false;
      }

      return missingProofs;
    },
    shouldShowAddProofText() {
      if (!this.hasCommunity) {
        return false;
      }

      if (this.missingProofCommunityUsers.length === 0) {
        return false;
      }

      if (this.missingProofCommunityUsers.some((cu) => cu.community.type === "private")) {
        return true;
      }

      // Do not show if missing proof only for borough, but is approved in a private community
      return this.user.user_communities.every((cu) => cu.community.type !== "private");
    },
    provideProofText() {
      let missingProofCount = Object.values(this.missingProofs).filter((p) => p).length;
      if (missingProofCount > 1 || this.missingProofs.custom) {
        return "Veuillez fournir les preuves manquantes";
      }

      if (this.missingProofs.identity) {
        return "Veuillez fournir une preuve d'identité";
      }

      if (this.missingProofs.residency) {
        return "Veuillez fournir une preuve de résidence";
      }

      return "Veuillez fournir une preuve de résidence";
    },
    provideProofSubtitle() {
      if (this.missingProofCommunityUsers.length === 1 && this.user.user_communities.length > 1) {
        return "Pour " + this.missingProofCommunityUsers[0].community.name;
      }
      return null;
    },
    provideProofLink() {
      if (this.missingProofCommunityUsers.length === 1) {
        return "/profile/communities/" + this.missingProofCommunityUsers[0].community.id;
      }
      return "/profile/communities";
    },
    hasCommunity() {
      return this.user && this.user.user_communities && this.user.user_communities.length > 0;
    },
    userInPrivateCommunityOnly() {
      if (!this.hasCommunity) {
        return false;
      }

      return this.onboardedCommunities.every((c) => c.community.type === "private");
    },
    userHasPrivateCommunity() {
      return this.user.user_communities.some((c) => c.community.type === "private");
    },
    communitiesToDisplay() {
      // If has any private community, display only private communities and approved communities
      if (this.userHasPrivateCommunity) {
        return this.user.user_communities
          .filter((c) => c.approved_at || c.community.type === "private")
          .map((c) => c.community);
      }
      return this.user.user_communities.map((c) => c.community);
    },
    neighborCount() {
      if (this.userHasPrivateCommunity) {
        return this.communitiesToDisplay
          .map((u) => u.approved_users_count)
          .reduce((acc, c) => acc + c, 0);
      }
      return this.user.neighbor_count;
    },
    waitingForProfileApproval() {
      return this.hasSubmittedProofs && !this.userIsApproved;
    },
    hasSubmittedProofs() {
      return this.onboardedCommunities.length > 0;
    },
    waitingForApprovalText() {
      // if waiting for approval in private community
      if (this.userInPrivateCommunityOnly) {
        return "Les administrateur-trices de votre communauté vérifient vos preuvent et valident votre compte. Vous recevrez un courriel de confirmation lorsque ce sera fait et aurez alors accès à toutes les fonctionnalités de LocoMotion!";
      }
      return "LocoMotion s'assure que vos voisin-e-s soient bien... vos voisin-e-s! C'est pourquoi un membre du comité va vérifier votre preuve de résidence et valider votre compte. Vous recevrez un courriel de confirmation et aurez alors accès à toutes les fonctionnalités de LocoMotion!";
    },
    onboardedCommunities() {
      return (this.user?.user_communities || []).filter(
        (cu) => cu.status === "approved" || !["partial", "absent"].includes(cu.proof_state)
      );
    },
    canAddLoanables() {
      return canAddLoanables(this.user);
    },
    canLoanVehicle() {
      return canLoanVehicle(this.user);
    },
    canFillDriverProfile() {
      return canFillDriverProfile(this.user);
    },
    userIsApproved() {
      return userIsApproved(this.user);
    },
  },
  activated() {
    // Refresh loans and loanables whenever the dashboard is re-activated if they're stale.
    if (
      this.$store.state.dashboard.loansLastLoadTime === null ||
      this.$dayjs().diff(this.$store.state.dashboard.loansLastLoadTime, "minutes") > 5
    ) {
      this.$store.dispatch("dashboard/loadLoans");
    }

    if (
      this.$store.state.dashboard.loanablesLastLoadTime === null ||
      this.$dayjs().diff(this.$store.state.dashboard.loanablesLastLoadTime, "minutes") > 5
    ) {
      this.$store.dispatch("dashboard/loadLoanables");
    }
  },
  mounted() {
    if (this.$store.state.invitation.newlyAcceptedCommunityId) {
      setTimeout(() => (this.showNewlyAcceptedCommunity = true), 200);
      // hide the new community notification after 5s
      setTimeout(() => {
        this.showNewlyAcceptedCommunity = false;
      }, 5000);
      // Remove from store after the animation has ended
      setTimeout(() => this.$store.commit("invitation/setNewlyAcceptedCommunityId", null), 6000);
    }
  },
  methods: {
    hasTutorial(name) {
      switch (name) {
        case "add-vehicle":
          return (
            this.$store.state.dashboard.loanablesLoaded &&
            !this.$store.state.dashboard.loanablesLoading &&
            this.hasNoLoanables &&
            this.canAddLoanables
          );
        case "find-vehicle":
          return (
            this.$store.state.dashboard.loansLoaded &&
            !this.$store.state.dashboard.loansLoading &&
            this.canLoanVehicle &&
            !this.hasOngoingLoans
          );
        case "fill-your-driving-profile":
          return this.hasCommunity && this.borrowerProfileNotSubmitted && this.canFillDriverProfile;
        case "upload-proofs":
          return this.shouldShowAddProofText;
        default:
          return false;
      }
    },
    formatLoanCount(loanType) {
      if (loanType.total > loanType.loans.length) {
        return `(${loanType.loans.length} de ${loanType.total})`;
      }
      return `(${loanType.loans.length})`;
    },
    formatLoanableCount(loanableType) {
      if (!loanableType) {
        return "";
      }
      if (loanableType.total > loanableType.data.length) {
        return `(${loanableType.data.length} de ${loanableType.total})`;
      }
      return `(${loanableType.data.length})`;
    },
    async hideLoanable(id) {
      this.$store.commit(
        "dashboard/setLoanables",
        this.loanables.filter((l) => l.id !== id)
      );
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.dashboard {
  .page__section {
    &__main {
      padding-top: 45px;
      padding-bottom: 45px;
    }

    .jumbotron {
      padding: 2rem;
      margin-top: 2rem;
    }

    .no-communities-jumbotron {
      .btn {
        margin-left: 0;
      }
    }
  }

  .page__sidebar > .card {
    box-shadow: $small-shadow;
  }

  .skeleton-button {
    opacity: 0.5;
  }

  .dashboard-list-item {
    transition: all 0.8s;
    margin-bottom: 1rem;

    &.skeleton {
      transition: opacity 0.8s;
    }
  }

  .dashboard-list-leave-to,
  .dashboard-list-enter {
    opacity: 0;
  }
  .dashboard-list-leave-active {
    position: absolute;
    width: 100%;
  }

  .fadein-leave-to,
  .fadein-enter {
    opacity: 0;
  }

  .fadein-enter,
  .fadein-enter-active {
    transition-duration: 0.5s;
  }

  .fadein-leave {
    opacity: 0;
    transition-duration: 0s;
  }

  .fadein-leave-active {
    position: absolute;
    width: 100%;
    transition-duration: 0s;
  }

  &__vehicles {
    .loanable-info-box {
      margin-bottom: 1.25rem;
    }
  }

  .loan-info-box {
    margin-bottom: 1.25rem;
  }

  h1 {
    font-weight: 700;

    @include media-breakpoint-down(md) {
      line-height: $h2-line-height;
      font-size: $h2-font-size;
    }
  }

  h2 {
    font-weight: 700;

    @include media-breakpoint-down(md) {
      line-height: $h3-line-height;
      font-size: $h3-font-size;
    }
  }

  .dashboard-h3 {
    margin-bottom: 1rem;
    line-height: $h3-line-height;
    font-size: $h3-font-size;

    @include media-breakpoint-down(md) {
      line-height: $h4-line-height;
      font-size: $h4-font-size;
    }
  }

  .search_button {
    background: #fff;
    color: #7a7a7a;
    border: 1px solid #e5e5e5;
    padding: 16px, 16px, 16px, 45px;
    margin: 0;
    width: 300px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &:hover {
      background: #fff;
      color: #7a7a7a;
    }
  }
}

.dashboard--margin-top {
  margin-top: 25px;
}

.dashboard--margin-bottom {
  margin-bottom: 25px;
}
</style>
