<script>
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import LoanPageEditableBox from "@/components/Loan/LoanPageEditableBox.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { date, durationInHours, formatDate, numberOnlyPhone, time } from "@/helpers/filters";
import { getZonedTimes } from "@/helpers/loanTimezones";
import { getOwner, isCoownerOrOwner } from "@/helpers/permissions/loanables";
import {
  canAcceptExtension,
  canCancelExtension,
  canChangeLoanTime,
} from "@/helpers/permissions/loans";
import { put } from "@/requests/server";
import dayjs from "dayjs";
import is from "vue2-datepicker/locale/es/is";
import { sections } from "./FullLoanSections";

export default {
  name: "LoanDatesBox",
  components: { FormsValidatedInput, IconButton, LoanPageEditableBox },
  props: {
    loan: {
      type: Object,
      required: true,
    },
    available: {
      type: Boolean,
      default: true,
    },
    isEstimating: {
      type: Boolean,
      default: false,
    },
    nextLoan: {
      type: Object,
      default: null,
    },
    maxDurationInMinutes: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      departureAt: this.loan.departure_at,
      durationInMinutes: this.loan.extension_duration_in_minutes
        ? this.loan.extension_duration_in_minutes
        : this.loan.duration_in_minutes,
      isActingOnExtension: false,
      sections,
    };
  },
  computed: {
    is() {
      return is;
    },
    user() {
      return this.$store.state.user;
    },
    isEditable() {
      return (
        this.loan.status === "requested" ||
        this.loan.status === "accepted" ||
        this.loan.status === "confirmed"
      );
    },
    isExtensible() {
      return (
        this.loan.status === "ongoing" ||
        this.loan.status === "ended" ||
        this.loan.status === "validated"
      );
    },
    zonedTimes() {
      return getZonedTimes(this.loan);
    },
    returnAt() {
      return dayjs(this.departureAt).add(this.durationInMinutes, "minute").toISOString();
    },
    willNeedReapproval() {
      return (
        this.isTimeChanged &&
        (this.loan.status === "accepted" || this.loan.status === "confirmed") &&
        !(this.loan.is_self_service || this.isCoownerOrOwner)
      );
    },
    isTimeChanged() {
      return (
        (this.isExtensible && this.durationInMinutes !== this.loan.extension_duration_in_minutes) ||
        (!this.isExtensible && this.durationInMinutes !== this.loan.duration_in_minutes) ||
        this.departureAt !== this.loan.departure_at
      );
    },
    isCancellingExtension() {
      return (
        this.isExtensible &&
        this.loan.extension_duration_in_minutes &&
        this.durationInMinutes === this.loan.duration_in_minutes
      );
    },
    invalidDuration() {
      return this.durationInMinutes < 15;
    },
    extensionReturnAt() {
      if (!this.loan.extension_duration_in_minutes) {
        return;
      }
      return dayjs(this.loan.departure_at).add(this.loan.extension_duration_in_minutes, "minutes");
    },
    isCoownerOrOwner() {
      return isCoownerOrOwner(this.user, this.loan.loanable);
    },
    canAcceptExtension() {
      return canAcceptExtension(this.user, this.loan);
    },
    canCancelExtension() {
      return canCancelExtension(this.user, this.loan);
    },
    canChangeLoanTime() {
      return canChangeLoanTime(this.user, this.loan);
    },
  },
  methods: {
    durationInHours,
    getOwner,
    numberOnlyPhone,
    formatDate,
    time,
    date,
    updateLoanDeparture(datetime) {
      if (this.invalidDuration) {
        // If duration is negative, we allow user to move each date independently
        this.durationInMinutes = dayjs(this.returnAt)
          .startOf("minute")
          .diff(dayjs(datetime).startOf("minute"), "minute");
      }
      this.departureAt = datetime;
      this.estimate();
    },
    updateLoanDuration(datetime) {
      this.durationInMinutes = dayjs(datetime)
        .startOf("minute")
        .diff(dayjs(this.departureAt).startOf("minute"), "minute");
      this.estimate();
    },
    addToDuration(amount) {
      this.durationInMinutes += amount;
      this.estimate();
    },
    estimate() {
      if (!this.invalidDuration) {
        this.$emit("estimate", {
          departureAt: this.departureAt,
          durationInMinutes: this.durationInMinutes,
        });
      }
    },
    emitUpdatedLoan(loan) {
      this.$emit("input", loan);
      // Reset estimation, since invoices is updated in the new the updated loan
      this.$emit("estimate");
    },
    async acceptExtension() {
      this.isActingOnExtension = true;
      const { data } = await put(
        `loans/${this.loan.id}/extension/accept`,
        {},
        {
          cleanupCallback: () => (this.isActingOnExtension = false),
          notifications: { action: "approbation de la prolongation" },
        }
      );

      this.emitUpdatedLoan(data);
    },
    async refuseExtension() {
      this.isActingOnExtension = true;
      const { data } = await put(
        `loans/${this.loan.id}/extension/reject`,
        {},
        {
          cleanupCallback: () => (this.isActingOnExtension = false),
          notifications: { action: "refus de la prolongation" },
        }
      );

      this.emitUpdatedLoan(data);
    },
    async cancelExtension() {
      this.isActingOnExtension = true;
      const { data } = await put(
        `loans/${this.loan.id}/extension/cancel`,
        {},
        {
          cleanupCallback: () => (this.isActingOnExtension = false),
          notifications: { action: "annulation de la prolongation" },
        }
      );

      this.emitUpdatedLoan(data);
    },
    async updateLoan(onSuccess) {
      // either edit or request extension
      if (this.isExtensible) {
        const { data } = await put(
          `loans/${this.loan.id}/extension`,
          {
            extension_duration_in_minutes: this.durationInMinutes,
          },
          {
            notifications: { action: "demande de prolongation" },
          }
        );

        this.emitUpdatedLoan(data);
        onSuccess();

        return;
      }

      if (this.isEditable) {
        const { data } = await put(
          `loans/${this.loan.id}/dates`,
          {
            departure_at: this.departureAt,
            duration_in_minutes: this.durationInMinutes,
          },
          {
            notifications: { action: "mise à jour des dates de l'emprunt" },
          }
        );

        this.emitUpdatedLoan(data);
        onSuccess();
      }
    },
    reset() {
      this.departureAt = this.loan.departure_at;
      this.durationInMinutes = this.loan.extension_duration_in_minutes
        ? this.loan.extension_duration_in_minutes
        : this.loan.duration_in_minutes;
      this.$emit("estimate", {});
    },
    disabledBeforeToday(date) {
      return dayjs().isAfter(dayjs(date).tz(this.loan.loanable.timezone, true), "day");
    },
    disabledBeforeNow(date) {
      return dayjs().isAfter(dayjs(date).tz(this.loan.loanable.timezone, true), "minutes");
    },
    disabledBeforeDeparture(date) {
      return dayjs(date)
        .tz(this.loan.loanable.timezone, true)
        .isSameOrBefore(this.departureAt, "minute");
    },
    disabledBeforeDepartureDay(date) {
      return dayjs(date).tz(this.loan.loanable.timezone, true).isBefore(this.departureAt, "day");
    },
    disabledBeforeCurrentReturn(date) {
      return dayjs(date)
        .tz(this.loan.loanable.timezone, true)
        .isSameOrBefore(this.loan.actual_return_at, "minute");
    },
    disabledBeforeCurrentReturnDay(date) {
      return dayjs(date)
        .tz(this.loan.loanable.timezone, true)
        .isBefore(this.loan.actual_return_at, "day");
    },
    open() {
      if (this.isEditable || this.isExtensible) {
        this.$refs.editableBox.setIsEditing(true);
      }
    },
  },
};
</script>

<template>
  <loan-page-editable-box ref="editableBox" label="Dates">
    <template #default="{ edit }">
      <div class="d-flex flex-column w-100">
        <div class="loan-dates-container">
          <div class="loan-dates">
            <div class="flex-grow-1">
              <div class="loan-dates-label">Départ</div>
              <div class="date-from">
                <div>
                  {{ date(zonedTimes.departureAt) }}
                </div>
                <div class="hour tabular-nums">
                  {{ time(zonedTimes.departureAt) }}
                </div>
              </div>
            </div>
            <div class="flex-grow-1">
              <div class="loan-dates-label">Retour</div>

              <div class="date-to">
                <div>
                  {{ date(zonedTimes.returnAt) }}
                </div>
                <div class="hour tabular-nums">
                  {{ time(zonedTimes.returnAt) }}
                </div>
              </div>
            </div>
            <div>
              <div class="loan-dates-label">Durée</div>
              <div v-b-tooltip="durationInHours(loan.duration_in_minutes)" class="dates-to hour">
                {{ loan.duration_in_minutes | approximateDuration | capitalize }}
              </div>
            </div>
          </div>
          <icon-button
            v-if="canChangeLoanTime && (isEditable || isExtensible)"
            :disabled="isActingOnExtension"
            size="sm"
            role="edit"
            :icon="isExtensible ? 'clock-history' : null"
            square
            xs="block"
            lg="block"
            @click="edit"
          >
            {{ isExtensible ? "Prolonger" : "Modifier" }}
          </icon-button>
        </div>
        <div v-if="zonedTimes.differsFromCurrentTimezone" class="small text-muted mt-1">
          Heures dans le fuseau horaire du véhicule: {{ zonedTimes.timezoneAbbrev }}
        </div>
        <div v-if="loan.extension_duration_in_minutes">
          <hr class="my-3" />
          <div class="prolongation-section">
            <div class="flex-grow-1">
              <b-icon v-if="loan.extension_duration_in_minutes" class="mr-2" icon="clock-history">
              </b-icon>
              Prolongation demandée jusqu'au
              <span class="extension-time">{{
                formatDate(extensionReturnAt, "D MMM. YYYY HH:mm")
              }}</span>
            </div>
            <template v-if="canAcceptExtension">
              <icon-button
                size="sm"
                role="accept"
                :onclick="acceptExtension"
                :disabled="isActingOnExtension"
                >Accepter</icon-button
              >
              <icon-button
                size="sm"
                role="reject"
                :onclick="refuseExtension"
                :disabled="isActingOnExtension"
                >Refuser</icon-button
              >
            </template>
            <template v-else-if="canCancelExtension">
              <icon-button
                size="sm"
                role="cancel"
                :onclick="cancelExtension"
                :disabled="isActingOnExtension"
                >Annuler la demande</icon-button
              ></template
            >
          </div>
        </div>
      </div>
    </template>
    <template #form="{ close }">
      <validation-observer ref="observer" v-slot="{ passes }">
        <b-form>
          <b-row>
            <b-col cols="12" lg="6">
              <forms-validated-input
                class="date-input"
                name="departure_at"
                :disabled="!isEditable"
                :label="$t('loans.fields.departure_at') | capitalize"
                type="datetime"
                :disabled-dates-fct="disabledBeforeToday"
                :disabled-times-fct="disabledBeforeNow"
                :value="departureAt"
                :timezone="loan.loanable.timezone"
                :clearable="false"
                :description="
                  zonedTimes.differsFromCurrentTimezone
                    ? `Heure dans le fuseau horaire du véhicule: ${zonedTimes.timezoneAbbrev}`
                    : null
                "
                @input="updateLoanDeparture"
              />
            </b-col>
            <b-col cols="12" lg="6">
              <forms-validated-input
                class="date-input"
                :value="returnAt"
                name="return_at"
                :disabled="!isEditable && !isExtensible"
                :label="$t('loans.fields.return_at') | capitalize"
                type="datetime"
                :clearable="false"
                :disabled-dates-fct="
                  isEditable ? disabledBeforeDepartureDay : disabledBeforeCurrentReturnDay
                "
                :disabled-times-fct="
                  isEditable ? disabledBeforeDeparture : disabledBeforeCurrentReturn
                "
                :timezone="loan.loanable.timezone"
                @input="updateLoanDuration"
              >
                <template #footer>
                  <button class="mx-btn mx-btn-text" @click="() => addToDuration(15)">
                    +15 min.
                  </button>
                  <button class="mx-btn mx-btn-text" @click="() => addToDuration(60)">
                    +1 heure
                  </button>
                  <button class="mx-btn mx-btn-text" @click="() => addToDuration(60 * 24)">
                    +1 jour
                  </button>
                </template>
              </forms-validated-input>
            </b-col>
          </b-row>

          <b-alert v-if="invalidDuration" show variant="danger">
            La durée de l'emprunt doit être supérieure ou égale à 15 minutes.
          </b-alert>

          <b-alert v-if="willNeedReapproval" show variant="warning" class="mb-2">
            <div class="font-weight-bold">Modification des heures de l'emprunt</div>
            Une personne responsable devra réapprouver cet emprunt.
          </b-alert>

          <template v-if="isExtensible && !available">
            <b-alert v-if="nextLoan" variant="warning" show class="mb-2">
              La prochaine réservation pour ce véhicule est le
              <span class="font-weight-bold"
                >{{ nextLoan.departure_at | date }} à {{ nextLoan.departure_at | time }}</span
              >. Contactez la prochaine personne emprunteuse directement pour gérer votre retard.
              <div class="mt-2">
                <dl class="mb-0">
                  <dt>{{ nextLoan.borrower_user.full_name }}</dt>
                  <dd class="mb-0">
                    <b-link :href="`tel:${numberOnlyPhone(nextLoan.borrower_user.phone)}`">
                      {{ nextLoan.borrower_user.phone | phone }}
                    </b-link>
                  </dd>
                </dl>
              </div>
            </b-alert>
            <b-alert v-else variant="warning" show class="mb-2">
              Le véhicule n'est pas disponible pour la prolongation sur cette période.
              <span v-if="!isCoownerOrOwner">
                Si vous arriverez en retard, contactez directement
                <icon-button
                  variant="ghost-secondary"
                  size="inline"
                  @click="$emit('jump', sections.contacts)"
                >
                  {{ getOwner(loan.loanable).full_name }} </icon-button
                >.</span
              >
            </b-alert>
          </template>
          <div
            v-if="
              (isEditable || isExtensible) &&
              !available &&
              maxDurationInMinutes !== null &&
              durationInMinutes > maxDurationInMinutes
            "
            class="text-muted small mb-2"
          >
            La durée maximale pour un emprunt de ce véhicule est de
            {{ maxDurationInMinutes | duration }}.
          </div>
          <div v-if="isEditable || isExtensible" class="button-list align-items-center">
            <icon-button v-if="!available" variant="primary" disabled type="submit">
              Indisponible
            </icon-button>
            <icon-button
              v-else-if="isCancellingExtension"
              variant="outline-primary"
              :onclick="cancelExtension"
            >
              Annuler la prolongation
            </icon-button>
            <icon-button
              v-else
              variant="primary"
              :onclick="async () => passes(() => updateLoan(close))"
              :disabled="invalidDuration || !isTimeChanged || isEstimating"
            >
              {{
                isExtensible
                  ? loan.is_self_service || canAcceptExtension
                    ? "Prolonger"
                    : "Demander la prolongation"
                  : "Modifier"
              }}
            </icon-button>
            <icon-button
              role="cancel"
              :onclick="
                () => {
                  close();
                  reset();
                }
              "
              >Annuler</icon-button
            >
            <b-spinner
              v-if="isEstimating && !isCancellingExtension"
              class="estimation-spinner"
              small
            />
          </div>
        </b-form>
      </validation-observer>
    </template>
  </loan-page-editable-box>
</template>

<style scoped lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.extension-time {
  color: $primary;
  white-space: nowrap;
}

.loanable-icon {
  fill: $primary;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  left: -0.75rem;
  transform: translateY(1rem) scaleX(-1) rotate(70deg);
}

.prolongation-section {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
}

.date-input {
  width: 15rem;
  display: inline-block;
  max-width: 100%;
}

.estimation-spinner {
  width: 20px;
  height: 20px;
  border-width: 2px;
  display: block;
  border-color: $grey;
  border-right-color: transparent;
}
.date-input {
  width: 100%;
}

.loan-dates-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.loan-dates {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  grid-template-columns: auto 1fr;
  padding: 0.25rem 0;

  .loan-dates-label {
    margin-bottom: 2px;
  }

  .date-from,
  .date-to {
    white-space: nowrap;
    font-size: 1.125rem;
    font-weight: 300;
  }

  .hour {
    color: $primary;
    font-weight: 600;
  }

  .date-from {
    grid-area: from;
  }

  .date-to {
    grid-area: to;
    text-align: left;
  }

  .date-from,
  .date-to {
    display: flex;
    gap: 0.5rem;
  }

  .hour {
    margin-top: 0;
  }
}
</style>
