<template>
  <loan-status-alert-container owner-action-required :loan="loan">
    <template #borrower>
      <p>
        La demande a bien été envoyée par courriel aux personnes responsables. N'hésitez pas à les
        <icon-button
          variant="ghost-secondary"
          size="inline"
          @click="$emit('jump', sections.contacts)"
          >contacter</icon-button
        >
        directement.
        <span v-if="loan.community.type === 'borough'">
          Il est plus vraisemblable qu'ils ou elles acceptent le prêt si vous faites connaissance en
          premier!
        </span>
      </p>
    </template>

    <template #owner>
      <p>
        <icon-button
          size="inline"
          variant="ghost-secondary"
          :onclick="() => $emit('jump', sections.contacts)"
          >{{ loan.borrower_user.name }}</icon-button
        >
        veut emprunter {{ loan.loanable.name }}.
      </p>
      <p v-if="canAcceptLoan">
        <template v-if="shownAsContact">
          Vos coordonnées sont visibles à l'emprunteur-se, mais n'hésitez pas à le-a contacter en
          premier pour clarifier les détails de l'emprunt.
        </template>
        <template v-else>
          Vos coordonnées ne sont pas partagées à l'emprunteur-se, mais n'hésitez pas à le-a
          contacter pour clarifier les détails de l'emprunt.
        </template>
      </p>

      <div class="mt-3 button-list">
        <markdown-editor
          id="message_for_borrower"
          v-model="comment"
          name="message_for_borrower"
          :placeholder="`Message pour ${loan.borrower_user.name} (optionnel)`"
        >
        </markdown-editor>

        <icon-button
          v-if="canAcceptLoan"
          size="sm"
          variant="success"
          icon="check-circle"
          :onclick="acceptLoan"
          :disabled="acting"
        >
          Accepter
        </icon-button>

        <icon-button
          v-if="canRejectLoan"
          size="sm"
          icon="x-octagon"
          variant="outline-danger"
          :disabled="acting"
          :onclick="refuseLoan"
        >
          Refuser
        </icon-button>
      </div>
    </template>

    <template #admin>
      <p>Une personne responsable du véhicule doit approuver cet emprunt.</p>
    </template>
  </loan-status-alert-container>
</template>

<script>
import LoanStatusAlertContainer from "@/components/Loan/Status/Alerts/LoanStatusAlertContainer.vue";
import IconButton from "@/components/shared/IconButton.vue";
import MarkdownEditor from "@/components/shared/MarkdownEditor.vue";
import { canAcceptLoan, canRejectLoan, isBorrower } from "@/helpers/permissions/loans";
import { sections } from "@/components/Loan/FullLoanSections";
import { acceptLoan, rejectLoan } from "@/requests/loanRequests";

export default {
  name: "LoanRequested",
  components: {
    MarkdownEditor,
    LoanStatusAlertContainer,
    IconButton,
  },
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      comment: null,
      acting: false,
      sections,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isBorrower() {
      return isBorrower(this.user, this.loan);
    },
    canAcceptLoan() {
      return canAcceptLoan(this.user, this.loan);
    },
    canRejectLoan() {
      return canRejectLoan(this.user, this.loan);
    },
    shownAsContact() {
      return this.loan.loanable.merged_user_roles.filter((r) => r.user_id === this.user.id)
        ?.show_as_contact;
    },
  },
  methods: {
    async acceptLoan() {
      this.acting = true;
      try {
        const response = await acceptLoan(this.loan, {
          comment: this.comment,
        });
        if (response && response.data) {
          this.$emit("input", response.data);
        }
      } finally {
        this.acting = false;
      }
    },
    async refuseLoan() {
      this.acting = true;

      try {
        const response = await rejectLoan(this.loan.id, {
          comment: this.comment,
        });
        if (response && response.data) {
          this.$emit("input", response.data);
        }
      } finally {
        this.acting = false;
      }
    },
  },
};
</script>

<style lang="scss"></style>
