<template>
  <div v-if="routeDataLoaded">
    <template v-if="loanable">
      <vue-headful :title="fullTitle" />

      <b-row class="mb-4">
        <b-col>
          <h1 v-if="loanable.name">{{ loanable.name }}</h1>
          <h1 v-else>
            <em>{{ $tc("loanables.loanable", 1) | capitalize }}</em>
          </h1>
        </b-col>
      </b-row>
    </template>

    <b-card v-if="loanable && !loanable.id && !loanableOwnerUser" class="bordered-card">
      <div class="owner-user-selection">
        <forms-validated-input
          class="flex-grow-1"
          type="relation"
          name="owner_user_id"
          :query="ownerUserQuery"
          :value="loanable.owner_user.id"
          :object-value="loanableOwnerUser"
          :rules="{ required: true }"
          label="Propriétaire"
          mode="eager"
          :disabled="loadingOwnerUser"
          @relation="setLoanableOwnerUser"
        />
        <b-spinner v-if="loadingOwnerUser" />
      </div>
    </b-card>

    <loanable-form v-show="loanable && loanableOwnerUser" :id="id" />
  </div>
  <layout-loading v-else />
</template>

<script>
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import LoanableForm from "@/components/Loanable/LoanableForm.vue";

import { capitalize } from "@/helpers/filters";
import { getOwner } from "@/helpers/permissions/loanables";

import DataRouteGuards from "@/mixins/DataRouteGuards";
import { get } from "@/requests/server";

export default {
  name: "AdminLoanable",
  components: {
    LoanableForm,
    FormsValidatedInput,
  },
  mixins: [DataRouteGuards],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingOwnerUser: false,
      ownerUserQuery: {
        slug: "users",
        value: "id",
        text: "full_name",
        details: "email",
      },
    };
  },
  computed: {
    loanable() {
      return this.$store.state.loanables.item;
    },
    loanableOwnerUser() {
      // If loanable is not created yet, owner user can be in the loanable.owner_user field.
      if (!this.loanable.id && this.loanable.owner_user.id) {
        return this.loanable.owner_user;
      }

      return getOwner(this.loanable);
    },
    fullTitle() {
      const parts = [
        "LocoMotion",
        capitalize(this.$i18n.t("titles.admin")),
        capitalize(this.$i18n.tc("loanables.loanable", 2)),
      ];

      if (this.pageTitle) {
        parts.push(this.pageTitle);
      }

      return parts.reverse().join(" | ");
    },
    pageTitle() {
      return this.loanable.name || capitalize(this.$i18n.tc("loanables.loanable", 1));
    },
  },
  methods: {
    async setLoanableOwnerUser(user) {
      this.loadingOwnerUser = true;
      // fetch full user with available_loanable_types and communities
      let { data } = await get(`/users/${user.id}?relations=user_communities.communities`, {
        notifications: { action: "chargement du propriétaire" },
        cleanupCallback: () => (this.loadingOwnerUser = false),
      });

      this.$store.commit("loanables/patchItem", {
        owner_user: data,
      });
    },
  },
};
</script>

<style lang="scss">
.owner-user-selection {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-end;

  .form-group {
    margin-bottom: 0;
  }

  .spinner-border {
    height: 1.5rem;
    width: 1.5rem;
    border-width: 3px;
    color: $grey;
    margin-bottom: 0.4rem;
  }
}
</style>
