<script>
import ConditionalContext from "@/components/shared/ConditionalContext.vue";
import IconButton from "@/components/shared/IconButton.vue";
import CustomProofInput from "@/components/Community/CustomProofInput.vue";
import IdentityProofInput from "@/components/User/IdentityProofInput.vue";
import ResidencyProofInput from "@/components/User/ResidencyProofInput.vue";
import locales from "@/locales";
import { put } from "@/requests/server";

export default {
  name: "CommunityProofForm",
  components: {
    ConditionalContext,
    IconButton,
    CustomProofInput,
    ResidencyProofInput,
    IdentityProofInput,
  },
  props: {
    canSkip: {
      type: Boolean,
      required: false,
      default: false,
    },
    user: {
      type: Object,
      required: false,
      default: () => {},
    },
    communityUser: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      proofs: {
        identity_proof: this.user.identity_proof,
        residency_proof: this.user.residency_proof,
        custom_proof: this.communityUser.custom_proof,
      },
    };
  },
  i18n: {
    messages: {
      fr: {
        ...locales.fr.communities,
        ...locales.fr.forms,
      },
    },
  },
  methods: {
    async submit() {
      this.loading = true;

      await put(`/communityUsers/${this.communityUser.id}/proofs`, this.proofs, {
        notifications: {
          action: "mise à jour des preuves",
        },
        cleanupCallback: () => (this.loading = false),
      });

      this.$emit("submitted", this.proofs);
    },
  },
};
</script>

<template>
  <validation-observer ref="observer" v-slot="{ passes, changed }" tag="div">
    <b-form :novalidate="true" class="user-proof-form__form" @submit.stop.prevent="passes(submit)">
      <p v-if="communityUser.community.type == 'borough'">
        Pour favoriser un partage sécuritaire et protéger vos ressources collectives, votre comité
        veut s’assurer que vous êtes bel et bien une vraie personne habitant dans le quartier. Merci
        de fournir les preuves demandées.
      </p>
      <p v-else>
        Pour favoriser un partage sécuritaire et protéger vos ressources collectives, votre
        communauté veut s’assurer que vous êtes bel et bien une vraie personne répondant à ses
        conditions d'admission. Merci de fournir les preuves demandées.
      </p>

      <conditional-context
        v-if="communityUser.community.requires_identity_proof"
        show
        label="Preuve d'identité"
        large
        :inset="false"
        background="white"
        class="proof-input-container"
      >
        <identity-proof-input
          v-model="proofs.identity_proof"
          class="mb-3"
          :loading="loading"
          :community-user="communityUser"
        />
      </conditional-context>
      <conditional-context
        v-if="communityUser.community.requires_residency_proof"
        show
        label="Preuve d'adresse"
        large
        :inset="false"
        background="white"
        class="proof-input-container"
      >
        <residency-proof-input
          v-model="proofs.residency_proof"
          :duplicate-drivers-license="communityUser.community.requires_identity_proof"
          class="mb-3"
          :loading="loading"
          :community-user="communityUser"
        />
      </conditional-context>
      <conditional-context
        v-if="communityUser.community.requires_custom_proof"
        show
        :label="communityUser.community.custom_proof_name"
        large
        :inset="false"
        background="white"
        class="proof-input-container"
      >
        <custom-proof-input
          v-model="proofs.custom_proof"
          class="mb-3"
          :loading="loading"
          :community-user="communityUser"
        />
      </conditional-context>

      <p class="mt-4">
        Une fois tous vos fichiers joints, il ne reste plus qu’à les envoyer! Maintenant, faisons
        preuve de patience. Ce sont de vraies personnes qui valident votre compte. 🙂
      </p>

      <div class="community-proof-form__buttons">
        <icon-button :loading="loading" :disabled="loading || !changed" role="send" type="submit">
          Envoyer
        </icon-button>

        <!-- Submitting proofs can be skipped at registration -->
        <icon-button v-if="canSkip" role="cancel" @click="$emit('skip')"> Plus tard</icon-button>
      </div>

      <div v-if="communityUser.community.requires_residency_proof" class="mt-3">
        <small>
          * Si votre situation ne vous permet pas de démontrer que vous habitez le quartier au moyen
          de la méthode proposée, contactez l’Équipe LocoMotion (info@locomotion.app) pour savoir
          comment vous inscrire.
        </small>
      </div>
    </b-form>
  </validation-observer>
</template>

<style lang="scss" scoped>
.community-proof-form__buttons {
  justify-content: center;
  display: flex;
  gap: 0.5rem;
}
.proof-input-container {
  margin-top: 2rem;
}
</style>
