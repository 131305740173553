<script>
export default {
  name: "AvailabilityStatus",
  props: {
    loanable: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasBlockingIncident() {
      return this.loanable.active_incidents?.filter((i) => i.is_blocking).length > 0;
    },
    hasIncident() {
      return this.loanable.active_incidents?.length > 0;
    },
  },
};
</script>

<template>
  <div class="status-list">
    <b-badge
      v-if="loanable.availability_status === 'no_approved_communities'"
      v-b-tooltip="`Le-a propriétaire n\'est approuvé-e dans aucune communauté.`"
      variant="warning"
    >
      Non partagé&nbsp;: aucune communauté approuvée
    </b-badge>
    <b-badge
      v-else-if="loanable.availability_status === 'wrong_type'"
      v-b-tooltip="
        `Le-a propriétaire n\'est pas approuvé-e dans une communauté permettant le partage de type
              \'${$t(
                `loanables.fields.types.${loanable.type}`
              )}\'. Ce véhicule ne sera donc pas affiché.`
      "
      variant="danger"
    >
      Type non partagé&nbsp;: {{ $t(`loanables.fields.types.${loanable.type}`) }}
    </b-badge>
    <b-badge
      v-else-if="loanable.availability_status === 'no_availabilities'"
      v-b-tooltip="`Ce véhicule n'a pas de disponibilités et ne sera donc pas affiché.`"
      variant="warning"
    >
      Aucune disponibilité
    </b-badge>
    <b-badge v-else-if="loanable.availability_status === 'unpublished'" variant="secondary">
      Non publié
    </b-badge>
    <b-badge v-else-if="loanable.availability_status === 'has_availabilities'" variant="success">
      Affiché
    </b-badge>

    <b-badge v-if="hasBlockingIncident" variant="danger"
      ><b-icon icon="exclamation-triangle" class="mr-1" />Incident bloquant</b-badge
    >
    <b-badge v-else-if="hasIncident" variant="warning"
      ><b-icon icon="exclamation-triangle" class="mr-1" />Incident non bloquant</b-badge
    >
  </div>
</template>

<style scoped lang="scss">
.status-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}
</style>
