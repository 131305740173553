<template>
  <div ref="loanableList" class="community-list" :class="{ loading }">
    <div class="top-filter-list">
      <div class="button-list flex-grow-1">
        <dropdown-select
          v-if="communityOptions.length > 2"
          v-model="selectedCommunity"
          label="Communauté"
          :options="communityOptions"
          @select="onCommunitySelected"
        />
        <icon-button
          :icon="availableOnly ? 'calendar2-check-fill' : 'calendar2-check'"
          variant="ghost-secondary"
          :pressed="availableOnly"
          @click="toggleAvailableOnly"
        >
          Disponible
        </icon-button>
        <b-input
          v-model="nameFilter"
          class="name-filter"
          placeholder="Nom du véhicule"
          @input="debouncedSetNameFilter"
        />
      </div>
      <b-pagination
        v-if="visibleLoanables.length > 0 && loaded"
        :value="page"
        class="mb-0 flex-1"
        align="right"
        :total-rows="total"
        :per-page="perPage"
        @change="onPageChange"
      />
    </div>
    <div v-if="!ghostOnly && visibleLoanables.length > 0">
      <div class="search-list-loanable-grid">
        <loanable-details
          v-for="loanable in visibleLoanables"
          :id="`loanable-result-${loanable.id}`"
          :key="loanable.id"
          class="position-relative community-list__result"
          :loanable="loanable"
          @test="emitTest(loanable)"
          @select="$emit('select', { ...$event, loanable })"
        />
      </div>
    </div>
    <!-- container if no loanables -->
    <div v-else>
      <div v-if="loading || ghostOnly" class="search-list-loanable-grid">
        <div
          v-for="i in 7"
          :key="i"
          class="community-list__skeleton"
          :style="`opacity: ${50 - i * 5}%`"
        >
          <b-skeleton type="card" />
          <div class="skeleton-content">
            <b-skeleton class="loanable-image-skeleton" animation="null" />

            <b-skeleton class="loanable-name-skeleton" :animation="null" />
            <b-skeleton class="loanable-button-skeleton" :animation="null" />
          </div>
        </div>
      </div>
      <b-card v-else-if="availableOnly && !$store.state['loanable.search'].allTested">
        <icon-button
          icon="search"
          variant="outline-primary"
          class="mb-2"
          @click="$store.dispatch('loanable.search/testAll')"
        >
          Rechercher pour voir les véhicules disponibles
        </icon-button>
        <div class="text-muted">
          Ou <b-link @click="toggleAvailableOnly">enlevez le filtre "Disponible"</b-link> pour voir
          tous les véhicules
        </div>
      </b-card>
      <b-card v-else>
        <h3>Désolé, aucun véhicule ne correspond à ces critères.</h3>
        <p class="community-list--dark">
          Essayez d'autres critères ou invitez vos voisins à rejoindre LocoMotion ;)
        </p>
      </b-card>
    </div>
  </div>
</template>

<script>
import LoanableDetails from "@/components/Loanable/Details.vue";
import { debounce } from "@/helpers/debounce";
import DropdownSelect from "@/components/DropdownSelect.vue";
import IconButton from "@/components/shared/IconButton.vue";

export default {
  name: "LoanableList",
  components: {
    LoanableDetails,
    DropdownSelect,
    IconButton,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    ghostOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      containerWidth: null,
      widthObserver: null,
      selectedCommunity: null,
      availableOnly: false,
      nameFilter: null,
      debouncedNameFilter: null,
      loaded: false,
    };
  },
  computed: {
    perPage() {
      if (!this.containerWidth) {
        return 8;
      }
      // 17 = 16 rem min width for each box, 1rem for the gap
      let loanablesPerLine = Math.floor(this.containerWidth / 17 / 16);
      // Check if we can fit one more loanable per line if we ignore the last gap (since gaps are
      // only between the boxes)
      if (
        loanablesPerLine > 1 &&
        ((loanablesPerLine + 1) * 16 + loanablesPerLine) * 16 < this.containerWidth
      ) {
        loanablesPerLine++;
      }
      let numberOfLines = Math.ceil(8 / loanablesPerLine);
      return numberOfLines * loanablesPerLine;
    },
    communityOptions() {
      let options = this.$store.state.user.user_communities.map((communityUser) => ({
        label: communityUser.community.name,
        value: communityUser.community.id,
      }));
      return [
        {
          label: "Toutes",
          value: null,
        },
        ...options,
      ];
    },
    total() {
      return this.filteredLoanables.length;
    },
    filteredLoanables() {
      let data = this.data;
      if (this.selectedCommunity) {
        data = data.filter((loanable) => loanable.community_ids.includes(this.selectedCommunity));
      }
      if (this.availableOnly) {
        data = data.filter((loanable) => loanable.available);
      }
      if (this.debouncedNameFilter) {
        data = data.filter((loanable) =>
          loanable.name.toLowerCase().includes(this.debouncedNameFilter.toLowerCase())
        );
      }
      return data;
    },
    visibleLoanables() {
      return this.filteredLoanables.slice((this.page - 1) * this.perPage, this.page * this.perPage);
    },
    types() {
      return this.$store.state["loanable.search"].selectedLoanableTypes;
    },
  },
  watch: {
    types() {
      this.page = 1;
    },
  },
  created() {
    this.debouncedSetNameFilter = debounce((name) => (this.debouncedNameFilter = name));
  },
  mounted() {
    if (this.$route.query.loanable_id && window.innerWidth < 990) {
      setTimeout(() => {
        let loanableResult = document.getElementById(
          `loanable-result-${this.$route.query.loanable_id}`
        );
        if (!loanableResult) {
          return;
        }
        window.scrollTo({
          top: window.scrollY + loanableResult.getBoundingClientRect().top - 16,
        });
      }, 50);
    }
    this.containerWidth = this.$el.clientWidth;

    let debouncedSetWidth = debounce((width) => {
      this.containerWidth = width;
      if (this.perPage * (this.page - 1) >= this.filteredLoanables.length) {
        this.page = 1;
      }
    });
    this.widthObserver = new ResizeObserver((entries) => {
      debouncedSetWidth(entries[0].contentBoxSize[0].inlineSize);
    });
    this.widthObserver.observe(this.$el);

    if (this.$route.query.loanable_id) {
      // find page
      let loanableIndex = this.data.findIndex(
        (loanable) => loanable.id == this.$route.query.loanable_id
      );
      if (loanableIndex !== -1) {
        this.page = Math.ceil((loanableIndex + 1) / this.perPage);
      }
    }
    this.loaded = true;
  },
  beforeDestroy() {
    this.widthObserver?.unobserve(this.$el);
  },
  methods: {
    onPageChange(page) {
      this.page = page;
    },
    onCommunitySelected() {
      this.page = 1;
    },
    emitTest(loanable) {
      this.$emit("test", loanable);
    },
    toggleAvailableOnly() {
      this.availableOnly = !this.availableOnly;
      this.page = 1;
      if (this.availableOnly && !this.$store.state["loanable.search"].allTested) {
        this.$store.dispatch("loanable.search/testAll");
      }
    },
  },
};
</script>
<style lang="scss">
#new-loan-modal .modal-content {
  border: 0;
  box-shadow:
    0px 1px 1px change-color($dark, $alpha: 0.5),
    0px 4.2px 4px -0.7px change-color($dark, $alpha: 0.45),
    0px 9.8px 9.3px -1.5px change-color($dark, $alpha: 0.4),
    0.1px 21.6px 20.6px -2.2px change-color($dark, $alpha: 0.35),
    0.2px 43.5px 41.4px -2.9px change-color($dark, $alpha: 0.3),
    0.3px 79px 75.2px -3.6px change-color($dark, $alpha: 0.25);
  background: $main-bg;
}
</style>
<style lang="scss" scoped>
@import "~bootstrap/scss/mixins/breakpoints";

.top-filter-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
  .name-filter {
    max-width: 15rem;
    flex-shrink: 1;
  }
}

.community-list__skeleton {
  height: 20rem;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
  opacity: 0.5;

  .b-skeleton-card {
    height: 100%;
  }
  .skeleton-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .loanable-image-skeleton {
    width: 100%;
    aspect-ratio: 16 / 9;
    height: auto;
  }
  .loanable-name-skeleton {
    margin-top: -2rem;
    width: 40%;
    margin-left: 0.5rem;
  }
  .loanable-button-skeleton {
    bottom: 0.5rem;
    right: 0;
    left: 0;
    position: absolute;
    width: 6rem;
    margin: 0 auto;
    border-radius: 0.25rem;
    height: 1.5rem;
  }
}

.search-list-loanable-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  gap: 1rem;
}
.community-list {
  width: 100%;
  position: relative;
  margin-bottom: 2rem;
}

.community-list--dark {
  color: $dark;
}
</style>
