<template>
  <div v-if="user" class="profile-sidebar">
    <ul class="px-2">
      <b-nav-item to="/profile/loans" :disabled="!user.borrower">
        <b-icon icon="arrow-repeat" class="mr-2" />{{ $t("profile.titles.loans") }}
      </b-nav-item>
      <b-nav-item v-if="canAddLoanables" to="/profile/loanables">
        <b-icon icon="bicycle" class="mr-2" />{{ $t("profile.titles.loanables") }}
      </b-nav-item>
      <b-nav-item to="/profile/invoices" :disabled="!isRegistered">
        <b-icon icon="file-text" class="mr-2" />{{ $t("profile.titles.invoices") }}
      </b-nav-item>
      <b-nav-item to="/profile/communities">
        <b-icon icon="people" class="mr-2" />{{ $t("profile.titles.communities") }}
      </b-nav-item>
    </ul>
    <conditional-context show label="Paramètres" class="mx-0 my-3 mb-3 py-2 px-2">
      <ul>
        <b-nav-item to="/profile/locomotion">
          <b-icon icon="person" class="mr-2" />{{ $t("profile.titles.profile") }}
        </b-nav-item>
        <b-nav-item to="/profile/account">
          <b-icon icon="gear" class="mr-2" />{{ $t("profile.titles.account") }}
        </b-nav-item>
        <b-nav-item to="/profile/payment_methods" :disabled="!isRegistered">
          <b-icon icon="wallet" class="mr-2" />{{ $t("profile.titles.payment_methods") }}
        </b-nav-item>
        <b-nav-item v-if="canFillDriverProfile" to="/profile/borrower">
          <b-icon icon="person-badge" class="mr-2" /> {{ $t("profile.titles.borrower") }}
        </b-nav-item>
        <b-nav-item to="/profile/reports" :disabled="!isRegistered">
          <b-icon icon="file-earmark-spreadsheet" class="mr-2" /> {{ $t("profile.titles.reports") }}
        </b-nav-item>
      </ul>
    </conditional-context>
  </div>
</template>

<script>
import ConditionalContext from "@/components/shared/ConditionalContext.vue";
import {
  canFillDriverProfile,
  userIsApproved,
  userIsRegistered,
  canAddLoanables,
} from "@/helpers/permissions/users";

export default {
  name: "ProfileSidebar",
  components: { ConditionalContext },
  computed: {
    user() {
      return this.$store.state.user;
    },
    canFillDriverProfile() {
      return canFillDriverProfile(this.user);
    },
    hasProfileApproved() {
      return userIsApproved(this.user);
    },
    isRegistered() {
      return userIsRegistered(this.user);
    },
    canAddLoanables() {
      return canAddLoanables(this.user);
    },
  },
};
</script>

<style lang="scss">
::view-transition-group(active-link) {
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.profile-sidebar {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    .nav-item + .nav-item {
      margin-top: 0.25rem;
    }

    .nav-item .nav-link {
      color: $black;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.5rem;
      transition: background 0.2s;
      position: relative;

      &:before {
        content: "";
        display: block;
        position: absolute;
        border-radius: 0.25rem;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &:hover:before {
        background: transparentize($locomotion-dark-green, 0.95);
      }

      &.router-link-active {
        color: $locomotion-green;
      }

      &.router-link-active:before {
        background: transparentize($locomotion-green, 0.875);
        view-transition-name: active-link;
      }

      .b-icon {
        max-height: 16px;
      }

      &.disabled {
        color: $grey;
      }
    }
  }
}
</style>
