<script>
import IconButton from "@/components/shared/IconButton.vue";
import { canCancelLoan, canResumeLoan } from "@/helpers/permissions/loans";
import { put } from "@/requests/server";
import dayjs from "dayjs";
import { sections } from "./FullLoanSections";

export default {
  name: "LoanButtons",
  components: { IconButton },
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  computed: {
    loanIsInProcess() {
      return (
        this.loan.id &&
        this.loan.id !== "new" &&
        this.loan.status !== "canceled" &&
        this.loan.status !== "rejected" &&
        this.loan.status !== "completed"
      );
    },
    canCancelLoan() {
      return canCancelLoan(this.$store.state.user, this.loan);
    },
    canResumeLoan() {
      return canResumeLoan(this.$store.state.user, this.loan);
    },
    canAddIncident() {
      return (
        // No new incidents.
        this.loanIsInProcess &&
        this.loan.loanable.active_incidents.filter((i) => !i.id).length === 0
      );
    },
  },
  methods: {
    async cancelLoan() {
      let cancel = await this.$bvModal.msgBoxConfirm(
        "Êtes-vous sûr-e de vouloir annuler l'emprunt?",
        {
          okTitle: "Annuler l'emprunt",
          cancelTitle: "Maintenir l'emprunt",
          okVariant: "danger",
          cancelVariant: "ghost-secondary",
        }
      );
      if (!cancel) {
        return;
      }
      const { data } = await put(`loans/${this.loan.id}/cancel`, null, {
        notifications: { action: "annulation de l'emprunt" },
      });
      this.$emit("input", data);
    },
    async resumeLoan() {
      const h = this.$createElement;

      const contentText = [h("p", "Êtes-vous sûr-e de vouloir ré-activer l'emprunt?")];
      if (
        (!this.loan.accepted_at || !this.loan.prepaid_at) &&
        dayjs(this.loan.actual_return_at).isBefore(dayjs())
      ) {
        contentText.push(
          h(
            "small",
            { class: "text-muted" },
            "Cet emprunt n'a jamais été accepté ou prépayé et a donc été annulé automatiquement après l'heure prévue de retour." +
              " La ré-activation de cet emprunt le marquera comme étant confirmé pour éviter qu'il ne s'annule automatiquement de nouveau."
          )
        );
      }

      const content = h("div", {}, contentText);

      let resume = await this.$bvModal.msgBoxConfirm([content], {
        okTitle: "Ré-activer l'emprunt",
        cancelTitle: "Annuler",
        okVariant: "warning",
        cancelVariant: "ghost-secondary",
      });
      if (!resume) {
        return;
      }
      const { data } = await put(`loans/${this.loan.id}/resume`, null, {
        notifications: { action: "ré-activation de l'emprunt" },
      });
      this.$emit("input", data);
    },
    addIncident() {
      if (!this.canAddIncident) {
        return;
      }
      this.$emit(
        "input",
        {
          ...this.loan,
          loanable: {
            ...this.loan.loanable,
            active_incidents: [
              ...this.loan.loanable.active_incidents,
              {
                status: "in_process",
                incident_type: null,
                loan_id: this.loan.id,
              },
            ],
          },
        },
        {
          refreshDashboard: false,
          freshlyLoaded: false,
        }
      );
      this.$emit("jump", sections.incidents);
    },
  },
};
</script>

<template>
  <div v-if="loanIsInProcess" class="loan-action-buttons">
    <icon-button
      size="sm"
      variant="outline-warning"
      icon="exclamation-triangle"
      square
      @click="addIncident"
      >Signaler un incident</icon-button
    >
    <icon-button
      size="sm"
      variant="outline-danger"
      icon="x-octagon"
      :onclick="cancelLoan"
      :disabled="!canCancelLoan"
      square
    >
      Annuler
    </icon-button>
  </div>
  <icon-button
    v-else-if="canResumeLoan"
    size="sm"
    variant="outline-warning"
    icon="play-circle"
    :onclick="resumeLoan"
    square
  >
    Ré-activer
  </icon-button>
</template>

<style scoped lang="scss">
.loan-action-buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: stretch;
  > * {
    flex-grow: 1;
  }
}
</style>
