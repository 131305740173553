<template>
  <div class="loan-search-form">
    <b-form class="form loan-search-form" @submit.stop.prevent="submit">
      <!-- title -->
      <div class="mb-3">
        <h4 class="loan-search-form--no-margin">
          Qu'aimeriez-vous emprunter
          <span v-if="userApprovedOnlyInPrivateCommunities"> ? </span>
          <span v-else class="loan-search-form--green"
            ><br />
            à vos voisin-e-s ?
          </span>
        </h4>
      </div>
      <!---->
      <!-- buttons to select types of vehicles -->
      <b-form-group>
        <b-form-checkbox-group
          v-if="possibleLoanableTypes.length > 1"
          id="loanable_type"
          buttons
          button-variant="outline-primary"
          :disabled="loading"
          class="loanable-buttons"
          :checked="selectedLoanableTypes"
          @change="emitLoanableTypes"
        >
          <b-checkbox v-if="possibleLoanableTypes.includes('car')" class="no-inset" value="car">
            <svg-car />
            <div>Auto</div>
          </b-checkbox>
          <b-checkbox v-if="possibleLoanableTypes.includes('bike')" class="no-inset" value="bike">
            <svg-bike />
            <div>Vélo</div>
          </b-checkbox>
          <b-checkbox
            v-if="possibleLoanableTypes.includes('trailer')"
            class="no-inset"
            value="trailer"
          >
            <svg-trailer />
            <div>Remorque</div>
          </b-checkbox>
        </b-form-checkbox-group>
      </b-form-group>
      <BorrowerProfileWarning v-if="selectedLoanableTypes.includes('car')" />
      <!---->
      <div class="search-dates">
        <div class="search-date-input">
          <label>{{ $t("loans.fields.departure_at") | capitalize }}</label>
          <forms-date-time-picker
            ref="depature"
            zoneless
            :value="searchDate"
            :disabled-dates-fct="disabledBeforeToday"
            :disabled-times-fct="disabledBeforeNow"
            @input="searchDateChanged"
          >
            <template #footer>
              <button class="mx-btn mx-btn-text" @click="setToEarliestDeparture">Maintenant</button>
            </template>
          </forms-date-time-picker>
        </div>

        <div class="search-date-input">
          <label>{{ $t("loans.fields.return_at") | capitalize }}</label>

          <forms-date-time-picker
            ref="return"
            :value="returnAt"
            zoneless
            :disabled-dates-fct="disabledBeforeDepartureDay"
            :disabled-times-fct="disabledBeforeDeparture"
            @input="returnAtChanged"
          >
            <template #footer>
              <button class="mx-btn mx-btn-text" @click="() => storeDurationAndClosePopup(60)">
                1 heure
              </button>
              <button class="mx-btn mx-btn-text" @click="() => storeDurationAndClosePopup(60 * 3)">
                3 heures
              </button>
              <button class="mx-btn mx-btn-text" @click="() => storeDurationAndClosePopup(60 * 24)">
                1 jour
              </button>
            </template>
          </forms-date-time-picker>
        </div>
      </div>

      <!-- text for loan invalid duration -->
      <b-alert v-if="invalidDuration" show variant="danger">
        La durée de l'emprunt doit être supérieure ou égale à 15 minutes.
      </b-alert>
      <!---->
      <!-- search button -->
      <div class="button-list justify-content-between">
        <icon-button
          type="submit"
          pill
          variant="success"
          :disabled="loading || invalidDuration"
          :loading="loading"
          icon="search"
        >
          Rechercher
        </icon-button>
        <slot name="buttons"></slot>
      </div>
      <!---->
    </b-form>
  </div>
</template>

<script>
import Bike from "@/assets/svg/bike.svg";
import Car from "@/assets/svg/car.svg";
import Trailer from "@/assets/svg/trailer.svg";
import FormsDateTimePicker from "@/components/Forms/DateTimePicker.vue";
import BorrowerProfileWarning from "@/components/Loan/BorrowerProfileWarning.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { getEarliestDeparture } from "@/helpers/loanDepartures";
import FormLabelsMixin from "@/mixins/FormLabelsMixin";
import dayjs from "dayjs";

export default {
  name: "SearchForm",
  components: {
    FormsDateTimePicker,
    IconButton,
    BorrowerProfileWarning,
    "svg-bike": Bike,
    "svg-car": Car,
    "svg-trailer": Trailer,
  },
  mixins: [FormLabelsMixin],
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedLoanableTypes: {
      type: Array,
      required: true,
    },
    possibleLoanableTypes: {
      type: Array,
      required: true,
    },
  },
  computed: {
    invalidDuration() {
      // Invalid if the duration of a loan is not greater than 0 minute.
      return !(this.searchDuration > 0);
    },
    searchDate() {
      return this.$store.state["loanable.search"].searchDepartureAt;
    },
    searchDuration() {
      return this.$store.state["loanable.search"].searchDuration;
    },
    returnAt() {
      return dayjs
        .utc(this.searchDate)
        .add(this.searchDuration, "minute")
        .format("YYYY-MM-DD HH:mm");
    },
    userApprovedOnlyInPrivateCommunities() {
      return (this.$store.state.user?.user_communities || [])
        .filter((c) => c.status === "approved")
        .every((c) => c.community.type === "private");
    },
  },
  beforeMount() {
    const { searchDepartureAt, searchDuration } = this.$store.state["loanable.search"];
    const minimumTime = getEarliestDeparture();
    if (!searchDepartureAt || minimumTime.isAfter(searchDepartureAt)) {
      this.$store.commit("loanable.search/searchDepartureAt", minimumTime);
    }
    if (!searchDuration) {
      this.$store.commit("loanable.search/searchDuration", 60);
    }
  },
  methods: {
    disabledBeforeToday(date) {
      return dayjs().isAfter(date, "day");
    },
    disabledBeforeNow(date) {
      return dayjs().isSameOrAfter(date, "minute") || dayjs(date).minute() > 45;
    },
    disabledBeforeDeparture(date) {
      return dayjs(date).isSameOrBefore(this.searchDate, "minute");
    },
    disabledBeforeDepartureDay(date) {
      return dayjs(date).isBefore(this.searchDate, "day");
    },
    storeDurationAndClosePopup(duration) {
      this.storeSearchDuration(duration);
      this.$refs.return.close();
    },
    storeSearchDuration(duration) {
      this.$store.commit("loanable.search/searchDuration", duration);
    },
    storeSearchDate(date) {
      this.$store.commit("loanable.search/searchDepartureAt", date);
    },
    setToEarliestDeparture() {
      this.storeSearchDate(getEarliestDeparture());
      this.$refs.depature.close();
    },
    emitLoanableTypes(value) {
      this.$emit("selectLoanableTypes", value);
    },
    submit() {
      this.$emit("submit");
    },
    returnAtChanged(returnAt) {
      this.storeSearchDuration(dayjs.utc(returnAt).diff(dayjs.utc(this.searchDate), "minute"));
    },
    searchDateChanged(newDepartureAt) {
      if (this.invalidDuration) {
        // If duration is negative, we allow user to move each date independently
        const newDuration = dayjs.utc(this.returnAt).diff(dayjs.utc(newDepartureAt), "minute");
        this.storeSearchDuration(newDuration);
      }
      this.storeSearchDate(newDepartureAt);
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.loan-search-form {
  container-type: inline-size;

  &__button-borrower {
    margin: 15px 0 0 0;
  }

  .search-dates {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
    @container (width > 30rem) {
      flex-direction: row;
    }
  }

  h4 {
    font-size: 22px;
    font-weight: 700;
  }

  .b-icon.bi {
    font-size: 100%;
    vertical-align: -0.15em;
  }

  .loanable-buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    svg {
      height: 22px;
      width: 34px;
    }
    svg path {
      fill: currentColor;
    }
  }
  .loanable-buttons label.btn {
    border-radius: 10px;
    font-size: 13px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    flex-basis: 5rem;
    border-width: 2px;
    border-radius: 10px !important;
    padding-left: 6px;
    padding-right: 6px;
    align-items: center;
    justify-content: flex-end;
  }

  .loanable-buttons label:hover {
    background-color: #fff;
    color: $primary;
  }

  .loanable-buttons .btn.disabled {
    background-color: #fff;
    border-color: $light !important;
    color: #7a7a7a;
  }

  .loanable-buttons .active.btn.disabled {
    background-color: $light;
  }
}

.loan-search-form--green {
  color: #00b1aa;
}

.loan-search-form--no-margin {
  margin: 0;
}

.loan-search-form--margin-bottom {
  margin-bottom: 15px;
}
</style>
