<template>
  <div class="availability-rules">
    <loanable-calendar
      default-view="month"
      availability-mode="unavailable"
      :events="events"
      :loading="loading"
      @click.native.stop.prevent
      @ready="calendarChanged"
      @view-change="calendarChanged"
    ></loanable-calendar>

    <loanable-calendar-legend class="mt-2" />

    <b-row class="availability-rules__description">
      <b-col>
        <div class="form-inline availability-rules__description__default">
          <b-form-group label="Par défaut:" label-for="availability_mode" inline>
            <b-select
              id="availability_mode"
              v-model="loanable.availability_mode"
              :disabled="disabled"
              name="availability_mode"
              @change="exceptions = []"
            >
              <option value="never" selected>Toujours indisponible</option>
              <option value="always" selected>Toujours disponible</option>
            </b-select>
          </b-form-group>
        </div>

        <b-row>
          <b-col>
            <p>Sauf&nbsp;:</p>
            <loanable-exceptions
              :disabled="disabled"
              :mode="loanable.availability_mode"
              :exceptions="exceptions"
              @input="exceptions = $event"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row v-if="unavailableLoans.length > 0">
      <b-col>
        <b-alert show variant="warning" class="mt-4">
          <h4 class="alert-heading">Emprunts durant des périodes d'indisponibilité</h4>

          Des emprunts existent dans des périodes d'indisponibilité du véhicule. Avez-vous besoin
          d'ajuster les disponibilités ou d'avertir les emprunteurs et annuler les emprunts?

          <div v-for="loan in unavailableLoans" :key="loan.id" class="mt-3">
            <loan-info-box
              :loan="loan"
              :user="user"
              :buttons="['view', 'cancel']"
              @changed="refreshUnavailableLoans"
            />
          </div>
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LoanInfoBox from "@/components/Loan/InfoBox.vue";
import LoanableCalendar from "@/components/Loanable/Calendar.vue";
import LoanableCalendarLegend from "@/components/Loanable/CalendarLegend.vue";
import LoanableExceptions from "@/components/Loanable/Exceptions.vue";
import { debounce } from "@/helpers/debounce";
import { get } from "@/requests/server";

export default {
  name: "LoanableAvailabilityRules",
  components: {
    LoanInfoBox,
    LoanableCalendar,
    LoanableCalendarLegend,
    LoanableExceptions,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loanable: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      calendar: {
        view: "",
        startDate: undefined, // Date
        endDate: undefined, // Date
        firstCellDate: undefined, // Date
        lastCellDate: undefined, // Date
      },
      events: [],
      loading: false,
      unavailableLoans: [],
    };
  },
  computed: {
    exceptions: {
      get() {
        return JSON.parse(this.loanable.availability_json);
      },
      set(val) {
        this.loanable.availability_json = JSON.stringify(val);
      },
    },
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    "loanable.availability_json": function () {
      this.loading = true;
      this.calendarEventsDebounced();
      this.refreshLoansDebounced();
    },
    "loanable.availability_mode": function () {
      this.loading = true;
      this.calendarEventsDebounced();
      this.refreshLoansDebounced();
    },
  },
  mounted() {
    this.refreshUnavailableLoans();
  },
  created() {
    this.calendarEventsDebounced = debounce(() => this.calendarUpdateEvents(), 50);
    this.refreshLoansDebounced = debounce(() => this.refreshUnavailableLoans(), 300);
  },
  methods: {
    calendarChanged({ view, startDate, endDate, firstCellDate, lastCellDate }) {
      this.calendar = { view, startDate, endDate, firstCellDate, lastCellDate };
      this.calendarUpdateEvents();
    },
    async refreshUnavailableLoans() {
      if (!this.loanable.id) {
        return;
      }

      const { data } = await get(`/loanables/${this.loanable.id}/loans/unavailable`, {
        axiosRequestConfig: {
          params: {
            availability_mode: this.loanable.availability_mode,
            availability_json: this.loanable.availability_json,
          },
        },
        notifications: { action: "calcul des périodes de disponibilité" },
      });
      this.unavailableLoans = data;
    },
    async calendarUpdateEvents() {
      let { view, startDate, endDate, firstCellDate, lastCellDate } = this.calendar;
      let start, end;

      // Include out-of-scope days in month view.
      if (view === "month") {
        // Must convert [, ] interval to [, ) by adding one second to the end time.
        start = this.$dayjs(firstCellDate);
        end = this.$dayjs(lastCellDate).add(1, "s");
      } else {
        // Must convert [, ] interval to [, ) by adding one second to the end time.
        start = this.$dayjs(startDate);
        end = this.$dayjs(endDate).add(1, "s");
      }

      try {
        this.loading = true;
        const response = await get(`/loanables/availability`, {
          axiosRequestConfig: {
            params: {
              start: start.format("YYYY-MM-DD HH:mm:ss"),
              end: end.format("YYYY-MM-DD HH:mm:ss"),
              responseMode: "available",
              // Sync with availability in real-time.
              availability_mode: this.loanable.availability_mode,
              availability_json: this.loanable.availability_json,
              timezone: this.loanable.timezone,
            },
          },
          notifications: { action: "calcul des disponibilités de ce véhicule" },
          requestOptions: { cancelId: `loanables/${this.loanable.id}/availability` },
        });
        this.events = response.data.map((e) => {
          e.type = "availability";
          return e;
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
