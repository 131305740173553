var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-cal',{ref:"vueCal",class:_vm.classList,attrs:{"disable-views":_vm.disableViews,"active-view":_vm.defaultView,"snap-to-time":15,"time-step":60,"time-cell-height":32,"events":_vm.vueCalEvents,"click-to-navigate":"","locale":"fr","events-on-month-view":"","selected-date":_vm.initialDate,"on-event-click":_vm.onEventClick},on:{"ready":function($event){return _vm.$emit('ready', $event)},"view-change":_vm.onViewChanged,"event-mouse-enter":_vm.eventEnter,"event-mouse-leave":_vm.eventLeave},scopedSlots:_vm._u([{key:"title",fn:function({ view }){return [_c('div',{staticClass:"d-flex align-items-center position-relative"},[(view.id === 'years')?_c('span',[_vm._v("Years")]):(view.id === 'year')?_c('span',[_vm._v(_vm._s(view.startDate.format("YYYY")))]):(view.id === 'month')?_c('span',[_vm._v(_vm._s(view.startDate.format("MMMM YYYY")))]):(view.id === 'week')?_c('span',[_vm._v(_vm._s(view.startDate.format("MMMM YYYY")))]):(view.id === 'day')?_c('span',[_vm._v(_vm._s(view.startDate.format("dddd D MMMM (YYYY)")))]):_vm._e(),(_vm.loading)?_c('b-spinner',{staticClass:"loanable-calendar__loading-spinner",attrs:{"small":""}}):_vm._e()],1)]}},{key:"time-cell",fn:function({ hours, minutes }){return [_c('div',{class:{
        'loanable-calendar__time-step--hours': !minutes,
        'loanable-calendar__time-step--minutes': minutes,
        'vuecal__time-cell-line': true,
      }},[_c('span',{staticClass:"line"}),(!minutes)?[_c('span',[_vm._v(_vm._s(hours < 10 ? "0" + hours : hours)+":"+_vm._s(minutes < 10 ? "0" + minutes : minutes))])]:_vm._e()],2)]}},(_vm.availabilityMode)?{key:"cell-content",fn:function(cellData){return [(cellData.view.id === 'month')?_c('calendar-month-cell-content',{attrs:{"availability":_vm.getMonthDayAvailability(cellData.events, cellData.cell),"current":cellData.cell.today}},[_vm._v(" "+_vm._s(cellData.cell.content)+" ")]):_c('span',{staticClass:"vuecal__cell-date"},[_vm._v(" "+_vm._s(cellData.cell.content)+" ")])]}}:null,{key:"event",fn:function({ event }){return [(event.data.available === false)?_c('b-icon',{attrs:{"icon":"calendar2-x"}}):_vm._e(),(event.data.available === true)?_c('b-icon',{attrs:{"icon":"calendar2-check"}}):_vm._e(),_c('div',{staticClass:"hover-target"}),(
        event.data.available !== undefined && (event.title || event.startDate || event.endDate)
      )?_c('span',[_vm._v("  ")]):_vm._e(),(event.title)?_c('div',{staticClass:"vuecal__event-title"},[_vm._v(" "+_vm._s(event.title)+" ")]):_vm._e(),_c('small',{staticClass:"vuecal__event-time"},[_c('span',{staticClass:"vuecal__event-time__start"},[(event.data.status)?_c('loan-status-icon',{attrs:{"status":event.data.status,"action-required":event.data.action_required}}):_vm._e(),_vm._v(" "+_vm._s(_vm.formatEventTimeStart(event)))],1),_c('span',{staticClass:"vuecal__event-time__end"},[_vm._v(_vm._s(_vm.formatEventTimeEnd(event))+" ")]),_c('span',{staticClass:"vuecal__event-time__span"},[_vm._v(_vm._s(_vm.formatEventTimeSpan(event)))])])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }