// Converts a GeoJson MultiPolygon to a list of paths consumable by google.maps
function extractMultipolygonPaths(multipolygon) {
  return multipolygon.coordinates.map((polygon) =>
    polygon.flatMap((linearRing) =>
      // linearRing has the same first and last point
      linearRing.slice(0, -1).map(([lng, lat]) => ({ lat, lng }))
    )
  );
}

function extractMultipolygonPoints(multipolygon) {
  return extractMultipolygonPaths(multipolygon).flatMap((path) => path);
}

/**
 * @param {array{lat: number, lng: number}} points
 * @returns {object{minLat: number, maxLat: number, minLng: number, maxLng: number}}
 */
function getBoundingBox(points) {
  if (!points.length) {
    return { minLat: 0, maxLat: 0, minLng: 0, maxLng: 0 };
  }

  return points.reduce(
    (acc, point) => {
      acc.minLat = Math.min(acc.minLat, point.lat);
      acc.maxLat = Math.max(acc.maxLat, point.lat);
      acc.minLng = Math.min(acc.minLng, point.lng);
      acc.maxLng = Math.max(acc.maxLng, point.lng);
      return acc;
    },
    { minLat: 90, maxLat: -90, minLng: 180, maxLng: -180 }
  );
}

export { extractMultipolygonPaths, extractMultipolygonPoints, getBoundingBox };
