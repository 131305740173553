<template>
  <paginated-table
    ref="table"
    endpoint="communityUsers"
    :sync-filters-with-url="syncFiltersWithUrl"
    :columns="columns"
    :load-relations="extraRelations"
    :extra-filters="extraFilters"
    v-bind="$attrs"
    :show-action-column="true"
    has-row-details
    @filters-changed="updateFilters"
    @updating="resetExports"
  >
    <template #head><slot name="head"></slot></template>
    <template #head-buttons><slot name="head-buttons"></slot></template>

    <template #cell(role)="row">
      <template v-if="row.item.approved_at && !row.item.suspended_at">
        <b-select
          v-if="canChangeUserRole"
          size="sm"
          :disabled="roleChanging.includes(row.item.id)"
          :options="[
            { value: null, text: $t('communities.fields.user.role_labels.member') },
            { value: 'admin', text: $t('communities.fields.user.role_labels.admin') },
          ]"
          :value="row.item.role"
          @change="changeUserRole(row.item, $event)"
        />
        <span v-else>{{ row.item.role }}</span>
      </template>
    </template>
    <template #row-details="{ item }">
      <community-user-details
        :community-user="item"
        :changing-item="roleChanging.includes(item.id)"
        @deleted="refresh"
      />
    </template>

    <template v-if="showMailboxExport" #moreExports>
      <div class="mb-2">
        <file-download-link
          ref="mailbox-export-button"
          :api-url="`/communities/${communityId}/users/mailboxes`"
          filename="membres.csv"
          :api-headers="{
            Accept: 'text/csv',
          }"
          :api-params="filters || {}"
          button-label="Exporter liste d'envoi (CSV)"
          variant="ghost-secondary"
          size="sm"
          download
        />
      </div>

      <div v-if="mailboxes">
        <icon-button
          variant="outline-success"
          size="sm"
          icon="files"
          @click.stop.prevent="copyMailboxes"
        >
          Copier la liste d'envoi
        </icon-button>
        <b-form-textarea
          plaintext
          size="sm"
          class="mt-1"
          :value="mailboxes"
          rows="3"
        ></b-form-textarea>
      </div>
      <template v-else>
        <icon-button
          size="sm"
          variant="ghost-secondary"
          :loading="mailboxesLoading"
          @click.stop.prevent="exportMailboxes"
        >
          Exporter liste d'envoi (texte)
        </icon-button>
      </template>

      <div class="text-secondary small mt-1">
        La liste d'envoi générée prend en compte les filtres actuels. Assurez-vous de filtrer en
        fonction du 'statut' si vous désirez contacter uniquement les participants approuvés dans
        votre communauté.
      </div>
    </template>
  </paginated-table>
</template>

<script>
import CommunityUserDetails from "@/components/Community/CommunityUserDetails.vue";
import FileDownloadLink from "@/components/shared/FileDownloadLink.vue";
import IconButton from "@/components/shared/IconButton.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column, Filter } from "@/components/shared/PaginatedTableColumns";
import { date } from "@/helpers/filters";
import { isGlobalAdmin } from "@/helpers/permissions/users";
import { get, put } from "@/requests/server";

export default {
  name: "CommunityUsersList",
  components: {
    FileDownloadLink,
    IconButton,
    CommunityUserDetails,
    PaginatedTable,
  },
  props: {
    hideFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    syncFiltersWithUrl: {
      type: Boolean,
      default: true,
    },
    userId: {
      type: Number,
      default: undefined,
    },
    communityId: {
      type: Number,
      default: undefined,
    },
    showMailboxExport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const defaultColumns = [
      new Column("id", this.$t("lists.id"), "id", { showByDefault: false }),
      new Column("user_id", "ID (utilisateur)", "id", { showByDefault: false }),
      Column.withRelation(
        "user.full_name",
        this.$t("communities.fields.user.name"),
        {
          relation: "users",
          label: "full_name",
          field: "id",
          params: this.communityId
            ? {
                "communities.id": this.communityId,
                for: "admin",
              }
            : { for: "admin" },
        },
        "user_id",
        {
          urlFct: (item) => `/admin/users/${item.user_id}?community_id=${item.community_id}`,
        }
      ),
      new Column("community_id", "ID (communauté)", "id", { showByDefault: false }),
      Column.withRelation(
        "community.name",
        this.$t("communities.fields.community.name"),
        {
          relation: "communities",
          label: "name",
          field: "id",
          params: this.userId ? { "users.id": this.userId, for: "admin" } : { for: "admin" },
        },
        "community_id",
        {
          urlFct: (item) => `/admin/communities/${item.community_id}?user_id=${item.user_id}`,
        }
      ),
      new Column("user.email", "Courriel", "text"),
      new Column("user.phone", "Téléphone", "text", { showByDefault: false }),
      new Column("updated_at", "Dernière mise à jour", "date"),
      new Column("user.address", "Adresse", "text", { showByDefault: false }),
      Column.withSelect(
        "role",
        this.$t("communities.fields.user.role"),
        [
          { value: null, label: "tous" },
          { value: "admin", label: "admin" },
        ],
        { showByDefault: false }
      ),
      new Column("approved_at", this.$t("communities.fields.user.approved_at"), "date", {
        showByDefault: false,
      }),
      new Column("suspended_at", this.$t("communities.fields.user.suspended_at"), "date", {
        showByDefault: false,
      }),
      Column.withSelect(
        "proof_state",
        this.$t("communities.fields.user.proof"),
        [
          { value: null, label: "tous" },
          { value: "absent", label: "Sans preuves", variant: "danger" },
          { value: "partial", label: "Preuves partielles", variant: "warning" },
          { value: "present", label: "Avec preuves" },
          { value: "unevaluated", label: "Preuves à évaluer", variant: "warning" },
          { value: "valid", label: "Preuves validées", variant: "success" },
          { value: "invalid", label: "Preuves insuffisantes", variant: "danger" },
        ],
        { sortable: false }
      ),
      Column.withSelect(
        "status",
        "Statut",
        [
          { value: null, label: "tous" },
          { value: "new", label: "En attente de validation", variant: "warning" },
          { value: "approved", label: "Approuvé-e", variant: "success" },
          { value: "suspended", label: "Suspendu-e", variant: "danger" },
        ],
        { sortable: false }
      ),
      Column.withRelation(
        "approver.full_name",
        this.$t("communities.fields.approver"),
        {
          relation: "users",
          label: "name",
          field: "id",
          params: { for: "admin" },
        },
        "approver_id",
        {
          urlFct: (item) => `/admin/users/${item.approver_id}`,
          showByDefault: false,
        }
      ),
      new Column("approval_note", "Note d'approbation", "text", { showByDefault: false }),
      Column.withSelect(
        "join_method",
        "Type d'ajout",
        [
          { value: null, label: "tous" },
          { value: "geo", label: "Adresse", variant: "success" },
          { value: "manual", label: "Manuel", variant: "warning" },
          { value: "invitation", label: "Invitation", variant: "primary" },
        ],
        { sortable: false, showByDefault: false }
      ),
      new Column("current_granted_subscription.end_date", "Gratuité jusqu'au", "date", {
        formatter: date,
        showByDefault: false,
      }),
    ];

    const extraRelations = [
      "user.identity_proof",
      "user.residency_proof",
      "user.avatar",
      "community",
      "custom_proof",
      "current_granted_subscription.",
      "current_granted_subscription.granted_by_user",
    ];

    const extraFilters = [
      new Filter("in_community_area", "Dans la zone de la communauté", "select", [
        { value: null, label: "tous" },
        { value: false, label: "Hors-zone" },
        { value: true, label: "Dans la zone" },
      ]),
    ];

    return {
      roleChanging: [],
      defaultColumns,
      extraRelations,
      mailboxes: null,
      mailboxesLoading: false,
      filters: {},
      extraFilters,
    };
  },
  computed: {
    columns() {
      return this.defaultColumns.filter((c) => !this.hideFields.includes(c.key));
    },
    canChangeUserRole() {
      return isGlobalAdmin(this.$store.state.user);
    },
  },
  methods: {
    async changeUserRole(communityUser, role) {
      this.roleChanging.push(communityUser.id);

      const { data } = await put(
        `communityUsers/${communityUser.id}`,
        {
          role,
        },
        {
          cleanupCallback: () => {
            this.roleChanging = this.roleChanging.filter((i) => i !== communityUser.id);
          },
          notifications: {
            action: "changement de rôle",
          },
        }
      );

      Object.assign(communityUser, data);
    },
    refresh() {
      this.$refs.table.refresh();
    },
    resetExports() {
      if (this.$refs["mailbox-export-button"]) {
        this.$refs["mailbox-export-button"].reset();
      }
      this.mailboxes = null;
      this.mailboxesLoading = false;
    },
    setFilters(filters) {
      this.$refs.table.setFilters(filters);
    },
    async exportMailboxes() {
      if (this.mailboxes || this.mailboxesLoading) {
        return;
      }
      try {
        this.mailboxesLoading = true;
        const response = await get(`/communities/${this.communityId}/users/mailboxes`, {
          axiosRequestConfig: {
            params: this.filters || {},
          },
          notifications: { action: "export de la liste d'envoi" },
        });

        this.mailboxes = response.data;
      } finally {
        this.mailboxesLoading = false;
      }
    },
    copyMailboxes() {
      if (!this.mailboxes) {
        return;
      }

      navigator.clipboard.writeText(this.mailboxes).then(() => {
        this.$store.commit("addNotification", {
          title: "Liste d'envoi copiée",
          variant: "success",
        });
      });
    },
    updateFilters(filters) {
      this.filters = Object.assign({}, filters);
      this.mailboxes = null;
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.user-details-row {
  dl,
  dd {
    margin-bottom: 0;
  }
  dd + dt {
    margin-top: 0.5rem;
  }
}

@include media-breakpoint-down(xs) {
  .user-details-image {
    max-width: 16rem;
    margin-bottom: 1rem;
  }
}

.user-details-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
}
</style>
