var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loan-card"},[_c('validation-observer',{ref:"observer"},[_c('b-form',{attrs:{"novalidate":true}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('forms-validated-input',{attrs:{"name":"departure_at","label":_vm._f("capitalize")(_vm.$t('loans.fields.departure_at')),"type":"datetime","disabled-dates-fct":_vm.disabledBeforeToday,"disabled-times-fct":_vm.disabledBeforeNow,"value":_vm.departureAt,"timezone":_vm.loanableTimezone,"clearable":false,"description":_vm.zonedTimes.differsFromCurrentTimezone
                ? `Heure dans le fuseau horaire du véhicule: ${_vm.zonedTimes.timezoneAbbrev}`
                : null},on:{"input":_vm.changeSearchDate},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('button',{staticClass:"mx-btn mx-btn-text",on:{"click":_vm.setDepartureToEarliest}},[_vm._v(" Maintenant ")])]},proxy:true}])})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('forms-validated-input',{attrs:{"value":_vm.returnAt,"name":"return_at","label":_vm._f("capitalize")(_vm.$t('loans.fields.return_at')),"type":"datetime","clearable":false,"disabled-dates-fct":_vm.disabledBeforeDepartureDay,"disabled-times-fct":_vm.disabledBeforeDeparture,"timezone":_vm.loanableTimezone},on:{"input":_vm.setReturnAt},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('button',{staticClass:"mx-btn mx-btn-text",on:{"click":() => _vm.setDuration(60)}},[_vm._v("1 heure")]),_c('button',{staticClass:"mx-btn mx-btn-text",on:{"click":() => _vm.setDuration(60 * 3)}},[_vm._v(" 3 heures ")]),_c('button',{staticClass:"mx-btn mx-btn-text",on:{"click":() => _vm.setDuration(60 * 24)}},[_vm._v(" 1 jour ")])]},proxy:true}])})],1)],1),(_vm.invalidDuration)?_c('b-row',[_c('b-col',[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" La durée de l'emprunt doit être supérieure ou égale à 15 minutes. ")])],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":_vm.alternativeTo === 'other' ? 6 : 12}},[_c('forms-validated-input',{attrs:{"name":"alternative_to","label":_vm._f("capitalize")(_vm.$t('loans.fields.alternative_to')),"rules":{
              required: true,
            },"type":"select","options":_vm.alternativeToOptions},on:{"input":(value) => {
                if (value !== 'other') {
                  _vm.alternativeToOther = null;
                }
              }},model:{value:(_vm.alternativeTo),callback:function ($$v) {_vm.alternativeTo=$$v},expression:"alternativeTo"}})],1),(_vm.alternativeTo === 'other')?_c('b-col',[_c('forms-validated-input',{attrs:{"name":"alternative_to_other","label":_vm._f("capitalize")(_vm.$t('loans.fields.alternative_to_other')),"rules":{
              required: true,
            },"type":"text"},model:{value:(_vm.alternativeToOther),callback:function ($$v) {_vm.alternativeToOther=$$v},expression:"alternativeToOther"}})],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('forms-validated-input',{attrs:{"name":"estimated_distance","label":_vm._f("capitalize")(_vm.$t('loans.fields.estimated_distance')),"type":"text","min":0,"rules":{ min_value: 0, required: true },"mask":"#######","formatter":_vm.integer},on:{"blur":_vm.onEstimatedDistanceBlur},model:{value:(_vm.estimatedDistance),callback:function ($$v) {_vm.estimatedDistance=$$v},expression:"estimatedDistance"}})],1)],1),(!_vm.loanWillBeSelfService)?_c('forms-validated-input',{attrs:{"name":"message_for_owner","label":_vm._f("capitalize")((`${_vm.$t('loans.fields.message_for_owner')} ` + `(${_vm.$t('forms.optional')})`)),"type":"markdown"},model:{value:(_vm.messageForOwner),callback:function ($$v) {_vm.messageForOwner=$$v},expression:"messageForOwner"}}):_vm._e(),_c('div',{staticClass:"text-center"},[(!_vm.available)?[(_vm.maxDurationInMinutes !== null && _vm.durationInMinutes > _vm.maxDurationInMinutes)?_c('div',{staticClass:"text-muted small mb-2"},[_vm._v(" La durée maximale pour un emprunt de ce véhicule est de "+_vm._s(_vm._f("duration")(_vm.maxDurationInMinutes))+". ")]):_vm._e(),(!_vm.available)?_c('icon-button',{attrs:{"variant":"primary","disabled":"","type":"submit"}},[_vm._v(" Indisponible ")]):_vm._e()]:_c('icon-button',{ref:"submitButton",attrs:{"onclick":_vm.submit,"variant":"success","disabled":_vm.estimating || _vm.invalidDuration || _vm.hasBlockingIncidents}},[_vm._v(" "+_vm._s(_vm.loanWillBeSelfService ? "Réserver" : "Envoyer la demande d'emprunt")+" ")])],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }