<template>
  <router-link :to="to" :class="`tutorial-block variant-${variant}`" :style="{ backgroundImage }">
    <div>
      <h2>{{ title }}</h2>
      <h4 v-if="subtitle">{{ subtitle }}</h4>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "TutorialBlock",
  props: {
    bgImage: {
      type: String,
      required: false,
      default: undefined,
    },
    to: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: "light",
    },
  },
  computed: {
    backgroundImage() {
      if (this.bgImage) {
        return `url('${this.bgImage}')`;
      }

      return undefined;
    },
  },
};
</script>

<style lang="scss">
.tutorial-block {
  min-height: 8rem;
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 1.25rem;
  padding-left: 2rem;

  display: flex;
  align-items: center;

  background-repeat: no-repeat;
  // Background height a bit larger than the container since the images have a bit of transparent padding
  background-size: auto 9rem;
  background-position: top right;
  transition-duration: 0.2s;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    box-shadow: $large-shadow;
  }

  h2 {
    font-size: 26px;
    font-weight: bold;
    color: $white;
    margin: 0;
    max-width: 35rem;
  }
  h4 {
    color: $white;
    font-weight: 300;
    font-size: 1.125rem;
  }

  &.variant {
    &-light {
      background-color: $locomotion-light-green;
    }

    &-dark {
      background-color: $locomotion-dark-green;
    }
  }
}
</style>
