<template>
  <validation-observer v-if="editedRole" ref="observer" v-slot="{ changed, passes }">
    <b-modal
      id="editing-role-modal"
      ref="modal"
      :title="editedRole.id ? 'Modifier le rôle' : 'Accorder un rôle'"
    >
      <b-form>
        <forms-validated-input
          type="relation"
          label="Membre"
          :name="'user_id_' + (role ? role.id : 0)"
          :disabled="!canEditUser || disabled || loading"
          :rules="{
            required: true,
          }"
          :query="roleUserQuery"
          :min-search-length="0"
          :value="editedRole.user ? editedRole.user.id : null"
          :object-value="editedRole.user"
          mode="eager"
          @relation="setUser"
        />

        <forms-validated-input
          type="select"
          :value="editedRole.role"
          disabled
          :options="[
            { value: editedRole.role, text: capitalize($t(`loanables.roles.${editedRole.role}`)) },
          ]"
          name="role"
          label="Rôle"
        />

        <forms-validated-input
          v-if="!editedRole.id && editedRole.role === 'manager'"
          v-model="editedRole.remove_existing_coowner_roles"
          label="Remplacer les rôles sur les véhicules individuels par ce nouveau rôle?"
          name="remove_existing_coowner_roles"
          description="Si coché, la personne ne sera plus responsable de chaque véhicule individuellement."
          type="checkbox"
          :disabled="disabled || loading"
        ></forms-validated-input>

        <forms-validated-input
          v-model="editedRole.title"
          label="Titre"
          :name="'title_' + (role ? role.id : 0)"
          description="Ce titre clarifie la responsabilité de cette personne pour le véhicule. Ex. marraine/parrain, gardien-ne, coordinateur-trice, co-propriétaire, etc."
          type="text"
          :disabled="disabled || loading"
        />

        <forms-validated-input
          v-model="editedRole.show_as_contact"
          label="Afficher comme contact"
          :name="'show_as_contact_' + (role ? role.id : 0)"
          type="checkbox"
          :disabled="disabled || loading"
          description="Si ses coordonnées devraient être affichées aux emprunteurs-ses."
        />
        <template v-if="canEditPaidAmounts">
          <forms-validated-input
            v-model="editedRole.pays_loan_price"
            label="Paie le tarif de l'emprunt"
            :name="'pays_loan_price_' + (role ? role.id : 0)"
            description="Si applicable, cette personne paie le coût lié à la durée et à la distance parcourue lors de ses emprunts de ce véhicule."
            type="checkbox"
            :disabled="disabled || loading"
          ></forms-validated-input>

          <template v-if="loanableHasPaidInsurance">
            <forms-validated-input
              v-model="editedRole.pays_loan_insurance"
              label="Paie l'assurance liée à l'emprunt"
              :name="'pays_loan_insurance_' + (role ? role.id : 0)"
              description="Si applicable, cette personne paie le coût lié à l'assurance lors de ses emprunts de ce véhicule."
              type="checkbox"
              :disabled="disabled || loading"
            ></forms-validated-input>

            <b-alert
              v-if="editedRole.role !== `owner` && !editedRole.pays_loan_insurance"
              variant="warning"
              show
            >
              <strong>Attention&nbsp;:</strong> cette personne ne sera pas assurée par LocoMotion
              lors de ses trajets.
            </b-alert>
          </template>
        </template>
      </b-form>
      <template #modal-footer>
        <div class="action-buttons">
          <icon-button
            role="save"
            :loading="loading"
            :disabled="disabled || loading || !changed"
            :onclick="async () => passes(save)"
            data-dusk="save-user-role-button"
          >
            Enregistrer
          </icon-button>
          <icon-button role="cancel" :disabled="disabled || loading" @click.prevent="cancel">
            Annuler
          </icon-button>
          <layout-loading v-if="loading" with-button />
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { capitalize } from "@/helpers/filters";
import { canEditRolePaidAmounts, canEditRoleUser } from "@/helpers/permissions/loanables";
import { post, put } from "@/requests/server";

export default {
  name: "LoanableUserRoleForm",
  components: { IconButton, FormsValidatedInput },
  props: {
    role: { type: Object, default: null },
    loanable: { type: Object, default: null },
    disabled: { type: Boolean, default: false },
    library: { type: Object, default: null },
  },
  data() {
    return {
      editedRole: {
        ...this.role,
      },
      loading: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    loanableHasPaidInsurance() {
      return this.library || this.loanable.type === "car";
    },
    canEditUser() {
      return this.role && canEditRoleUser(this.user, this.role);
    },
    canEditPaidAmounts() {
      return (
        this.role && canEditRolePaidAmounts(this.user, this.loanable ?? this.library, this.role)
      );
    },
    roleUserQuery() {
      const query = {
        slug: "users",
        value: "id",
        text: "full_name",
        details: "email",
        params: {},
      };

      if (this.library) {
        // For existing libraries, do not return users which already have a role in the library
        query.params["!library_roles.ressource_id"] = this.library.id;
      }
      return query;
    },
  },
  methods: {
    capitalize,
    show() {
      this.$nextTick(() => {
        this.editedRole = {
          ...this.role,
        };
        this.$bvModal.show("editing-role-modal");
      });
    },
    async save() {
      if (!this.editedRole.user) {
        return;
      }
      if (!this.loanableHasPaidInsurance) {
        this.role.pays_loan_insurance = false;
      }

      let updatedRole = null;
      if (this.editedRole.id) {
        const { data } = await put(
          `/loanables/roles/${this.editedRole.id}`,
          {
            ...this.editedRole,
            user_id: this.editedRole.user.id,
          },
          {
            notifications: { action: "modidification du rôle" },
          }
        );
        updatedRole = data;
      } else {
        let endpoint = "";
        if (this.library) {
          endpoint = "/libraries/" + this.library.id;
        } else {
          endpoint = "/loanables/" + this.loanable.id;
        }

        const { data } = await post(
          `${endpoint}/roles`,
          {
            ...this.editedRole,
            user_id: this.editedRole.user.id,
          },
          {
            notifications: { action: "Ajout du rôle" },
          }
        );
        updatedRole = data;
      }
      this.editedRole = {
        id: null,
        user: null,
        role: null,
      };
      this.$bvModal.hide("editing-role-modal");
      this.$emit("saved", updatedRole);
    },
    cancel() {
      this.$bvModal.hide("editing-role-modal");
    },
    setUser(user) {
      this.editedRole.user_id = user?.id;
      this.editedRole.user = user;
    },
  },
};
</script>

<style scoped>
.action-buttons {
  display: flex;
  gap: 0.5rem;
}
</style>
