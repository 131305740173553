<template>
  <b-container class="profile-payment-method" fluid>
    <vue-headful :title="fullTitle" />

    <div v-if="!paymentMethod">Mode de paiement introuvable.</div>
    <template v-else>
      <h1>{{ paymentMethod.name }}</h1>

      <payment-method-form :payment-method="paymentMethod" @destroyed="onDestroy" />
    </template>
  </b-container>
</template>

<script>
import { capitalize } from "@/helpers/filters";

export default {
  name: "ProfilePaymentMethod",
  components: {
    PaymentMethodForm: () => import("@/components/PaymentMethod/PaymentMethodForm.vue"),
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    paymentMethod() {
      return this.$store.state.user.payment_methods.find((pm) => pm.id == this.id);
    },
    fullTitle() {
      const parts = [
        "LocoMotion",
        capitalize(this.$i18n.t("titles.profile")),
        capitalize(this.$i18n.tc("paymentMethods.model_name", 2)),
      ];

      if (this.pageTitle) {
        parts.push(this.pageTitle);
      }

      return parts.reverse().join(" | ");
    },
    pageTitle() {
      return this.paymentMethod?.name || capitalize(this.$i18n.tc("paymentMethods.model_name", 1));
    },
  },
  methods: {
    onDestroy() {
      this.$router.push("/profile/payment_methods");
    },
  },
};
</script>

<style lang="scss"></style>
