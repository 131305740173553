<template>
  <date-picker
    ref="datetimepicker"
    :value="timeValue"
    type="datetime"
    :minute-step="15"
    :show-second="false"
    :disabled-date="disabledDatesFct"
    :disabled-time="disabledTimesFct"
    :disabled="disabled"
    value-type="date"
    format="D MMMM YYYY HH:mm"
    time-title-format="D MMMM YYYY"
    :formatter="formatter"
    :clearable="clearable"
    :open.sync="open"
    lang="fr"
    @change="closeWhenMinutePicked"
    @input="emitChangeTime"
    @close="showTimePanel = false"
    @update:show-time-panel="showTimePanel = $event"
  >
    <template #footer>
      <button class="mx-btn mr-auto mx-btn-text" @click="handleTimePanelShortcut">
        <b-icon :icon="showTimePanel ? 'calendar' : 'clock'"></b-icon>
      </button>
      <slot name="footer"></slot>
    </template>
    <template v-if="$slots.sidebar" #sidebar><slot name="sidebar"></slot></template>
  </date-picker>
</template>

<script>
import { datetime } from "@/helpers/filters";
import dayjs from "dayjs";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/fr";

export default {
  name: "FormsDateTimePicker",
  components: {
    DatePicker,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledDatesFct: {
      type: Function,
      required: false,
      default: () => false,
    },
    disabledTimesFct: {
      type: Function,
      required: false,
      default: () => false,
    },
    value: {
      type: String,
      default: null,
    },
    timezone: {
      type: String,
      default: undefined,
    },
    zoneless: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    showTimezone: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTimePanel: false,
      open: false,
      formatter: {
        stringify: (date) => {
          if (this.showTimezone) {
            return dayjs(date).format("D MMMM YYYY HH:mm z (Z)");
          }
          return datetime(date);
        },
        parse(date) {
          if (!date) {
            return null;
          }
          let d = dayjs(date, "D MMMM YYYY HH:mm", "fr");
          if (!d.isValid()) {
            d = dayjs(date);
          }
          return d.toDate();
        },
      },
    };
  },
  computed: {
    timeValue() {
      if (!this.value) {
        return null;
      }

      let dayjsDate = dayjs(this.value);

      if (this.timezone) {
        dayjsDate = dayjs.atTz(this.value, this.timezone);
      } else if (this.zoneless) {
        dayjsDate = dayjs.utc(this.value);
      }

      return new Date(
        dayjsDate.year(),
        dayjsDate.month(),
        dayjsDate.date(),
        dayjsDate.hour(),
        dayjsDate.minute(),
        dayjsDate.second()
      );
    },
    valueFormat() {
      return "YYYY-MM-DDTHH:mm";
    },
  },
  mounted() {
    // Without this, popup doesn't close when clicking outside
    document.addEventListener("mousedown", (event) => {
      if (!this.open) {
        return;
      }
      if (this.$el.contains(event.target)) {
        return;
      }

      let popup = this.$refs.datetimepicker?.$refs.popup?.$el;
      if (popup && popup.contains(event.target)) {
        return;
      }
      this.open = false;
    });
  },
  methods: {
    emitChangeTime(value) {
      if (this.disabled) {
        return;
      }

      if (!value) {
        this.$emit("input", value);
        return;
      }

      // Discard local timezone from date
      value = dayjs(value).format("YYYY-MM-DDTHH:mm");

      if (this.zoneless) {
        this.$emit("input", value);
        return;
      }

      // Force a specific timezone
      if (this.timezone) {
        this.$emit("input", dayjs.atTz(value, this.timezone).toISOString());
        return;
      }

      // Return date in ISO string assuming time is in user's local timezone
      this.$emit("input", dayjs(value).toISOString());
    },
    closeWhenMinutePicked(value, type) {
      if (!this.$refs?.datetimepicker) {
        return;
      }
      if (type === "minute") {
        this.$refs.datetimepicker.closePopup();
      }
    },
    handleTimePanelShortcut() {
      if (!this.$refs?.datetimepicker?.$refs?.picker) {
        return;
      }
      if (this.showTimePanel) {
        this.$refs.datetimepicker.$refs.picker.closeTimePanel();
        return;
      }

      this.$refs.datetimepicker.$refs.picker.openTimePanel();
    },
    close() {
      this.open = false;
    },
  },
};
</script>

<style lang="scss"></style>
