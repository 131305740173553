<template>
  <info-box
    class="library-info-box"
    :loading="loading"
    :big-img="library.avatar"
    :link="`/libraries/${library.id}`"
    :title="library.name"
    :avatar="contextImage"
    :avatar-fallback="context"
  >
    <b-row class="flex-fill align-items-center" no-gutters align-h="between">
      <b-col cols="12" xl="8">
        <span v-if="context" class="library-info-box__owner">{{ context }}<br /></span>
        <span class="library-info-box__name">{{ library.name }}</span>
      </b-col>
    </b-row>
  </info-box>
</template>

<script>
import InfoBox from "@/components/shared/InfoBox.vue";

export default {
  name: "LibraryInfoBox",
  components: { InfoBox },
  props: {
    library: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    context() {
      if (this.libraryOwnerUser && this.libraryOwnerUser.id !== this.user.id) {
        return this.libraryOwnerUser.full_name;
      }
      return null;
    },
    contextImage() {
      if (this.libraryOwnerUser && this.libraryOwnerUser.id !== this.user.id) {
        return this.libraryOwnerUser.avatar;
      }
      return null;
    },
    libraryOwnerUser() {
      return this.library.user_roles?.find((userRole) => userRole.role === "owner")?.user;
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.library-info-box {
  &__name {
    color: $black;
    font-size: 1.25rem;
    position: unset;
  }

  &__owner {
    color: $black;
  }
}
</style>
