<template>
  <div class="password-request">
    <h1 class="password-request__title">{{ $t("views.password.request.request_password") }}</h1>

    <b-alert v-if="sent" variant="success" show>
      {{ $t("views.password.request.request_response") }}
    </b-alert>

    <b-alert v-if="error" variant="danger" show>
      {{ $t("views.password.request.request_response_error") }}
    </b-alert>

    <p>{{ $t("views.password.request.instructions") }}</p>

    <b-form class="password-request__form" novalidate @submit.prevent="passwordRequest">
      <b-form-group :label="$t('views.password.request.email')">
        <b-form-input
          v-model="email"
          type="email"
          required
          :placeholder="$t('views.password.request.email')"
        />
      </b-form-group>

      <b-form-group>
        <icon-button type="submit" :loading="loading" variant="success" block>
          {{ $t("views.password.request.submit") }}
        </icon-button>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";

export default {
  name: "PasswordRequest",
  components: { IconButton },
  data() {
    return {
      email: "",
      error: false,
      loading: false,
      sent: false,
    };
  },
  methods: {
    async passwordRequest() {
      this.sent = false;
      this.loading = true;
      this.error = false;

      try {
        await this.$store.dispatch("password/request", {
          email: this.email,
        });
        this.sent = true;
      } catch (e) {
        this.error = true;
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.password-request {
  .password-request__form {
    margin-top: 32px;
  }

  .password-request__title {
    text-align: center;
    color: $black;
    font-size: 24px;
    margin-bottom: 20px;
  }
}
</style>
