import { get, post, put } from "@/requests/server";
import libraries from "@/store/models/libraries";
import dayjs from "dayjs";
import merge from "deepmerge";
import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

import account from "./account";
import imageCache from "./imageCache";
import invitation from "./invitation";

import bikes from "./models/bikes";
import borrowers from "./models/borrowers";
import cars from "./models/cars";
import communities from "./models/communities";
import communityUsers from "./models/communityUsers";
import invoices from "./models/invoices";
import loanables from "./models/loanables";
import loanableTypes from "./models/loanableTypes";
import loans from "./models/loans";
import paymentMethods from "./models/paymentMethods";
import trailers from "./models/trailers";
import users from "./models/users";

import CommunityMap from "./pages/community/map";
import Dashboard from "./pages/dashboard";
import LoanableSearch, { persistState } from "./pages/loanable/search";
import Login from "./pages/login";
import Register from "./pages/register";
import paginatedTables from "./paginatedTables";
import passwordModule from "./password";
import stats from "./stats";

Vue.use(Vuex);

/*
  Store is divided into modules:
    https://vuex.vuejs.org/guide/modules.html

  Root state is described in initialState below.
*/
const modules = {
  // General modules
  account,
  password: passwordModule,
  stats,

  // Rest modules: containing data for each entity.
  bikes,
  borrowers,
  cars,
  communities,
  communityUsers,
  imageCache,
  invitation,
  invoices,
  libraries,
  loans,
  loanables,
  loanableTypes,
  paymentMethods,
  trailers,
  users,

  // Page modules
  "community.map": CommunityMap,
  "loanable.search": LoanableSearch,
  dashboard: Dashboard,
  login: Login,
  register: Register,
  paginatedTables: paginatedTables,
};

const getInitialState = () => ({
  // Initial root state.
  // Initial state of modules is set in each module individually.
  loaded: false,
  loading: false,
  loansLoaded: false,
  loanablesLoaded: false,
  notifications: [],
  user: null,
  token: null,
  tokenExpiresOn: null,
  loggedInOn: null,
  refreshToken: null,
  mandated: false,
  seenVersions: [],
  clientVersionFromBackend: null,
});

const actions = {
  async loadUser({ commit, state }) {
    commit("loading", true);

    const { data: user } = await get("/auth/user", {
      cleanupCallback: () => commit("loading", false),
    });

    const newUser = {
      ...user,
      loanables: state.user?.loanables ?? [],
      loans: state.user?.loans ?? [],
    };

    commit("user", newUser);

    commit("loaded", true);
  },
  async login({ commit, dispatch, state }, { email, password }) {
    commit("loading", true);

    const { data } = await post(
      "/auth/login",
      {
        email,
        password,
        rememberMe: state.login.rememberMe,
      },
      {
        notifications: {
          action: "connexion",
          ressourceName: "ce compte",
        },
        requestOptions: { expects: [401] },
        cleanupCallback: () => commit("loading", false),
      }
    );

    commit("setTokens", {
      token: data.access_token,
      refreshToken: data.refresh_token,
      expiresIn: data.expires_in,
    });

    if (!state.login.rememberMe) {
      commit("login/email", "");
    }

    await dispatch("loadUser");
  },
  async register({ commit, dispatch, state }, { email, password, code }) {
    const { data } = await post(
      "/auth/register",
      {
        email,
        password,
        code,
      },
      {
        notifications: { action: "inscription" },
        cleanupCallback: () => commit("loading", false),
      }
    );

    commit("setTokens", {
      token: data.access_token,
      refreshToken: data.refresh_token,
      expiresIn: data.expires_in,
    });

    if (!state.login.rememberMe) {
      commit("login/email", "");
    }

    await dispatch("loadUser");
  },
  async logout({ commit, state }, { keepInvitation = false } = {}) {
    if (state.token) {
      try {
        await put(
          "/auth/logout",
          {},
          {
            requestOptions: { expects: [401] },
          }
        );
      } catch (e) {
        if (e.response?.status !== 401) {
          console.error(e);
        }
        // Do not block the flow, if we cannot logout in backend.
      }
    }
    // Reset any user-specific state, especially persisted state
    commit("reset");
    commit("dashboard/reset");
    commit("loanable.search/reset");
    commit("paginatedTables/reset");
    if (!keepInvitation) {
      commit("invitation/clear");
    }
  },
};

const mutations = {
  addNotification(state, notification) {
    state.notifications.push(notification);
  },
  removeNotification(state, notification) {
    const index = state.notifications.indexOf(notification);

    if (index > -1) {
      state.notifications.splice(index, 1);
    }
  },
  loaded(state, value) {
    state.loaded = value;
  },
  loading(state, value) {
    state.loading = value;
  },
  mergeUser(state, user) {
    state.user = merge(state.user, user);
  },
  refreshToken(state, refreshToken) {
    state.refreshToken = refreshToken;
  },
  seeVersion(state, version) {
    state.seenVersions.push(version);
  },
  unseeVersion(state, version) {
    const index = state.seenVersions.indexOf(version);
    if (index > -1) {
      state.seenVersions.splice(index, 1);
    }
  },
  token(state, token) {
    state.token = token;
  },
  setTokens(
    state,
    {
      token = null,
      refreshToken = null,
      expiresIn = null,
      tokenExpiresOn = null,
      mandated = false,
    } = {}
  ) {
    state.mandated = mandated;
    state.token = token;
    state.refreshToken = refreshToken;
    state.tokenExpiresOn = tokenExpiresOn
      ? dayjs(tokenExpiresOn).toISOString()
      : expiresIn
      ? dayjs().add(expiresIn, "seconds").toISOString()
      : null;
    state.loggedInOn = token ? dayjs().toISOString() : null;
  },
  user(state, user) {
    state.user = user;
  },
  reset(state) {
    Object.assign(state, getInitialState());
  },
  setClientVersionFromBackend(state, version) {
    state.clientVersionFromBackend = version;
  },
};

const vuexPersist = new VuexPersist({
  key: "locomotion",
  storage: window.localStorage,
  /*
     State reducer. Reduces state to only those values you want to save.
     By default, saves entire state.
     https://github.com/championswimmer/vuex-persist#constructor-parameters--
   */
  reducer: (state) => ({
    // Root state
    user: state.user,
    token: state.token,
    refreshToken: state.refreshToken,
    tokenExpiresOn: state.tokenExpiresOn,
    loggedInOn: state.loggedInOn,
    seenVersions: state.seenVersions,

    // General modules
    stats: state.stats,
    login: {
      email: state.login.email,
      rememberMe: state.login.rememberMe,
    },
    paginatedTables: state.paginatedTables,

    // Page modules
    "loanable.search": persistState(state["loanable.search"]),
  }),
  // Do not save to local storage if this a mandated session
  filter: () => !store.state.mandated,
});

const store = new Vuex.Store({
  modules,
  state: getInitialState(),
  mutations,
  actions,
  plugins: [vuexPersist.plugin],
});

export default store;
