<template>
  <awareness-banner class="donation" :image="image" :title="title">
    <slot></slot>

    <div class="donation_amount_with_slider">
      <div class="custom-range-plus">
        <div class="custom-range-context">
          <div
            v-for="stop of stops"
            :key="stop.amount"
            class="range-text-stop"
            :style="`left: ${stop.percent}%`"
            :class="{ 'stop-reached': amount >= stop.amount }"
            @click="() => setAmount(stop.amount)"
          >
            <icon-button v-if="stop.label" variant="outline-primary" size="inline">
              {{ stop.label }}
            </icon-button>
            <div class="range-stop-icon">
              <component :is="stop.icon" v-if="stop.icon" />
              <div v-else class="spacer"></div>
            </div>
          </div>
        </div>
        <div class="slider" :class="{ 'slider-focused': sliderInteractedLast }">
          <div class="track">
            <div
              class="active-track"
              :class="{
                disabled: disabled,
                'near-min': sliderValue < 10,
                'near-max': sliderValue > 90,
              }"
              :style="`width: ${sliderValue}%; width: calc(2rem + (100% - 2rem) *  ${
                sliderValue / 100
              })`"
            >
              <b-icon class="slider-arrow slider-arrow-left" icon="arrow-left-short" />
              <b-icon class="slider-arrow slider-arrow-right" icon="arrow-right-short" />
            </div>
          </div>

          <div class="real-range" @touchmove.stop>
            <label class="sr-only" for="amount-slider">Montant de la contribution</label>
            <b-form-input
              id="amount-slider"
              ref="amountSlider"
              :disabled="disabled"
              name="amount-slider"
              :value="sliderValue"
              type="range"
              :min="0"
              :max="100"
              :step="currentStep"
              :aria-valuenow="amount"
              :aria-valuetext="`${amount} $`"
              aria-label="Montant de la contribution"
              :aria-valuemin="minimum"
              :aria-valuemax="maximum"
              role="slider"
              @input="handleSlider"
              @blur="sliderInteractedLast = false"
              @touchend="sliderInteractedLast = false"
              @touchstart="() => $refs.amountSlider.focus()"
              @focus="sliderInteractedLast = true"
            />
          </div>
        </div>
      </div>

      <div class="donation__amount tabular-nums">
        <b-input
          ref="currencyInput"
          v-currency="{
            locale: 'fr',
            currency: { suffix: ' $' },
            valueRange: { min: minimum },
            allowNegative: false,
            distractionFree: false,
          }"
          type="text"
          aria-label="Montant de la contribution"
          :value="formattedAmount"
          @focus="inputHasFocus = true"
          @blur="handleCurrencyInputChange"
        />
      </div>
    </div>

    <small class="text-right text-muted">
      <slot name="under"></slot>
      <template v-if="subscriptionAvailable && pricingLoanableType">
        Pour faire disparaître ce module, financez le projet d’un coup avec la
        <b-link :to="subscriptionLink">contribution annuelle</b-link>.
      </template>
    </small>
  </awareness-banner>
</template>

<script>
import celebration from "@/assets/svg/celebration-sparks.svg";
import party from "@/assets/svg/party.svg";
import happy from "@/assets/svg/single-person-happy.svg";
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import AwarenessBanner from "@/components/shared/AwarenessBanner.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { filters } from "@/helpers";
import { min } from "locutus/php/math";
import { CurrencyDirective as currency, getValue, setValue } from "vue-currency-input";

export default {
  name: "DonationSlider",
  components: {
    IconButton,
    AwarenessBanner,
    FormsValidatedInput,
  },
  directives: {
    currency,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: Number,
      default: 0,
    },
    target: {
      type: Number,
      default: 0,
    },
    contributionRequired: {
      type: Boolean,
      default: false,
    },
    subscriptionAvailable: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 0.25,
    },
    pricingLoanableType: {
      type: String,
      default: undefined,
    },
    communityId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      sliderInteractedLast: false,
      inputHasFocus: false,
    };
  },
  computed: {
    stops() {
      if (this.target === 0) {
        return [
          {
            amount: 0,
            percent: 0,
            label: "min",
          },
          {
            amount: 5,
            percent: 40,
            label: "5 $",
            icon: happy,
          },
          {
            amount: 8,
            percent: 70,
            label: "8 $",
            icon: party,
          },
          {
            amount: 60,
            percent: 100,
            label: "max",
            icon: celebration,
          },
        ];
      } else {
        if (this.contributionRequired) {
          return [
            {
              amount: this.target,
              percent: 0,
              label: "Prix coûtant",
              icon: happy,
            },
            {
              amount: this.target * 2,
              percent: 50,
              icon: party,
            },
            {
              amount: Math.max(this.target * 3, 60),
              percent: 100,
              label: "Max",
              icon: celebration,
            },
          ];
        } else {
          return [
            {
              amount: 0,
              percent: 0,
              label: "Min",
            },
            {
              amount: this.target,
              percent: 40,
              label: "Prix coûtant",
              icon: happy,
            },
            {
              amount: this.target * 2,
              percent: 70,
              icon: party,
            },
            {
              amount: Math.max(this.target * 3, 60),
              percent: 100,
              label: "Max",
              icon: celebration,
            },
          ];
        }
      }
    },
    currentStep() {
      let lastStop = 1;
      for (lastStop; lastStop < this.stops.length - 1; lastStop++) {
        if (this.stops[lastStop].amount > this.amount) {
          break;
        }
      }

      let startStop = this.stops[lastStop - 1];
      let endStop = this.stops[lastStop];

      // Round to ensure max (100) is divisible by the step (so that the slider can slide to the very end)
      return (
        Math.round(
          ((endStop.percent - startStop.percent) / (endStop.amount - startStop.amount)) *
            this.step *
            100
        ) / 100
      );
    },
    minimum() {
      return this.stops[0].amount;
    },
    maximum() {
      return this.stops[this.stops.length - 1].amount;
    },
    sliderValue() {
      let lastStop = null;
      for (const stop of this.stops) {
        if (stop.amount > this.amount) {
          if (!lastStop) {
            // lower than min
            return 0;
          }

          return (
            ((this.amount - lastStop.amount) / (stop.amount - lastStop.amount)) *
              (stop.percent - lastStop.percent) +
            lastStop.percent
          );
        }
        lastStop = stop;
      }

      // Over max
      return 100;
    },
    formattedAmount() {
      return filters.currency(this.amount);
    },
    isChanging() {
      return this.inputHasFocus;
    },
    subscriptionLink() {
      return `/profile/communities/${this.communityId}?loanable_type=${this.pricingLoanableType}`;
    },
  },
  watch: {
    stops() {
      // When stops change, the input disappears for no apparent reason. This ensures the input value
      // is visible still.
      setValue(this.$refs.currencyInput, this.amount);
    },
  },
  mounted() {
    if (this.amount < this.target && this.contributionRequired) {
      this.setAmount(this.target);
    }
  },
  methods: {
    min,
    setAmount(newValue) {
      this.sliderInteractedLast = false;
      this.$emit("amountChanged", newValue);
    },
    handleCurrencyInputChange() {
      // Timeout so the amountChange event is handled before the 'isChanging' state turns to false;
      setTimeout(() => (this.inputHasFocus = false), 10);
      let inputAmount = getValue(this.$refs.currencyInput);
      if (!inputAmount) {
        setValue(this.$refs.currencyInput, this.amount);
        return;
      }
      if (inputAmount < this.minimum) {
        // Normally, v-currency would clamp to minimum, but it doesn't seem to work when clearing
        // the input. This handles that case.
        setValue(this.$refs.currencyInput, this.minimum);
        this.$emit("amountChanged", this.minimum);
        return;
      }
      this.$emit("amountChanged", inputAmount);
    },
    handleSlider(sliderValue) {
      this.sliderInteractedLast = true;
      sliderValue = parseFloat(sliderValue);
      let lastStop = null;
      for (const stop of this.stops) {
        if (stop.percent > sliderValue) {
          if (!lastStop) {
            // lower than min
            this.$emit("amountChanged", stop.amount);
            return;
          }

          // snap to some amount maybe?
          let newAmount =
            ((stop.amount - lastStop.amount) * (sliderValue - lastStop.percent)) /
              (stop.percent - lastStop.percent) +
            lastStop.amount;

          let clampedAmount = Math.round(newAmount / this.step) * this.step;

          this.$emit("amountChanged", clampedAmount);
          return;
        }
        lastStop = stop;
      }

      // Over max
      this.$emit("amountChanged", lastStop.amount);
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.donation {
  --range-size: 2rem;
  --range-border-radius: 1rem;

  @include media-breakpoint-down(sm) {
    --range-size: 2.5rem;
    --range-border-radius: 1.25rem;
  }

  .custom-range-plus {
    position: relative;
    .real-range {
      input {
        height: var(--range-size);
        opacity: 0;
        &::-webkit-slider-runnable-track {
          height: var(--range-size);
        }
        &::-moz-range-track {
          height: var(--range-size);
        }
        &::-ms-track {
          height: var(--range-size);
        }
        &::-webkit-slider-thumb {
          height: var(--range-size);
          width: var(--range-size);
        }
      }
    }
    .track {
      position: absolute;
      pointer-events: none;
      background: $beige;
      box-shadow: $small-inset-shadow;
      border-radius: var(--range-border-radius);
      height: var(--range-size);
      width: 100%;

      .active-track {
        background: linear-gradient(
          70deg,
          $locomotion-light-green 0rem,
          $locomotion-light-green 3rem
        );
        box-shadow: $small-shadow;
        height: var(--range-size);
        border-radius: var(--range-border-radius);
        min-width: var(--range-size);
        transition: 0.5s width;
        position: relative;
        .slider-arrow {
          position: absolute;
          font-size: 1.5rem;
          height: 100%;
          transition: all 0.3s;
          opacity: 1;
          &.slider-arrow-left {
            right: 0.5rem;
            color: white;
          }

          &.slider-arrow-right {
            right: -1.5rem;
            color: $locomotion-green;
          }
        }
        &.near-min .slider-arrow-left {
          opacity: 0;
        }
        &.near-max .slider-arrow-right {
          opacity: 0;
        }
        &.disabled {
          background: $grey;
        }
      }
    }

    .slider.slider-focused {
      .track .active-track {
        transition: 0s width;
        box-shadow:
          $small-shadow,
          0 0 0 0.2rem rgba(57, 179, 173, 0.5);
      }
    }

    .custom-range-context {
      height: 3rem;
      margin-bottom: 0.5rem;
      position: relative;
    }
    .range-text-stop {
      display: flex;
      justify-content: flex-end;
      gap: 0.25rem;
      flex-direction: column;
      position: absolute;
      font-size: 0.9rem;
      font-weight: bold;
      top: 0;
      transform: translateX(-50%);
      height: 100%;
      cursor: pointer;

      &:first-child {
        transform: none;
        left: 0;
        .range-stop-icon {
          justify-content: flex-start;
        }
      }

      &:last-child {
        transform: none;
        left: unset !important;
        right: 0;

        .range-stop-icon {
          justify-content: flex-end;
        }
      }

      .range-stop-icon {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        svg,
        .spacer {
          height: 1.5rem;
          width: 1.5rem;
        }
        svg {
          fill: lightgray;
          transition: 0.5s fill;
        }
      }

      &.stop-reached .range-stop-icon svg {
        fill: $locomotion-green;
      }
    }
  }

  &__amount {
    font-size: 3rem;
    font-weight: bold;
    text-align: right;
    color: $locomotion-green;

    input {
      border: none;
      text-align: right;
      font-size: 3rem;
      font-weight: bold;
      color: $locomotion-green;
      background: transparent;
      // 3 rem * 1.2 line-height
      height: 3.6rem;
      max-width: 100%;
      padding: 0;
      &:focus {
        color: $locomotion-green;
      }
    }
  }

  .donation_amount_with_slider {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
    }
  }
}
</style>
