<script>
import LibraryBlurb from "@/components/Loanable/LibraryBlurb.vue";
import UserRoleList from "@/components/Loanable/UserRoleList.vue";
import UserBlurb from "@/components/User/UserBlurb.vue";
import { isCoownerOrOwner } from "@/helpers/permissions/loanables";
import { canViewLoanAdminDetails, isBorrower, isLoanAdmin } from "@/helpers/permissions/loans";
import { addTrustedBorrower } from "@/requests/trustedBorrowersRequests";

export default {
  name: "LoanContacts",
  components: { LibraryBlurb, UserRoleList, UserBlurb },
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isBorrower() {
      return isBorrower(this.user, this.loan);
    },
    isCoownerOrOwner() {
      return isCoownerOrOwner(this.user, this.loan.loanable);
    },
    isLoanAdmin() {
      return isLoanAdmin(this.user, this.loan);
    },
    canViewLoanAdminDetails() {
      return canViewLoanAdminDetails(this.user, this.loan);
    },
  },
  methods: {
    async addBorrowerToTrustedBorrowers() {
      await addTrustedBorrower(this.loan.loanable.id, this.loan.borrower_user.id);
      this.$emit(
        "input",
        {
          ...this.loan,
          is_borrower_trusted: true,
        },
        {
          refreshDashboard: false,
          freshlyLoaded: false,
        }
      );
    },
  },
};
</script>

<template>
  <div>
    <div v-if="loan.loanable.library" class="mb-3">
      <h3 class="loan-section-title">{{ $t("loanables.fields.library") | capitalize }}</h3>
      <library-blurb :library="loan.loanable.library" />
    </div>
    <div v-if="isCoownerOrOwner || isLoanAdmin" class="mb-3">
      <h3 class="loan-section-title">{{ $t("loans.fields.borrower_user_id") | capitalize }}</h3>
      <user-blurb
        :show-admin-link="canViewLoanAdminDetails"
        :user="loan.borrower_user"
        show-phone
        show-email
      >
        <template
          v-if="
            loan.loanable.sharing_mode === 'hybrid' &&
            !loan.is_self_service &&
            !loan.is_borrower_trusted
          "
          #dropdown
        >
          <b-dropdown-item variant="success" @click="addBorrowerToTrustedBorrowers">
            <b-icon icon="shield-fill-check" />
            Ajouter au réseau de confiance
          </b-dropdown-item>
        </template>
        <template v-else-if="loan.is_borrower_trusted" #nameicon>
          <b-icon
            v-b-tooltip="'Dans le réseau de confiance de ce véhicule'"
            class="text-primary"
            icon="shield-fill-check"
          />
        </template>
      </user-blurb>
    </div>
    <user-role-list
      v-if="isBorrower || isLoanAdmin || isCoownerOrOwner"
      :user-roles="loan.loanable.merged_user_roles"
      :show-admin-links="isLoanAdmin"
      :show-contacts-only="!isLoanAdmin && !isCoownerOrOwner"
    >
      <template #title>
        <h3 class="loan-section-title">
          {{ $t("loanables.resource_people") | capitalize }}
        </h3>
      </template>
    </user-role-list>
  </div>
</template>
