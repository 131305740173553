<script>
import LoanPageEditableBox from "@/components/Loan/LoanPageEditableBox.vue";
import IconButton from "@/components/shared/IconButton.vue";
import MarkdownContent from "@/components/shared/MarkdownContent.vue";
import { hasSomeLoanableRole } from "@/helpers/permissions/loanables";
import { isGlobalAdmin } from "@/helpers/permissions/users";
import { put } from "@/requests/server";

export default {
  name: "LoanAutoValidationBox",
  components: { MarkdownContent, IconButton, LoanPageEditableBox },
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userAnswer: null,
    };
  },
  computed: {
    isLoanableManagerOrAdmin() {
      return (
        isGlobalAdmin(this.$store.state.user) ||
        hasSomeLoanableRole(this.$store.state.user, this.loan.loanable, [
          "owner",
          "manager",
          "coowner",
        ])
      );
    },
  },
  methods: {
    async submit() {
      const { data } = await put(
        `/loans/${this.loan.id}/autovalidate`,
        {
          answer: this.userAnswer,
        },
        {
          notifications: { action: "Auto-validation", onSuccess: true },
        }
      );
      this.$emit("input", data);
    },
    async skip() {
      const { data } = await put(
        `/loans/${this.loan.id}/autovalidate`,
        {
          skip: true,
        },
        {
          notifications: { action: "Auto-validation", onSuccess: true },
        }
      );
      this.$emit("input", data);
    },
  },
};
</script>

<template>
  <loan-page-editable-box
    ref="editableBox"
    :initially-editing="!loan.auto_validated_at"
    label="Auto-validation"
  >
    <template #default="{ edit }">
      Auto-validé par {{ loan.auto_validated_by_user.full_name }} le
      {{ loan.auto_validated_at | datetime }}.
      <b-link @click="edit">(détails)</b-link>
    </template>
    <template #form="{ close }">
      <markdown-content :content="loan.loanable.library.loan_auto_validation_question" />
      <div v-if="loan.auto_validated_at" class="mt-3 text-center text-muted">
        Auto-validé par {{ loan.auto_validated_by_user.full_name }} le
        {{ loan.auto_validated_at | datetime }}.
        <b-link @click="close">(réduire)</b-link>
      </div>
      <template v-else>
        <b-input v-model="userAnswer" class="my-3"></b-input>
        <div class="button-list">
          <icon-button role="save" :onclick="submit">Soumettre</icon-button>
          <div v-if="isLoanableManagerOrAdmin">
            <icon-button
              v-if="isLoanableManagerOrAdmin"
              variant="outline-warning"
              icon="skip-end-circle"
              :onclick="skip"
              >Sauter l'étape
            </icon-button>
            <b-icon
              v-b-tooltip="'Cette option est disponible puisque vous êtes responsable du véhicule'"
              :scale="0.75"
              icon="info-circle"
              variant="secondary"
            ></b-icon>
          </div>
        </div>
      </template>
    </template>
  </loan-page-editable-box>
</template>

<style scoped lang="scss"></style>
